export  class LovModel {


  data = [ {
    'list' : [ {
      'label' : {
        'en' : 'yes',
        'fr' : 'oui'
      },
      'value' : 'val1'
    }, {
      'label' : {
        'en' : 'no',
        'fr' : 'non'
      },
      'value' : 'val2'
    } ],
    'name' : 'dummy_liste'
  }, {
    'name' : 'research_methods',
    'list' : [ {
      'label' : {
        'en' : 'Action Research\t',
        'fr' : 'Action Research\t'
      },
      'value' : '1'
    }, {
      'label' : {
        'en' : 'Autobiography/Auto-Ethnography',
        'fr' : 'Autobiographie / Auto-Ethnographie'
      },
      'value' : '2'
    }, {
      'label' : {
        'en' : 'Community Based Research',
        'fr' : 'Recherche communautaire'
      },
      'value' : '3'
    }, {
      'label' : {
        'en' : 'Data Linkage',
        'fr' : 'Liaison de données'
      },
      'value' : '4'
    }, {
      'label' : {
        'en' : 'Deception',
        'fr' : 'Tromperie'
      },
      'value' : '5'
    }, {
      'label' : {
        'en' : 'Ethnographic Fieldwork',
        'fr' : 'Terrain ethnographique'
      },
      'value' : '6'
    }, {
      'label' : {
        'en' : 'Expert Interviews',
        'fr' : ' Entretiens d\'experts'
      },
      'value' : '7'
    }, {
      'label' : {
        'en' : 'Focus Groups',
        'fr' : 'Groupes de discussion'
      },
      'value' : '8'
    }, {
      'label' : {
        'en' : 'Naturalistic Observation',
        'fr' : 'Observation naturaliste'
      },
      'value' : '9'
    }, {
      'label' : {
        'en' : 'Random Digit Dialing',
        'fr' : 'Numérotation aléatoire'
      },
      'value' : '10'
    }, {
      'label' : {
        'en' : 'Secondary Use of Data',
        'fr' : 'Utilisation secondaire des données'
      },
      'value' : '11'
    }, {
      'label' : {
        'en' : 'Subject Pools',
        'fr' : 'Pools sujets'
      },
      'value' : '12'
    }, {
      'label' : {
        'en' : 'Use of Medical Records',
        'fr' : 'Utilisation des dossiers médicaux'
      },
      'value' : '13'
    }, {
      'label' : {
        'en' : 'Videotaping',
        'fr' : ' Enregistrement vidéo'
      },
      'value' : '14'
    }, {
      'label' : {
        'en' : 'None of these Methods',
        'fr' : ' Aucune de ces méthodes'
      },
      'value' : '15'
    } ]
  }, {
    'name' : 'yes_no_list',
    'list' : [ {
      'label' : {
        'en' : 'Yes',
        'fr' : 'Oui'
      },
      'value' : '1'
    }, {
      'label' : {
        'en' : 'No',
        'fr' : 'Non'
      },
      'value' : '2'
    } ]
  }, {
    'name' : 'i_agree',
    'list' : [ {
      'label' : {
        'en' : '   I certify',
        'fr' : '    je certifie'
      },
      'value' : '1'
    } ]
  } ];

}
