import {CalendarDateFormatter, DateFormatterParams} from 'angular-calendar';
import {getISOWeek} from 'date-fns';
import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // TODO: add explicit constructor

  public weekViewTitle({ date, locale }: DateFormatterParams): string {
    const year: string = new DatePipe(locale).transform(date, 'y', locale);
    const weekNumber: number = getISOWeek(date);
    const  week=locale==='en'?'Week':'Semaine';
     const ofWeek=locale==='en'?'Of': 'De';

     return week+` ${weekNumber} ` +ofWeek+` ${year}`;
  }
}
