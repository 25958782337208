import {Component, OnInit} from '@angular/core';
import {ErrorCodesService} from '../error-codes.service';
import {eCaseAnimations} from '../../animations';
import {Router} from '@angular/router';

@Component({
  selector: 'ecase-error-code-screen',
  templateUrl: './error-code-screen.component.html',
  styleUrls: ['./error-code-screen.component.scss'],
  animations: eCaseAnimations
})
export class ErrorCodeScreenComponent implements OnInit {
  errorCode: number;

  constructor(private errorCodesService: ErrorCodesService, private router: Router) {
    this.errorCode = this.errorCodesService.errorCode;
  }

  ngOnInit(): void {
    if (!this.errorCode) {
      this.router.navigate(['/']).then(() => {});
    }
  }

}
