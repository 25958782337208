import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomGraphsService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) { }

  appendComponentToBody(vc,component) {
    // 1. Create a component reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);
// 3. Get DOM element from component
vc.insert(componentRef.hostView);
 // 5. Wait some time and remove it from the component tree and from the DOM
    setTimeout(() => {

     // this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, 3000);

  }

}
