<ng-container *ngIf="selectedLanguage">
  <div fxLayout="row" fxLayoutAlign="start" id="register">

    <div fxFlex fxHide fxShow.gt-xs id="register-intro">

      <div *eCaseIfOnDom [@animate]="{value:'*',params:{scale:'0.1'}}"
           class="logo">
        <img *ngIf="isDropShadowEnabled" class="logoImg"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo"
             style="filter: drop-shadow(0px 0px 5px #000);">
        <img *ngIf="!isDropShadowEnabled" class="logoImg"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo">
      </div>

      <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}"
           class="title">
        {{ 'welcomeToEcase' | translate }}
      </div>
      <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}"
           class="subtitle">
        {{ 'welcomeToEcaseSubHeading' | translate }}
      </div>

      <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}"
           [innerHTML]="'welcomeToEcaseDescription' | translate"
           class="description">
      </div>

    </div>

    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}"
         id="register-form-wrapper">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="40">
        </div>
        <mat-form-field *ngIf="showLanguageSwitcher" appearance="outline" class="languageSwitcher" fxFlex="60">
          <mat-select (ngModelChange)="setLanguage($event)" [(ngModel)]="selectedLanguage"
                      [attr.aria-label]="'language selector'"
                      role="listbox">
            <mat-option *ngFor="let language of languages" [attr.aria-labelledby]="language.label" [value]="language"
                        role="option">{{language.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div id="register-form">

        <div class="title" style="color:black !important"> {{ 'createAnAccount' | translate }}</div>

        <form #formDirective="ngForm" [formGroup]="registerForm" autocomplete="off" name="registerForm">


          <mat-label><span style="color: red">* </span>{{ 'fname' | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="firstName" [attr.aria-label]="'fname' | translate" formControlName="fname" matInput maxlength="50">
            <mat-error *ngIf="registerFormErrors.fname.required">
              {{ 'fNameIsRequired' | translate }}
            </mat-error>
          </mat-form-field>
          <p  class="counter-style">{{getTextLength('fname')}} / 50</p>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-label><span style="color: red">* </span>{{ 'lname' | translate }}</mat-label>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-form-field appearance="outline">
            <input [(ngModel)]="lastName" [attr.aria-label]="'lname' | translate" formControlName="lname" matInput maxlength="50">
            <mat-error *ngIf="registerFormErrors.lname.required">
              {{ 'lNameIsRequired' | translate }}
            </mat-error>
          </mat-form-field>
          <p  class="counter-style">{{getTextLength('lname')}} / 50</p>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-label><span style="color: red">* </span>{{ 'email' | translate }}</mat-label>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-form-field appearance="outline">
            <input [(ngModel)]="email" [attr.aria-label]="'email' | translate" formControlName="email" matInput maxlength="70">
            <mat-error *ngIf="registerFormErrors.email.required">
              {{ 'emailIsRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="!registerFormErrors.email.required && registerFormErrors.email.email">
              {{ 'pleaseEnterValidEmailAddress' | translate }}
            </mat-error>
            <mat-error
              *ngIf="!registerFormErrors.email.required && !registerFormErrors.email.email&& registerFormErrors.email.emailDomain">
              {{ 'ecase.common.nosfuemailforsignup' | translate }}
            </mat-error>
          </mat-form-field>
          <p  class="counter-style">{{getTextLength('email')}} / 70</p>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-label><span style="color: red">* </span>{{ 'password' | translate }}</mat-label>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-form-field
            [style.padding-top.px]="!registerFormErrors.email.required && !registerFormErrors.email.email&& registerFormErrors.email.emailDomain ? 20:0"
            appearance="outline">
            <input (focusin)="showPasswordHelpText()" [(ngModel)]="password" maxlength="50"
                   [attr.aria-label]="'password' | translate"
                   [type]="isActive ? 'password' : 'text'"
                   formControlName="password"
                   matInput>
            <button (click)="togglePassword()" aria-label="password show/hide toggle" mat-icon-button matSuffix>
              <mat-icon>{{isActive ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
            <mat-error *ngIf="registerFormErrors.password.required">
              {{ 'passwordIsRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="registerFormErrors.password.pattern "> {{ 'passwordIsWeak' | translate }}
            </mat-error>
          </mat-form-field>
          <p  class="counter-style">{{getTextLength('password')}} / 50</p>
          <br *ngIf="getWidthFromMaxlength()">
          <span *ngIf="capsLockOn" style="color: red">{{'ecase.common.capslockon' | translate}}</span><br
          *ngIf="getWidthFromMaxlength()">

          <div *ngIf="isStrongPasswordValidatorEnabled === 'true' && isPasswordHelpTextVisible" class="passwordhelptextdiv">
            <span class="listspan">{{ 'passwordHelp' | translate }}</span>
            <ul style="padding-left: 28px;">
              <li *ngFor="let text of splitText(strongPasswordHelpText[selectedLanguage.id], '|')">
                <span class="listspan">{{text}}</span>
              </li>
            </ul>
          </div>

          <mat-label><span style="color: red">* </span>{{ 'passwordConfirm' | translate }}</mat-label>
          <br *ngIf="getWidthFromMaxlength()">
          <mat-form-field appearance="outline">
            <input [(ngModel)]="passwordConfirm" [attr.aria-label]="'passwordConfirm' | translate"
                   [type]="isActivePC ? 'password' : 'text'" formControlName="passwordConfirm" maxlength="50"
                   matInput>
            <button (click)="togglePasswordConfirm()" aria-label="confirm password show/hide toggle" mat-icon-button
                    matSuffix>
              <mat-icon>{{isActivePC ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
            <mat-error *ngIf="registerFormErrors.passwordConfirm.required">
              {{ 'passwordConfirmationIsRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="registerFormErrors.passwordConfirm.passwordsNotMatch">
              {{ 'passwordMustMatch' | translate }}
            </mat-error>
          </mat-form-field>
          <p  class="counter-style">{{getTextLength('passwordConfirm')}} / 50</p>

          <!--          <mat-checkbox formControlName="isStudent" name="isStudent" aria-label="isStudent" [(ngModel)]="isStudent">-->
          <!--            {{ 'ecase.common.areyouastudent' | translate }}-->
          <!--          </mat-checkbox>-->
          <div *ngIf="selectedLanguage && showReCaptcha" id="ngxRecaptchaDiv" style="padding-top: 15px">
            <ngx-recaptcha2 #captchaElement (expire)="handleExpire()"
                            (success)="handleSuccess($event)"
                            [hl]="selectedLanguage.id"
                            [siteKey]="siteKey"
                            [theme]="'light'"
                            formControlName="reCaptcha">
            </ngx-recaptcha2>
          </div>

          <mat-error *ngIf="errorMessage" style="margin: 8px;">{{errorMessage | translate}}</mat-error>

          <div *ngIf="false" class="terms" fxLayout="row" fxLayoutAlign="center center">
            <mat-checkbox [disabled]="disable" aria-label="Accept" formControlName="check1" name="terms" required>
              <span>  {{ 'accept' | translate }}</span>
            </mat-checkbox>
            <a href="#"> {{ 't&amp;c' | translate }}</a>
          </div>


          <button (click)="validate(registerForm, formDirective)" [attr.aria-label]="'createAnAccount' | translate"
                  [disabled]="registerForm.invalid"
                  class="submit-button" color="accent" mat-raised-button
                  type="submit">
            {{ 'createAnAccount' | translate }}
          </button>


        </form>

        <div class="register" fxLayout="column" fxLayoutAlign="center center" style="font-size: 15px;">
          <span class="text">   {{ 'alreadyHaveAnAccount' | translate }}</span>
          <a [routerLink]="'/'" class="link"> {{ 'login' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
