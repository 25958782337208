import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root'
})
export class AdminCrmOrgService {

  dataSourceCrmOrganisation = [];
  selectedOrgId;
  checkForValidationCommunicationDataTable = false;
  checkForValidationAffiliationsDataTable = false;
  checkForPositionTable = false;
  isIdentityTableValid = false;
  checkForValidationAddress = false;
  checkForValidationPhon = false;
  checkForValidationEmail = false;
  checkForManitoryFileds = false;
  orcIdValidationErrorMsg = false;
  checkIsEditOrg = true;
  checkForfullAccess: boolean;
  selectedOrganization;
  selectedPerson;
  isOrgSelected = false;
  isPersoneSelected = false;
  newCounterId = 0;
  countryChosenGeneral = null;
  principalLanguage = {};

  // These variables are for perserving search data
  preserveDataSource = false;
  preserveFormData: any = false;
  preserveFilterObject: any = false;
  preserveFilterObjectForSelectFields: any = false;


  // Creating a Subject in order to redirect user to the main Documents page upon clicking
  triggerDocumentsTabForOrg: Subject<any> = new Subject<any>();


  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  triggerWhenDocumentsTabIsClicked(): void {
    this.triggerDocumentsTabForOrg.next(true);
  }



  /*  CRM ORGANIZATION SERVICES */


  selectPersone(event): void {
    this.isPersoneSelected = event;
  }

  searchCrmOrganisation(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/searchCrmOrg', obj);

  }

  countAllOrganizations(): Observable<any> {
    return this.eCaseHttpService.get('/api/countCrmOrg');
  }

  updateCrmOrganization(obj: any): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateOrg', obj);
  }

  getCrmOrganizationContactsCount(id): Observable<any> {
    return this.eCaseHttpService.get('/api/getCrmOrganizationContactsCount/' + id);
  }

  getCrmOrganizationContacts(id, rowOffSet: number, rowLimit: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getCrmOrganizationContacts',
      {
        'crmOrganizationId': id,
        'rowOffSet': rowOffSet,
        'rowLimit': rowLimit
      });
  }

  endCrmPersonAffiliationWithOrganization(crmPartyRelationId, id, crmPersonId): Observable<any> {
    return this.eCaseHttpService.post('/api/endCrmPersonAffiliationWithOrganization', {
      'crmPartyRelationId': crmPartyRelationId,
      'crmOrganizationId': +(id),
      'crmPersonId': crmPersonId
    });
  }

  createSyntoUserAccount(crmPartyRelationId, id): Observable<any> {
    return this.eCaseHttpService.post('/api/createSyntoUserAccount', {
      'crmPartyRelationId': crmPartyRelationId,
      'crmOrganizationId': Number(id)
    });
  }

  deleteCrmPersonAffiliation(crmPartyRelationId, crmPersonId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCrmPersonAffiliation', {
      'crmPartyRelationId': crmPartyRelationId,
      'crmPersonId': crmPersonId
    });
  }

  addCrmPersonAffiliationToOrganization(crmOrganizationId, crmPersonId, crmPositionId, contractType, workingHours, annualSalary, startDate, otherOrganization): Observable<any> {
    return this.eCaseHttpService.post('/api/addCrmPersonAffiliationToOrganization',
      {
        'crmPositionId': crmPositionId,
        'crmPersonId': crmPersonId,
        'crmOrganizationId': Number(crmOrganizationId),
        'contractType': contractType,
        'workingHours': (!workingHours || workingHours === '') ? undefined : workingHours,
        'annualSalary': (!annualSalary || annualSalary === '') ? undefined : annualSalary,
        'startDate': startDate,
        'otherOrganization': (!otherOrganization || otherOrganization === '') ? undefined : otherOrganization
      });
  }


  archiveUnarchiveCrmOrg(id, isArchived): Observable<any> {
    console.log(isArchived);
    return this.eCaseHttpService.post('/api/archiveUnarchiveCrmOrganisation', {
      'id': id,
      'isArchived': isArchived
    });
  }


  setTheStatusToApprove(ids): Observable<any> {
    return this.eCaseHttpService.post('/api/approveCrmOrg', {'ids': ids});
  }

  getOrganisationData(id): Observable<any> {
    return this.eCaseHttpService.get('/api/editOrg/' + id);
  }

  checkIfOrganizationNameExists(name: string, lang: string): Observable<any> {
    return this.eCaseHttpService.post('/api/checkIfOrganizationNameExists', {
      'name' : name,
      'lang' : lang
    });
  }

  getHierarchicalOrgForOrganization(crmOrganizationId?): Observable<any> {
    if (crmOrganizationId) {
      return this.eCaseHttpService.post('/api/getHierarchicalOrganizationsForOrganization', {id: crmOrganizationId});
    } else {
      return this.eCaseHttpService.post('/api/getHierarchicalOrganizationsForOrganization', {});
    }
  }

  getAllIdentitiesForCrmOrgAndPerson(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllIdentitiesForCrmOrganizationAndPerson');
  }

  updateCrmOrganizationStatus(id, status): Observable<any> {
    return this.eCaseHttpService.post('/api/updateCrmOrganizationStatus', {
      crmOrganizationId: id,
      newStatusId: status
    });
  }


  /* DOCUMENTS TAB SERVICES */


  getDocumentAccessRolesForCrmOrgDocument(id, edmDocumentRepositoryId, isPerson): Observable<any> {
    if (isPerson) {
      return this.eCaseHttpService.post('/api/getDocumentAccessRolesForCrmPersonDocument', {
        'crmPersonId': id,
        'edmDocumentRepositoryId': edmDocumentRepositoryId
      });
    } else {
      return this.eCaseHttpService.post('/api/getDocumentAccessRolesForCrmOrganizationDocument', {
        'crmOrganizationId': id,
        'edmDocumentRepositoryId': edmDocumentRepositoryId
      });
    }

  }

  getSystemRolesForDocumentAccess(): Observable<any> {
    return this.eCaseHttpService.get('/api/getSystemRolesForDocumentAccess');
  }

  getCrmAdminDocuments(id, isPerson): Observable<any> {
    if (isPerson) {
      return this.eCaseHttpService.post('/api/getCrmPersonDocuments', {'crmPersonId': id});
    } else {
      return this.eCaseHttpService.post('/api/getCrmOrganizationDocuments', {'crmOrganizationId': id});
    }
  }


  archiveCrmDocument(id, edmDocumentRepositoryId, isPerson): Observable<any> {
    if (isPerson) {
      return this.eCaseHttpService.post('/api/archiveCrmPersonDocument', {
        'crmPersonId': id,
        'edmDocumentRepositoryId': edmDocumentRepositoryId
      });
    } else {
      return this.eCaseHttpService.post('/api/archiveCrmOrganizationDocument', {
        'crmOrganizationId': id,
        'edmDocumentRepositoryId': edmDocumentRepositoryId
      });
    }
  }

  saveCrmDocument(formData: FormData, isPerson): Observable<any> {
    if (isPerson) {
      return this.eCaseHttpService.post('/api/saveCrmPersonDocument', formData);
    } else {
      return this.eCaseHttpService.post('/api/saveCrmOrganizationDocument', formData);
    }
  }

  searchForOrganizationViaSirene(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/searchForOrganizationViaSirene', obj);
  }

  addOrganizationViaSirene(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrganizationViaSirene', obj);
  }

  /* COMPLEMENTARY INFORMATION TAB SERVICES */

  public getComplementaryInformationFormDataForFields(formId: number, crmOrganizationId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getOrganizationAdditionalInformationFormDataForFieldsForOrg', {
      'formId': formId,
      'crmOrganizationId': crmOrganizationId,
      'caseJsonDataRepositoryId': -1,
      'isFormForConsent': true
    });
  }

  /*  METHODS USED ALL OVER CRM MODULE*/
  sortLovArrayAlphabetically(sortingArray, selectedLang): any {
    sortingArray = sortingArray.sort((a, b) => {
      a.value[selectedLang] = (a.value[selectedLang] === null || a.value[selectedLang] === undefined) ? '' : a.value[selectedLang];
      b.value[selectedLang] = (b.value[selectedLang] === null || b.value[selectedLang] === undefined) ? '' : b.value[selectedLang];
      return (a.value[selectedLang]).toLowerCase().localeCompare((b.value[selectedLang]).toLowerCase());
    });
    return sortingArray;
  }

  sortTermArrayAlphabetically(sortingArray, selectedLang): any {
    sortingArray = sortingArray.sort((a, b) => {
      a.label[selectedLang] = (a.label[selectedLang] === null || a.label[selectedLang] === undefined) ? '' : a.label[selectedLang];
      b.label[selectedLang] = (b.label[selectedLang] === null || b.label[selectedLang] === undefined) ? '' : b.label[selectedLang];
      return (a.label[selectedLang]).toLowerCase().localeCompare((b.label[selectedLang]).toLowerCase());
    });
    return sortingArray;
  }

  isPrincipalFieldAvailable(dataSet: any[], action: string): boolean {
    console.log(dataSet);
    let isPrincipalOptionAllowed;
    if (action === 'add') {
      if (dataSet.length === 0) {
        isPrincipalOptionAllowed = true;
      } else {
        // dataSet HAS values
        isPrincipalOptionAllowed = !(dataSet.filter(item => item.isPrincipal).map(item => item.isPrincipal)[0]);
        console.log('dataSet HAS values ', isPrincipalOptionAllowed);
      }
    } else {
      // EDIT LOGIC
      isPrincipalOptionAllowed = true;
    }

    return isPrincipalOptionAllowed;
  }


  createLocationAddress(dataSet: any[], countryArray: any[], provinces: any[], selectedLang: string): any[] {
    return dataSet.map((item) => {
      const countryName = (item.countryTermId) ? ' ' + countryArray.filter(_item => _item.id === item.countryTermId).map(_item => _item.value[selectedLang])[0] : '';
      const provinceName = (item.provinceTermId) ? ' ' + provinces.filter(_item => _item.id === item.provinceTermId).map(_item => _item.value[selectedLang])[0] : '';

      item.formattedAddress = (item.line1 && item.line1 !== '' ? item.line1 : '')
        + (item.line2 && item.line2 !== '' ?  ' ' + item.line2 : '')
        + (item.line3 && item.line3 !== '' ? ' ' + item.line3 : '')

        + (item.city && item.city !== '' ? ' ' + item.city : '')

        + provinceName
        + (item.postalCode && item.postalCode !== '' ? ' ' + item.postalCode : '')
        + countryName
        + (item.cedex && item.cedex !== '' ? ' ' + item.cedex : '');
      return item;
    });

  }

}
