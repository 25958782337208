import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'lovFilter'
})
export class LovFilterPipe implements PipeTransform {

  transform(lovSearchResults: any[], lovSearchText: string): any[] {
    if (!lovSearchResults) {
      return [];
    }
    if (!lovSearchText) {

      return [];
    } else {
      lovSearchText = lovSearchText.toLowerCase();
    }
    return lovSearchResults.filter((lov) => {
      if (lov) {

        return lov.toLowerCase().includes(lovSearchText);
      }
    });


  }
}
