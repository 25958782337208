import {AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BaseReCaptchaComponent} from 'ngx-captcha';
import {DOCUMENT} from "@angular/common";
import {
  eCaseAnimations,
  ECaseConfigService, EcaseHttpService, ECaseSnackBarService,
  ECaseTranslationLoaderService, ECaseUtils,
  LanguageService,
  LoginAuthenticationService, ParametersService, TitleService
} from "synto-common";
import {Platform} from "@angular/cdk/platform";

@Component({
  selector: 'eCase-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: eCaseAnimations
})
export class ECaseRegisterComponent implements OnInit, AfterViewInit {
  isActive = true;
  isActivePC = true;
  registerForm: FormGroup;
  registerFormErrors: any;
  selectedLanguage: any;
  showReCaptcha: boolean;
  firstName: any;
  lastName: any;
  email: any;
  password: any;
  passwordConfirm: any;
  organisation: any;
  languages: any[];
  siteKey = '6LfSQE4UAAAAAKh8VMqKyfnNLnjTQAgVRY6-d555';
  captchaResponse;
  errorMessage;
  logoSrc = '';
  strongPasswordHelpText = '{\'en\' : \'\', \'fr\' : \'\'}';
  isPasswordHelpTextVisible = false;
  organisations = [];
  orchid;
  // isStudent: boolean;
  capsLockOn = false;
  showLanguageSwitcher: boolean;
  @ViewChild('captchaElement') captchaElem: BaseReCaptchaComponent;
  isStrongPasswordValidatorEnabled = 'false';
  isDropShadowEnabled = false;

  constructor(
    private eCaseConfig: ECaseConfigService,
    private eCaseSnackBarService: ECaseSnackBarService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private eCaseHttpService: EcaseHttpService,
    private titleService: TitleService,
    private translationLoader: ECaseTranslationLoaderService,
    private loginAuthenticationService: LoginAuthenticationService,
    private parametersService: ParametersService,
    public platform: Platform,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.eCaseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
    this.registerFormErrors = {
      fname: {},
      lname: {},
      email: {},
      password: {},
      passwordConfirm: {},
      orchid: {},

    };
    this.translationLoader.loadTranslations(27);
    this.titleService.setTitleWithSeparator(['createAnAccount'], '');
  }

  splitText(text: string, splitString: string): string[] {
    return text.split(splitString);
  }

  showPasswordHelpText() {
    const control = this.registerForm.get('password');
    if (control.invalid) {
      this.isPasswordHelpTextVisible = true;
    }
  }

  ngOnInit() {
    this.titleService.setTitleWithSeparator(['createAnAccount'], '');
    this.isStrongPasswordValidatorEnabled = this.parametersService.parameter['/core/ui/strong_password_validation/enabled'];
    this.isDropShadowEnabled = this.parametersService.parameter['/core/ui/hosting_organisation_logo_drop_shadow/enabled'] === 'true';
    this.showLanguageSwitcher = this.parametersService.parameter['/core/show_language_switcher/enabled'] === 'true';
    const passwordValidationPattern = this.parametersService.parameter['/core/strong_password_validator_regex_pattern'];
    console.log(passwordValidationPattern);
    this.strongPasswordHelpText = JSON.parse(this.parametersService.parameter['/core/ui/strong_password_help_text']);
    if (this.parametersService.parameter['/core/ui/account_creation/enabled'] === 'true' || this.parametersService.parameter['/core/ui/account_creation/url/enabled'] === 'true') {
      this.isStrongPasswordValidatorEnabled = this.parametersService.parameter['/core/ui/strong_password_validation/enabled'];
      this.registerForm = this.isStrongPasswordValidatorEnabled === 'true' ? this.formBuilder.group({
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, this.emailDomainValidator]],
        password: ['', [Validators.required, Validators.pattern(passwordValidationPattern)]],
        reCaptcha: ['', Validators.required],
        orchid: [''],
        // isStudent: [""],
        passwordConfirm: ['', [Validators.required, confirmPassword]]
      }) : this.formBuilder.group({
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, this.emailDomainValidator]],
        password: ['', [Validators.required]],
        reCaptcha: ['', Validators.required],
        orchid: [''],
        // isStudent: [""],
        passwordConfirm: ['', [Validators.required, confirmPassword]]
      });


      this.registerForm.valueChanges.subscribe((event) => {
        this.onRegisterFormValuesChanged();
      });
      this.logoSrc = this.parametersService.parameter['/core/ui/organisation_logo/url'];
      this.languages = this.languageService.languageList;
      if (this.languages && this.languages.length > 0) {
        this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
      } else {
        this.languageService.getAllLanguages().subscribe((data) => {
          this.languages = this.languageService.createLanguage(data);
          this.languageService.languageList = this.languages;
          this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
        });
      }
      this.updateLogoSrc(this.translate.getDefaultLang());
      this.showReCaptcha = true;
    } else {
      this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.accountcreationdisabled'));
      this.router.navigate(['/']);
    }
  }

  getWidthFromMaxlength() {
    if (this.platform.ANDROID || (this.platform.IOS && !navigator.userAgent.match(/(iPad)/))) {
      return true;
    } else {
      return false;
    }
  }


  setLanguage(lang) {
    this.selectedLanguage = lang;
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
    this.document.documentElement.lang = lang.id;
    this.titleService.setTitleWithSeparator(['createAnAccount', 'ecase.common.defaultBrowserTabTitle'], ' | ');
    this.updateLogoSrc(lang.id);
  }

  updateLogoSrc(lang) {
    if (this.parametersService.parameter['/core/ui/organisation_logo/multilingual_logo/enabled'] === 'true') {
      const splitSrc = this.logoSrc.split('_');
      this.logoSrc = splitSrc[0] + '_' + lang + '.' + splitSrc[1].split('.')[1];
    }
  }

  getTextLength(l): number {
    try {
      return this.registerForm.controls[l].value ? this.registerForm.controls[l].value.length : 0;
    } catch (e) {
      return 0;
    }
  }

  handleExpire() {
    this.captchaResponse = '';
    location.reload();
  }


  handleSuccess($event) {
    this.captchaResponse = $event;
  }

  emailDomainValidator(control: FormControl) {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (domain.toLowerCase() === 'sfu.ca') {
        return {
          emailDomain: {
            parsedDomain: domain
          }
        };
      }
    }
    return null;
  }


  validate(registerForm, formDirective) {
    // this.showReCaptcha = false;
    const user: any = {};
    user.userRow = {};
    user.userRow.id = 0;
    user.userRow.username = this.email;
    user.userRow.password = this.password;
    user.userRow.statusId = 2;
    user.userRow.firstName = this.firstName;
    user.userRow.familyName = this.lastName;
    user.userRow.primeRoleId = 3;
    user.roles = [3];
    user.selectedLanguage = this.translate.getDefaultLang();
    user.orchidId = this.orchid ? this.orchid : undefined;
    user.reCaptchaResponse = this.captchaResponse;
    // user.isStudent = this.isStudent ? this.isStudent : undefined;
    this.loginAuthenticationService.createAccountAndSendActivationLink(user).subscribe((_response: any) => {
      if (_response.status) {
        this.eCaseSnackBarService.showComponent('success', ECaseUtils.getTranslatedValueFromKey(this.translate,'ecase.common.activationlinksent'));
        this.router.navigate(['/']);
      } else {
        this.errorMessage = _response.message;
        this.showReCaptcha = true;
        registerForm.reset();
        formDirective.resetForm();
        this.captchaElem.reloadCaptcha();
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, this.errorMessage));
        this.ngOnInit();
      }
    });

  }


  onRegisterFormValuesChanged() {
    for (const field in this.registerFormErrors) {
      if (!this.registerFormErrors.hasOwnProperty(field)) {
        continue;
      }
      this.registerFormErrors[field] = {};
      const control = this.registerForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.registerFormErrors[field] = control.errors;
      }
      if (field === 'password') {
        this.isPasswordHelpTextVisible = control.invalid && control.dirty;
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  togglePassword() {
    this.isActive = !this.isActive;
  }

  togglePasswordConfirm() {
    this.isActivePC = !this.isActivePC;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const documentInputFields = document.getElementById('ngxRecaptchaDiv').getElementsByTagName('textarea');
      for (let i = 0; i < documentInputFields.length; i++) {
        if (!documentInputFields[i].getAttributeNames().includes('aria-label')) {
          documentInputFields[i].setAttribute('aria-label', ECaseUtils.getTranslatedValueFromKey(this.translate, 'Google Recaptcha'));
        }
      }
    }, 4000);
  }
}


function confirmPassword(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }
  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');
  if (!password || !passwordConfirm) {
    return;
  }
  if (passwordConfirm.value === '') {
    return;
  }
  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}
