import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root'
})
export class SecuritycenterService {

  rolesArray = [];
  navigationToChildeComponent = false;
  assignedRoles = [];
  ssoUserId;
  securityCenterUSerSearch;
  securityCenterUserDatasource;

  //used by activation codes dialog
  userDetails;
  isReset;

  constructor(private eCaseHttpService: EcaseHttpService) {
  }


  deleteSsoRoleFromSsoUser(ssoUserId, ssoRoleId, organisationId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteSsoRoleFromSsoUser', {
      'ssoUserId': ssoUserId,
      'ssoRoleId': ssoRoleId,
      'organisationId': organisationId ? organisationId : undefined
    });
  }

  addOrUpdateSsoUser(ssoUser): Observable<any> {
    return this.eCaseHttpService.post('/api/addOrUpdateSsoUser', ssoUser);
  }

  changeUsername(ssoUserId, username): Observable<any> {
    return this.eCaseHttpService.post('/api/applyActionsOnSsoUserchangeUsername', {
      'ssoUserId': ssoUserId,
      'username': username
    });
    //
  }

  getSsoUser(ssoUserId): Observable<any> {
    return this.eCaseHttpService.get('/api/getSsoUser/' + ssoUserId);
  }

  unlockUser(ssoUserId): Observable<any> {
    return this.eCaseHttpService.get('/api/unlockUser/' + ssoUserId);
  }

  addSsoRoleToSsoUser(ssoUserId, ssoRoleId, organizationId): Observable<any> {
    return this.eCaseHttpService.post('/api/addSsoRoleToSsoUser', {
      'ssoUserId': ssoUserId,
      'organizationId': organizationId,
      'ssoRoleId': ssoRoleId
    });
  }

  getCrmOrganizationsForSpecificPerson(ssoUserId): Observable<any> {
    return this.eCaseHttpService.get('/api/getCrmOrganizationsForSpecificPerson/' + ssoUserId);
  }

  deleteCrmSignature(ssoUserId, signatureId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteCrmSignature', {
      'ssoUserId': ssoUserId,
      'signatureId': signatureId
    });
  }

  getRoleListForSsoUser(): Observable<any> {
    return this.eCaseHttpService.post('/api/getRoleListForSsoUser', {});
  }

  searchForSsoUsers(userSearch): Observable<any> {
    return this.eCaseHttpService.post('/api/searchForSsoUsers', userSearch);
  }

  applyActionsOnSsoUser(action, ssoUserIds): Observable<any> {
    return this.eCaseHttpService.post('/api/applyActionsOnSsoUser', {
      'action': action,
      'ssoUserIds': ssoUserIds
    });
  }

  countSsoUsers(): Observable<any> {
    return this.eCaseHttpService.get('/api/countSsoUsers');
  }

  getAllRolesForSecurityCenter(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllRolesForSecurityCenter ');
  }

  deleteSsoRole(ssoRoleId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteSsoRole', {'ssoRoleId': ssoRoleId});
  }

  addSsoRole(roleName, roleDescription, parentRoleId, ssoRolePrivilegeIds, sysFeatureIds, isMfaRequired): Observable<any> {
    return this.eCaseHttpService.post('/api/addSsoRole ', {
      'roleName': roleName,
      'roleDescription': roleDescription,
      'parentRoleId': parentRoleId,
      'ssoRolePrivilegeIds': ssoRolePrivilegeIds,
      'sysFeatureIds': sysFeatureIds,
      'isMfaRequired' : isMfaRequired
    });
  }

  // addSsoRole(item) {
  //   return this.eCaseHttpService.post('/api/addSsoRole ',item);
  // }

  getSsoRole(ssoRoleId: string): Observable<any> {
    return this.eCaseHttpService.get('/api/getSsoRole/' + ssoRoleId);
  }

  editSsoRole(obj): Observable<any> {
    return this.eCaseHttpService.post('/api/editSsoRole ', obj);
    // return this.eCaseHttpService.post('/api/editSsoRole ',  {
    //   'ssoRoleId': ssoRoleId,
    //   'roleName': roleName,
    //   'roleDescription': roleDescription
    // });
  }

  // addSsoRolePrivilegesToSsoRole (item) {
  //   return this.eCaseHttpService.post('/api/addSsoRolePrivilegesToSsoRole ', item);
  // }

  addSsoRolePrivilegesToSsoRole(ssoRoleId, ssoRolePrivilegeIds): Observable<any> {
    return this.eCaseHttpService.post('/api/addSsoRolePrivilegesToSsoRole ', {
      'ssoRoleId': ssoRoleId,
      'ssoRolePrivilegeIds': ssoRolePrivilegeIds
    });
  }

  deleteSsoRolePrivilege(ssoRoleId, ssoRolePrivilegeId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteSsoRolePrivilege',
      {
        'ssoRoleId': ssoRoleId,
        'ssoRolePrivilegeId': ssoRolePrivilegeId
      });
  }

  deleteSysFeatureFromRole(ssoRoleId, sysFeatureId): Observable<any> {
    return this.eCaseHttpService.post('/api/deleteSysFeatureFromRole ', {
      'ssoRoleId': ssoRoleId,
      'sysFeatureId': sysFeatureId
    });
  }

  addSysFeatureToRole(ssoRoleId, sysFeatureIds): Observable<any> {
    return this.eCaseHttpService.post('/api/addSysFeatureToRole ', {
      'ssoRoleId': ssoRoleId,
      'sysFeatureIds': sysFeatureIds
    });
  }

  getSpecificFeatures(sysFeatureIds): Observable<any> {
    return this.eCaseHttpService.post('/api/getSpecificFeatures ', {
      'featureIds': sysFeatureIds
    });
  }

  getAllSysFeatures(ssoRoleId, excludingFeatureIds): Observable<any> {
    const obj = {
      'ssoRoleId': ssoRoleId,
      'excludingFeatureIds': excludingFeatureIds
    };
    return this.eCaseHttpService.post('/api/getAllSysFeatures', obj);
  }

  resetDate(userId, isPassword): Observable<any> {
    if(isPassword)
    {return this.eCaseHttpService.get('/api/resetPasswordDate/'+ userId + '/' + !!isPassword);}
    else
    {return this.eCaseHttpService.get('/api/resetActivationDate/'+ userId+ '/' + !!isPassword);}
  }

}
