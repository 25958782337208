import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  eCaseAnimations,
  EcaseHttpService, ECaseUtils,
  ECaseUtilsGlobal,
  LanguageService,
  LoginAuthenticationService, ParametersService, TitleService
} from "synto-common";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css'],
  animations: eCaseAnimations
})
export class PrivacyComponent implements OnInit, AfterViewInit {

  cookiePolicyContentObject = {
    'en' : 'It will be updated soon...',
    'fr' : 'Il sera mis à jour prochainement ...',
    'ar' : 'ستكون محدثة قريبا...',
  };
  cookiePolicyContentValue = '';
  selectedLanguage;
  languages: any[];

  constructor(public translate: TranslateService, private titleService: TitleService,
              private eCaseHttpService: EcaseHttpService, private parametersService: ParametersService,
              private languageService: LanguageService, private loginAuthenticationService: LoginAuthenticationService) {
  }

  ngOnInit(): void {
    this.titleService.setTitleWithSeparator(['ecase.common.privacypolicy']);
    this.languageService.getAllLanguages().subscribe((data) => {
      this.languages = this.languageService.createLanguage(data);
      this.languageService.languageList = this.languages;
      this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
      this.translate.use(this.selectedLanguage.id);
      this.translate.setDefaultLang(this.selectedLanguage.id);
      const obj = {
        taxonomyId: '75'
      };
      this.eCaseHttpService.post('/api/getAllTermsWithTaxonomyId', obj).subscribe((response: any) => {
        if (response.status) {
          const privacyPolicyObj = response.data.allTerms.filter(item => item.id === ECaseUtilsGlobal.GDPR_COMPLIANCE_PRIVACY_NOTICE_TERM_ID)[0];
          if (privacyPolicyObj) {
            Object.keys(privacyPolicyObj.label).forEach((key) => {
              this.cookiePolicyContentObject[key] = ECaseUtils.replaceAllWithoutRegex(privacyPolicyObj.label[key], '${baseUrl}', this.loginAuthenticationService.baseUrl);
            });
          }
          this.cookiePolicyContentValue = this.cookiePolicyContentObject[this.translate.getDefaultLang()];
        }
      });

    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize(null);
    }, 200);
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    // Use the selected language for translations
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
    this.titleService.setTitleWithSeparator(['ecase.common.privacypolicy']);
    this.cookiePolicyContentValue = this.cookiePolicyContentObject[lang.id];
  }

  onResize(event) {
    const element = document.getElementById('content');
    const newHeight = window.innerHeight - 124;
    element.setAttribute('style', 'padding:15px;max-height:' + newHeight + 'px;');
  }

}
