import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AnimationBuilder, AnimationPlayer} from '@angular/animations';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class MatProgressBarService {
  splashScreenEl;
  public player: AnimationPlayer;
  private matProgressBarEvent = new Subject<any>();


  toggleMatProgress(data) {
    this.matProgressBarEvent.next(data);
  }

  onMatProressBarToggle(): Observable<any> {
    return this.matProgressBarEvent.asObservable();
  }

  constructor(
    private animationBuilder: AnimationBuilder,
    @Inject(DOCUMENT) private document: any,
    private router: Router
  ) {
    // Get the splash screen element app-root
    this.splashScreenEl = this.document.body.querySelector('#eCase-progress-bar');

    // If the splash screen element exists...
    if (this.splashScreenEl) {
      // Hide it on the first NavigationEnd event
      const hideOnLoad = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            setTimeout(() => {
              this.hide();

              // Unsubscribe from this event so it
              // won't get triggered again
              hideOnLoad.unsubscribe();
            }, 0);
          }
        }
      );
    }
  }

  show() {
    this.splashScreenEl.style.visibility = 'visible';
  }

  hide() {
    this.splashScreenEl.style.visibility = 'hidden';
  }
}
