import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {map} from 'rxjs/operators';
import {ECaseSnackBarService} from './snack-bar.service';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class PanelRoundService implements CanActivate {

  static REVIEW_ROUND_TYPE_ID: any = 1;
  static SELF_ASSESSMENT_ROUND_TYPE_ID: any = 2;

  selectedRound: any;

  _panelRoundTabSelectedSubject = new Subject<any>();

  constructor(private eCaseHttpService: EcaseHttpService, private snackBarService: ECaseSnackBarService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.selectedRound = {type: 1};
    if (this.selectedRound) {
        return !route.data.guards || route.data.guards.filter(guard => guard === this.selectedRound.type).length > 0;
    } else {
      return this.eCaseHttpService.get(`api/review/getPanelRound/${route.params['roundId']}`).pipe(map((round: any) => {
        if (round.status) {
          this.selectedRound = {type: 1};
        }
        return round.status && (!route.data.guards || route.data.guards.filter(guard => guard === round.type).length > 0);
      }));
    }
  }

  get onPanelRoundTabSelected() {
    return this._panelRoundTabSelectedSubject.asObservable();
  }

  selectPanelRoundTab(event) {
    this._panelRoundTabSelectedSubject.next(event);
  }

}
