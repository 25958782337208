import {Subject} from "rxjs/internal/Subject";
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SectionBreadCrumbService {
  setSectionforBreadCrumb = new Subject();
  removeforBreadCrumb = new Subject();
  removeforBreadCrumbAtIndex = new Subject();
  addCrumbs = new Subject();
}
