export  enum Type{

  Text='text',
  TextArea='textarea',
  Checkbox='Checkbox',
  RadioButton='Radiobutton',
  Email='Email',
  Table='Table',
  DatePicker='datepicker',
  StaticText='statictext',
  WarningText='warningtext',
  AutoComplete= 'AutoComplete',
  Currency='currency',
  Select='select',
  Upload='upload'

}

