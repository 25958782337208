import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {EcaseHttpService} from './ecase-http.service';
import {ECaseSnackBarService} from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService implements CanActivate {
  static SELF_ASSESSMENT_STAGE_TYPE_ID: any = 1;
  static REVIEW_STAGE_TYPE_ID: any = 2;
  static REVIEW_MEETING_STAGE_TYPE_ID: any = 3;
  static DIRECTOR_REVIEW_STAGE_TYPE_ID: any = 8;
  static COMMITTEE_ROLE_EXTERNAL: any = 6;
  static REVIEW_ROLE_INTERNAL: any = 1;
  static REVIEW_ROLE_EXTERNAL: any = 5;

  public languages;
  selectedMeeting: any;
  selectedRatingScale: any;
  isCreationMeeting: boolean = false;

  getMeetingEventDocuments: any;

  private _meetingTabSelectedSubject = new Subject<any>();
  private _meetingSelection = new Subject<any>();

  constructor(private eCaseHttpService: EcaseHttpService, private snackBarService: ECaseSnackBarService) {
    this.fetchLanguages();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.selectedMeeting) {
      return true;
    } else {
      return this.eCaseHttpService.get(`api/review/meeting/getMeeting/${route.params['meetingId']}`).pipe(map((meeting: any) => {
        if (meeting.status) {
          this.selectedMeeting = meeting.response[0];
        }
        return meeting.status;
      }));
    }
  }

  get onMeetingSelected(): Observable<any> {
    return this._meetingSelection.asObservable();
  }

  selectMeeting(event): void {
    this._meetingSelection.next(event);
  }

  get onMeetingTabSelected(): Observable<any> {
    return this._meetingTabSelectedSubject.asObservable();
  }

  selectMeetingTab(event): void {
    this._meetingTabSelectedSubject.next(event);
  }

  getMeetings(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getAllGrantsMeetings');
  }

  getAllAttendees(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllReviewCommittees');
  }
  getAllAttendeesFromAllPanelsByMeeting(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`api/review/meeting/getAllAttendeesFromAllPanelsByMeeting/${meetingId}`);
  }

  getMeetingAttendance(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`api/review/meeting/getMeetingAttendance/${meetingId}`);
  }

  addMeeting(newCommittee): Observable<any> {
    return this.eCaseHttpService.post('/api/addReviewMeeting', newCommittee);
  }

  updateMeeting(committee): Observable<any> {
    return this.eCaseHttpService.post('/api/updateReviewMeeting', committee);
  }

  deleteMeeting(committeeId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/inactiveMeeting', {'meetingId': committeeId});
  }

  getMeeting(committeeId): Observable<any> {
    return this.eCaseHttpService.post('/api/getReviewMeeting', {'committeeId': committeeId});
  }

  getMeetingPanels(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`api/review/meeting/getMeetingPanels/${meetingId}`);
  }

  getPanels(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getPanelsWithReviewStageActive');
  }

  removePanel(meetingId, panelId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/removeReviewMeetingPanel', {'meetingId': meetingId, 'panelId' : panelId});
  }

  fetchLanguages(): void {
    this.eCaseHttpService.get('/api/getLanguages').subscribe((data) => {
      this.languages = data;
    });
  }

  addUpdateMeetingAttendance(meetingId, usersId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/addUpdateMeetingAttendance',{'meetingId' : meetingId, 'users' : usersId});
  }
  getAllMeetingAccessLevelTypes(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getAllMeetingAccessLevel');
  }

  updateAttendanceMember(meetingAttendanceId, newVal, isVoting): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/updateAttendanceMember', {'meetingAttendanceId' : meetingAttendanceId, 'value' : newVal, 'isVoting': isVoting });
  }

  removeAttendance(meetingAttendanceId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/removeAttendance', {'meetingAttendanceId' : meetingAttendanceId });
  }


  getAllDecisions(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getAllMeetingDecisionForms');
  }

  getAllPlenaryTypes(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getAllMeetingPlenaryTypes');
  }

  getMeetingDecision(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingDecision/${meetingId}`);
  }

  getMeetingReviewFinalization(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingReviewFinalization/${meetingId}`);
  }

  getMeetingPlenary(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingPlenary/${meetingId}`);
  }

  getMeetingTemplates(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getMeetingTemplates');
  }

  getMeetingNotifications(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingNotifications/${meetingId}`);
  }

  getMeetingAgendaItemText(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingAgendaItemText/${meetingId}`);
  }

  getMeetingAgendaApplications(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingAgendaApplications/${meetingId}`);
  }

  getAllApplicationsFromAllPanelsByMeeting(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getAllApplicationsFromAllPanelsByMeeting/${meetingId}`);
  }

  getMeetingEventMetadata(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingEventMetadata/${meetingId}`);
  }

  getMeetingOrganizers(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingOrganizers/${meetingId}`);
  }

  getMeetingDocuments(meetingId): Observable<any> {
    return this.eCaseHttpService.get(`/api/review/meeting/getMeetingDocuments/${meetingId}`);
  }

  addUpdateMeetingConfig(postObj): Observable<any>  {
    return this.eCaseHttpService.post('/api/review/meeting/addOrUpdateMeetingConfig', postObj);
  }

  saveMeetingApplications(meetingId, applicationIds): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/saveMeetingApplications', {'meetingId':meetingId, 'caseApplications':applicationIds});
  }

  removeMeetingApplication(meetingId, caseApplicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/removeMeetingApplication', {'meetingId':meetingId, 'caseApplicationId':caseApplicationId});
  }

  saveMeetingAgendaItemText(meetingId, text): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/addOrUpdateAgendaText', {'meetingId':meetingId, 'text':text});
  }
  addOrUpdateEventInfo(objInfo): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/addOrUpdateEventInfo', objInfo);
  }
  addMeetingOrganizers(meetingId, idList): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/addMeetingOrganizers', {'meetingId': meetingId, 'users': idList});
  }
  removeMeetingOrganizer(meetingId, userId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/removeMeetingOrganizer', {'meetingId':meetingId, 'userId':userId});
  }
  addMeetingDocument(docObj): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/addMeetingDocument', docObj);
  }
  deleteDocument(docId): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/deleteDocument', docId);
  }

  addMeetingNotification(notObj): Observable<any> {
    return this.eCaseHttpService.post('/api/review/meeting/addMeetingNotification', notObj);
  }

  getMeetingTypes(): Observable<any> {
    return this.eCaseHttpService.get('/api/review/meeting/getMeetingTypes');
  }
  getMeetingsByUserId(): Observable<any> {
    return this.eCaseHttpService.get('/api/reviewer/getMeetingsByUserId');
  }
  getMeetingDetails(idMeeting): Observable<any> {
    return this.eCaseHttpService.get('/api/reviewer/meeting/getMeetingDetails/' + idMeeting);
  }
}

