import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshDataService {
  displayResultForKids;
  displayResult;
  private messageSource = new Subject<any>();
  private messageSourceForSelect = new Subject<any>();
  private messageSourceForStaticText = new Subject<any>();
  private saveEvent = new Subject<any>();
  private submitEvent = new Subject<any>();
  private validationErrorEvent = new Subject<any>();
  private formValidationEvent = new Subject<any>();
  private tableCancelClickEvent = new Subject<any>();
  private toggleShowSaveNavBarEvent = new Subject<any>();
  private addTableRowEvent = new Subject<any>();
  bphChange = [];
  showSecondTable = true;
  competitionId;

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  addNewTableRow(data) {
    this.addTableRowEvent.next(data);
  }

  onNewTableRowAdd(): Observable<any> {
    return this.addTableRowEvent.asObservable();
  }

  toggleShowSaveNavBar(data, provenanceString = '') {
    console.log('RAAAAAAAAAAAAAAAAAAAAAAAAAA' + data + ' : ' + provenanceString);
    this.toggleShowSaveNavBarEvent.next(data);
  }

  toggleShowSaveNavBarEventSubscriber(): Observable<any> {
    return this.toggleShowSaveNavBarEvent.asObservable();
  }

  saveForm(data) {
    this.saveEvent.next(data);
  }

  saveFormSubscriber(): Observable<any> {
    return this.saveEvent.asObservable();
  }

  submitForm(data) {
    this.submitEvent.next(data);
  }


  submitFormSubscriber(): Observable<any> {
    return this.submitEvent.asObservable();
  }

  throwValidationError(data) {
    this.validationErrorEvent.next(data);
  }

  onValidationError(): Observable<any> {
    return this.validationErrorEvent.asObservable();
  }

  formValidation(data) {
    this.formValidationEvent.next(data);
  }

  onFormValidation(): Observable<any> {
    return this.formValidationEvent.asObservable();
  }

  tableCancelClicked(data) {
    this.tableCancelClickEvent.next(data);
  }

  onTableCancelClick(): Observable<any> {
    return this.tableCancelClickEvent.asObservable();
  }


  setNewValue(data) {
    this.messageSource.next(data);
  }

  getNewValue(): Observable<any> {
    return this.messageSource.asObservable();
  }

  setNewTableList(data) {
    return this.messageSource.next(data);
  }

  getNewTableList(): Observable<any> {
    return this.messageSource.asObservable();
  }

  setNewValueForSelect(data) {
    this.messageSourceForSelect.next(data);
  }

  getNewValueForSelect(): Observable<any> {
    return this.messageSourceForSelect.asObservable();
  }

  setNewValueForAutoComplete(data) {
    this.messageSourceForSelect.next(data);
  }

  getNewValueForAutocomplete(): Observable<any> {
    return this.messageSourceForSelect.asObservable();
  }

  setNewListForRadioButton(data) {
    this.messageSourceForSelect.next(data);
  }

  getNewListForRadioButton(): Observable<any> {
    return this.messageSourceForSelect.asObservable();
  }

  setNewValueForStaticText(name, value, isDataParametrised?) {
    if (isDataParametrised) {
      this.messageSourceForStaticText.next({'name': name, 'value': value, 'isDataParametrised': isDataParametrised});
    } else {
      this.messageSourceForStaticText.next({'name': name, 'value': value});
    }
  }

  getNewValueForStaticText(): Observable<any> {
    return this.messageSourceForStaticText.asObservable();
  }

  getAllExpenditureItems(competitionId) {
    return this.eCaseHttpService.post('/api/getAllExpenditureItems', {'cpmCompetitionId': competitionId});
  }


  // All services related to getting real-time data for AutoComplete should be here

  getAllInstitutionForAutoComplete(obj) {
    return this.eCaseHttpService.post('/api/getAllInstitutionForAutoComplete', obj);
  }

  getAllUsersForAutoComplete(obj) {
    return this.eCaseHttpService.post('/api/getAllUsersForAutoComplete', obj);
  }
  getAllUsersByEmailForAutoComplete(obj) {
    return this.eCaseHttpService.post('/api/getAllUsersByEmailForAutoComplete', obj);
  }

  getAllUsersForAutoCompleteApplications(obj) {
    return this.eCaseHttpService.post('/api/getAllUsersForAutoCompleteApplications', obj);
  }

  getAllUsersByEmailWithoutPositionForAutoComplete(obj) {
    return this.eCaseHttpService.post('/api/getAllUsersByEmailWithoutPositionForAutoComplete', obj);
  }
  getAllUsersWithPhoneForAutoComplete(obj) {
    return this.eCaseHttpService.post('api/getAllUsersWithPhoneForAutoComplete', obj);
  }


  // -----------------------------------------------------------------------------
}
