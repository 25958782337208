import {enableProdMode, NgModuleRef} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    const msg = 'Hi there 👋🏽, fellow developer! Thanks for visiting Synto. Nothing to see here just a dog hanging out.\n\n' +
      '  ("\`-’-/").___..--’’"\`-._\n' +
      '   \`6_ 6  )   \`-.  (     ).\`-.__.‘)\n' +
      '   (_Y_.)’  ._   )  \`._ \`. \`\`-..-’\n' +
      ' _..\`--’_..-_/  /--’_.’ ,’\n' +
      '(il),-’‘  (li),’  ((!.-‘\n';
    console.log(msg);
    const cons: any = window.console;
    cons.syntoLog = window.console.log;
    window.console.log = (): void => {
    };
  }
}


const bootstrap = (): Promise<NgModuleRef<any>> => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap().then(() => {});


