import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';


/**
 * Created by sameer@evision.ca on 2019-09-30.
 * Usage :-
 * <textarea eCaseDragAndDropTextarea ></textarea>
 * For more example refer to fb-text-area.component.html and search for eCaseDragAndDropTextarea
 *
 */
@Directive({
  selector: '[eCaseDragAndDropTextarea]'
})
export class ECaseDragAndDropTextareaDirective implements OnInit {

  reader = new FileReader();
  value: any = '';


  constructor(private elementRef: ElementRef) {
  }

  @HostListener('dragover') dragOver(): void {
    this.elementRef.nativeElement.style.backgroundColor = 'rgba(128,128,128,0.15)';
    this.elementRef.nativeElement.style.border = 'dashed grey 3px';
  }

  @HostListener('dragleave') dragLeave(): void {
    this.elementRef.nativeElement.style.backgroundColor = '#fff';
    this.elementRef.nativeElement.style.border = 'none';
  }

  @HostListener('drop', ['$event']) drop($event: Event): void {
    $event.preventDefault();
    this.elementRef.nativeElement.style.backgroundColor = '#fff';
    this.elementRef.nativeElement.style.border = 'none';
    this.onDropFile($event);
  }

  ngOnInit(): void {
    this.reader.onload = (): void => {
      this.value = this.reader.result;
      this.elementRef.nativeElement.value = this.value;
    };
  }

  onDropFile($event): void {
    const file = $event.dataTransfer.files[0];
    this.reader.readAsText(file);
  }

}
