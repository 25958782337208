<div eCasePerfectScrollbar fxLayout="column" id="forgot-password" style="height: 100%">
  <div *eCaseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" fxLayoutAlign="center center"
       id="forgot-password-form-wrapper">
    <div id="forgot-password-form">
      <div *ngIf="showLanguageSwitcher">
        <mat-form-field appearance="outline">
          <mat-select (ngModelChange)="setLanguage($event)" [(ngModel)]="selectedLanguage">
            <mat-option *ngFor="let language of languages" [value]="language">{{(language.label ? language.label : language.nativeName)}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="logo">
        <img *ngIf="isDropShadowEnabled" class="logoImg"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo"
             style="filter: drop-shadow(0px 0px 5px #000);max-width: 500px;">
        <img *ngIf="!isDropShadowEnabled" class="logoImg"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo"
             style="max-width: 500px;">
      </div>
      <div class="title" style="color:black !important">{{ 'RECOVERPASSWORD' | translate }}</div>

      <form [formGroup]="forgotPasswordForm" name="forgotPasswordForm" novalidate>

        <mat-label><span style="color: red">* </span>{{ 'email' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input [attr.aria-label]="'email' | translate" formControlName="email" matInput maxlength="70">
          <mat-error *ngIf="forgotPasswordFormErrors.email.required">
            {{ 'emailIsRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="!forgotPasswordFormErrors.email.required && forgotPasswordFormErrors.email.email">
            {{ 'pleaseEnterValidEmailAddress' | translate }}
          </mat-error>
          <mat-error
            *ngIf="!forgotPasswordFormErrors.email.required && !forgotPasswordFormErrors.email.email&& forgotPasswordFormErrors.email.emailDomain">
            {{ 'ecase.common.resetpasswordforsfuerrror' | translate }}
          </mat-error>
        </mat-form-field>
        <p class="counter-style">{{getTextLength('email')}} / 70</p>
        <button (click)="sendactivatelink()" [disabled]="forgotPasswordForm.invalid"
                aria-label="{{ 'SENDRESETLINK' | translate }}" class="submit-button"
                color="accent" mat-raised-button>
          {{ 'SENDRESETLINK' | translate }}
        </button>
      </form>


      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a [routerLink]="'/'" class="link"> {{ 'GOBACK' | translate }}</a>
      </div>
      <!--      <p>-->
      <!--        {{ 'notefeatureavailablefornonsfu' | translate }}<a href="https://my.sfu.ca/ChangePassword" target="_blank"> https://my.sfu.ca/ChangePassword</a>-->
      <!--      </p>-->
    </div>

  </div>

</div>
