import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";

@Injectable({
  providedIn: 'root'
})
export class RulesTextService{

 updateJson;
  private messageSource = new Subject<any>();




}
