import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  goToDetailsDocuments: boolean;
  selectedApplication;
  applicationId;
  projectNumber;
  internalId;
  piName;

  constructor(private eCaseHttpService: EcaseHttpService,) {
  }

  getAttachedDocument(applicationId) {
    return this.eCaseHttpService.post('api/getAttachedDocument', { 'applicationId': applicationId });

  }

  reOpenApplication(applicationId) {
    return this.eCaseHttpService.post('/api/reOpenApplication', { 'applicationId': applicationId });

  }

  updateAgencyDocument(documentAccess) {
    return this.eCaseHttpService.post('api/updateDocumentAgency', documentAccess);

  }

  getAttachedDocumentsByAgency(applicationId) {
    return this.eCaseHttpService.post('/api/getAttachedDocumentAdmin', { 'applicationId': applicationId });

  }

  updateUploadedFile(obj) {

    return this.eCaseHttpService.post('/api/updateFile', obj);

  }

  addNewDocument(obj) {

    return this.eCaseHttpService.post('/api/addNewDocument', obj);

  }

  deleteAttachedDocument(documentId) {

    return this.eCaseHttpService.post('/api/deleteUploadedFile', { 'edmRepositoryId': documentId });
  }

  sendEmailForPI(applicationId) {
    return this.eCaseHttpService.post('/api/reminderEmailForPI', { 'applicationId': applicationId });
  }

  sendEmailForPIForPaa(applicationId) {
    return this.eCaseHttpService.post('/api/reminderEmailForPIForPaa', { 'applicationId': applicationId });
  }
}
