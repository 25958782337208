import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Form} from '../model/form.model';
import {HttpClient} from '@angular/common/http';
import {LanguageService} from './language.service';
import {ECaseUtils} from '../eCaseUtils';
import {TranslateService} from '@ngx-translate/core';
import {map, timeout} from 'rxjs/operators';
import {SectionComponetsValidationOnSaveService} from './section-componets-validation-on-save.service';


@Injectable({
  providedIn: 'root'
})
export class LoadJsonService {
  selectedFormID;
  offlineJsonForm: any;
  selectedSection;
  errorDisableBtn: boolean;
  tableComponentName: string;
  selectedComponentIndex: number;
  nestedComponentIndex: number;
  nestedLevel: number;
  blockName_errors: any;
  languages = [];
  selectedLanguage: any;
  modules = {};
  isNumberStatic: boolean;
  section;
  component;
  selectSectionTo;
  componentTo;
  isValueRanged;
  currentName;
  array = [];
  curentSematicValueArray = [];
  curentSematicValue;
  courrantSection: boolean;
  sectionandNameArray = [];
  eventName;

  constructor(private eCaseHttpService: EcaseHttpService,
              private httpClient: HttpClient,
              private languageService: LanguageService,
              private sectionCompValidationService: SectionComponetsValidationOnSaveService,
              private translate: TranslateService) {
    this.selectedLanguage = this.translate.getDefaultLang();

    this.blockName_errors =
      {
        name:
          [
            { 'type': 'nameExist', 'message': 'Block name already exists', 'status': false },
            { 'type': 'specialCharacter', 'message': 'No special characters allowed', 'status': false },
            { 'type': 'isEmpty', 'message': 'Field cannot be empty', 'status': false },
            { 'type': 'nameExistform', 'message': 'semanticLabelError', 'status': false }
          ],
        label:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],

        yearLabel:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],
        section:
          [

            {
              'type': 'isEmpty',
              'message': 'Either select section and select component should  have a value or number of years is static should be checked.',
              'status': false
            }
          ]
        ,
        sectionTo:
          [
            {
              'type': 'isEmpty',
              'message': 'Either select section and select component should  have a value or number of years is static should be checked.',
              'status': false
            }
          ]
        ,
        component:
          [
            {
              'type': 'isEmpty',
              'message': 'Either select section and select component should  have a value or number of years is static should be checked.',
              'status': false
            }
          ]
        ,isNumberOfYearsStatic:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ]
        ,

        editLabel:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],

        indexColumnLabel:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],

        taskTitle:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],
      startMonth:
        [
          {
            'type': 'isEmpty',
            'message': {}
          }
        ],
        endMonth:
        [
          {
            'type': 'isEmpty',
            'message': {}
          }
        ],
        categoryLabel:
        [
          {
            'type': 'isEmpty',
            'message': {}
          }
        ],
        monthLabel:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],
        list:
          [
            {
              'type': 'isEmptyAndValid',
              'message': 'Field cannot be empty and must have a value from the LOV',
              'status': false
            },
            { 'type': 'isEmpty', 'message': 'Field cannot be empty', 'status': false }
          ],
        toolTip:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],
        showNodata:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],
        tableLabel:
        [
          {
            'type': 'isEmpty',
            'message': {}
          }
        ],
        dialog:
          [
            {
              'type': 'isEmpty',
              'message': {}
            }
          ],
        formatTypeForUpload:
          [
            {
              'type': 'isEmpty',
              'message': 'Field cannot be empty',
              'status': false
            }
          ],
        /* documentTypeForUpload:
           [
             {
               "type": "isEmpty",
               "message": "Field cannot be empty",
               status: false
             },
             {
               "type": "isEmptyForStaticDocumentType",
               "message": "Field cannot be empty",
               status: false
             }
           ],*/
        urlForSubmitButton:
          [
            {
              'type': 'isEmpty',
              'message': 'Field cannot be empty',
              'status': false
            }

          ],
        dynamicMatrix:
          [
            {
              'type': 'rowLov',
              'message': 'Field cannot be empty and must have a value from the LOV',
              'status': false
            },
            {
              'type': 'columnLov',
              'message': 'Field cannot be empty and must have a value from the LOV',
              'status': false
            }

          ]
      };


  }

  setLanguageKeysForErrorBlocks(block, lovs): void {
    console.log('INSIDE NGONINIT of loadjsonservice');
    let message1: any;

    for (const language of this.offlineJsonForm.languages) {
      console.log(language);
      const id = language.id;
      message1 = {
        [id]: { 'message': 'Field cannot be empty', 'status': false }
      };

      this.blockName_errors.label[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.label[0].message, message1));
      this.blockName_errors.yearLabel[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.yearLabel[0].message, message1));
      this.blockName_errors.editLabel[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.editLabel[0].message, message1));
      this.blockName_errors.indexColumnLabel[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.indexColumnLabel[0].message, message1));
      this.blockName_errors.taskTitle[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.taskTitle[0].message, message1));
      this.blockName_errors.startMonth[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.startMonth[0].message, message1));
      this.blockName_errors.endMonth[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.endMonth[0].message, message1));
      this.blockName_errors.categoryLabel[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.categoryLabel[0].message, message1));
       this.blockName_errors.monthLabel[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.monthLabel[0].message, message1));
       this.blockName_errors.toolTip[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.toolTip[0].message, message1));
      this.blockName_errors.showNodata[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.showNodata[0].message, message1));
      this.blockName_errors.tableLabel[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.tableLabel[0].message, message1));
      this.blockName_errors.dialog[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.dialog[0].message, message1));
      this.blockName_errors.section[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.section[0].message, message1));
      this.blockName_errors.sectionTo[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.sectionTo[0].message, message1));
      this.blockName_errors.component[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.component[0].message, message1));
      this.blockName_errors.isNumberOfYearsStatic[0].message = ECaseUtils.eCaseCloneDeep(Object.assign(this.blockName_errors.isNumberOfYearsStatic[0].message, message1));

    }
    this.section = '';
    this.component = '';
    this.selectSectionTo = '';
    this.componentTo = '';
    this.isNumberStatic = false;
    this.isValueRanged = false;
    console.log(this.blockName_errors);
  }
  noDuplicateAllBlockNamesAllowedInForm($event){
    for (const section of this.offlineJsonForm.sections) {
      for (let i = 0; i < section.blocks.length; i++) {
        this.sectionCompValidationService.allBlockNameArray.push(section.blocks[i].name);
      }
    }
    this.array = [];
    for(const name of this.sectionCompValidationService.allBlockNameArray){
      if (name === this.currentName){
        this.array.push(name);
      }
    }
    console.log('array',this.array);
    console.log('this.sectionCompValidationService.allBlockNameArray');
    console.log(this.sectionCompValidationService.allBlockNameArray);
    console.log(this.eventName );
    console.log(this.currentName);
    for (const blockname of this.sectionCompValidationService.allBlockNameArray) {
      console.log(blockname);
      if(blockname === this.currentName  && this.eventName=== this.currentName && this.array.length>1 ){
        this.blockName_errors.name[3].status = true;
        break;
      } else {
        this.blockName_errors.name[3].status = false;
      }
    }
    this.setBtnDisable();
    this.sectionCompValidationService.allBlockNameArray = [];
    console.log(this.sectionCompValidationService.allBlockNameArray);
  }

  noDuplicateBlockNamesAllowedInForm($event){
   this.eventName = $event;
    console.log('newfORM');
    for (const section of this.offlineJsonForm.sections) {
      const sectionandNameobject: any = {};
      for (let i = 0; i < section.blocks.length; i++) {
        if(section.blocks[i].semantic && section.blocks[i].semantic.label && (section.blocks[i].semantic.label[this.translate.getDefaultLang()]) ){
          sectionandNameobject.name = section.blocks[i].name;
          sectionandNameobject.id = section.id;
        //  if( !this.sectionandNameArray.includes(sectionandNameobject)){
            if(this.sectionandNameArray.filter(item => item.id === section.id && item.name===section.blocks[i].name).length === 0){
          this.sectionandNameArray.push(sectionandNameobject);
        }}
      }
    }
    for (const item of this.sectionandNameArray) {
      console.log(item);
      if(item.name === $event && item.id !== this.selectedSection.id   ){
        this.blockName_errors.name[3].status = true;
        break;
      } else {
        this.blockName_errors.name[3].status = false;
      }
    }
    this.setBtnDisable();
  }

  // Method to show error message if the user tries to add a block Name which already exists in the form
  noDuplicateBlockNamesAllowed($event) {
    console.log('this.offlineJsonForm');
    console.log(this.offlineJsonForm.sections);
    for (const section of this.offlineJsonForm.sections) {
      if (section.id === this.selectedSection.id) {
        for (let i = 0; i < section.blocks.length; i++) {
          // if component selected is not inside the table or container
          if (!this.nestedLevel) {
            if (section.blocks[i].name === $event && i !== this.selectedComponentIndex  ) {
              // if(section.blocks[this.selectedComponentIndex].semantic ===  'undefined'){
              //   console.log("888888888888")
              //
              //   this.blockName_errors.name[0].status = true;
              // }
              // else{
              //   this.blockName_errors.name[3].status = true;
              // }
              this.blockName_errors.name[0].status = true;
              break;
            } else {
              this.blockName_errors.name[0].status = false;
             // this.blockName_errors.name[3].status = false;
            }
          } else {
            // if component selected is inside the table or container
            console.log('nested.....');
            for (let j = 0; j < section.blocks[i].subBlocks.length; j++) {
              if (section.blocks[i].subBlocks[j].name === $event && j !== this.nestedComponentIndex) {
                this.blockName_errors.name[0].status = true;
                break;
              } else {
                this.blockName_errors.name[0].status = false;
              }
            }
          }
        }
      }
    }
    console.log(this.blockName_errors);
    this.setBtnDisable();

  }


  nospecialCHaracter($event) {
    console.log('nospecialCHaracterhhhhhhhh');
    if ($event.includes('/') || $event.includes('-') || $event.includes(' ') || $event.includes('$')) {
      this.blockName_errors.name[1].status = true;
    }
    else {
      this.blockName_errors.name[1].status = false;
    }
    this.setBtnDisable();
  }

  isEmpty($event, type) {
    for (let i = 0; i < this.blockName_errors[type].length; i++) {
      if (this.blockName_errors[type][i].type === 'isEmpty') {
        if (!$event || ($event && $event.length === 0)) {
          this.blockName_errors[type][i].status = true;
        }
        else {
          this.blockName_errors[type][i].status = false;
        }
      }
    }
    this.setBtnDisable();
    console.log(this.blockName_errors);
  }

  isEmptyForLabel($event, lang, type) {
    console.log('===============================');
    console.log($event);
    console.log(lang);
    console.log(type);
    if (this.blockName_errors[type]) {
      for (let i = 0; i < this.blockName_errors[type].length; i++) {
        if (this.blockName_errors[type][0].type === 'isEmpty') {
          if (!$event || ($event && $event.length === 0)) {
            console.log('TRUE NOW: ' + type + '   : ' + $event);
            console.log(this.blockName_errors[type][0]);
            if (!this.blockName_errors[type][0].message[lang]) {
              this.blockName_errors[type][0].message[lang] = {};
            }
            this.blockName_errors[type][0].message[lang].status = true;
            // this.blockName_errors.label[0].message[lang].status= true;

          }
          else {
            console.log('NOT TRUE NOW ' + $event);
            console.log(this.blockName_errors[type][0]);
            if (!this.blockName_errors[type][0].message[lang]) {
              this.blockName_errors[type][0].message[lang] = {};
            }
            this.blockName_errors[type][0].message[lang].status = false;
            // this.blockName_errors.label[0].message[lang].status= false;
            console.log(this.blockName_errors[type][0].message[lang].status);
          }
        }
      }
      this.setBtnDisable();
      console.log('isEmpty');
    }
  }

  isEmptyAndValid($event, type, block, lovs) {
    console.log('000000000000000000000000000000000000');
    console.log($event);
    if (type === 'list' ) {
      for (let i = 0; i < this.blockName_errors[type].length; i++) {
        if (this.blockName_errors[type][i].type === 'isEmptyAndValid') {

          if ($event && $event.length !== 0) {
            console.log('111111111111111111111111111111111111111111');
            this.blockName_errors[type][i].status = true;
            if (!block.isDataFromUrl || !block.lovSQL) {
              for (const l of lovs) {
                if (block.listNameToShow === l.name[this.selectedLanguage]) {
                  this.blockName_errors[type][i].status = false;
                  break;
                }
                else {
                  this.blockName_errors[type][i].status = true;
                }
              }
            }
            else {
              this.blockName_errors[type][i].status = false;
            }
          }
          else {
            if (!block.lovSQL){
              console.log('22222222222222222222222222');
              this.blockName_errors[type][i].status = true;
            }
            else {
              console.log('3333333333333333333');
              this.blockName_errors[type][i].status = false;

            }
          }
        }
      }
    }
    if (type === 'rowLov' || type === 'columnLov') {
      console.log($event);

      /*          if(block.listNameToShowForRowLov === '' || (!(block.listNameToShowForRowLov))){
                  // this.hasError.blockList = true;
                  this.blockName_errors.dynamicMatrix[0].status = true;
                }

                if(block.listNameToShowForColumnLov === '' || (!(block.listNameToShowForColumnLov))){
                  // this.hasError.blockList = true;
                  this.blockName_errors.dynamicMatrix[1].status = true;
                }*/
      for (const l of lovs) {
        if (type === 'rowLov') {
          if ((block.listNameToShowForRowLov === l.name[this.selectedLanguage])) {
            this.blockName_errors.dynamicMatrix[0].status = false;
            break;
          } else {
            this.blockName_errors.dynamicMatrix[0].status = true;
          }
        }
        else if (type === 'columnLov') {
          if (block.listNameToShowForColumnLov === l.name[this.selectedLanguage]) {
            this.blockName_errors.dynamicMatrix[1].status = false;
            break;
          } else {
            this.blockName_errors.dynamicMatrix[1].status = true;
          }
        }
      }

    }
    this.setBtnDisable();

  }
  // ==========================================================
  isEmptySelectAndCheckboxType($event , type){

    if(this.isValueRanged){

      if( type === 'section' && this.isValueRanged ){
        this.section = $event.value.name;
        if((!$event.value.name || !this.component   )&&!this.isNumberStatic){
          this.blockName_errors.section[0].status = true;
          this.blockName_errors.isNumberOfYearsStatic[0].status = true;
        }
        else{
          this.blockName_errors.section[0].status = false;
          this.blockName_errors.isNumberOfYearsStatic[0].status = false;
        }
      }
      if( type === 'component'  && this.isValueRanged){
        this.section = $event.value.name;
        if((!$event.value.name || !this.section    )&&!this.isNumberStatic){

          this.blockName_errors.section[0].status = true;
          this.blockName_errors.isNumberOfYearsStatic[0].status = true;
        }
        else{
          this.blockName_errors.section[0].status = false;
          this.blockName_errors.isNumberOfYearsStatic[0].status = false;
        }
      }

      if( type === 'componentTo' && this.isValueRanged){
        this.componentTo = $event.value.name;
        if((!$event.value.name ||  !this.selectSectionTo )&& !this.isNumberStatic ){
          this.blockName_errors.sectionTo[0].status = true;
          this.blockName_errors.isNumberOfYearsStatic[0].status = true;
        }
        else{
          this.blockName_errors.sectionTo[0].status = false;
          this.blockName_errors.isNumberOfYearsStatic[0].status = false;
        }
      }

      if( type === 'selectSectionTo' && this.isValueRanged){
        console.log('tototototo');
        this.selectSectionTo = $event.value.name;

        if((!$event.value.name  || !this.componentTo )&& !this.isNumberStatic ){

          this.blockName_errors.sectionTo[0].status = true;
          this.blockName_errors.isNumberOfYearsStatic[0].status = true;
        }
        else{
          this.blockName_errors.sectionTo[0].status = false;
          this.blockName_errors.isNumberOfYearsStatic[0].status = false;
        }
      }

      // if(type ==="isValueRanged" ){
      //   if( $event.checked  && (!this.selectSectionTo  || !this.componentTo)){
      //     this.blockName_errors.sectionTo[0].status = true;
      //   }
      //   else{
      //     this.blockName_errors.sectionTo[0].status = false;
      //
      //   }
      // }


    }
    if( type === 'section' && !this.isValueRanged ){
      this.section = $event.value.name;
      if((!$event.value.name || !this.component )&&!this.isNumberStatic){

        this.blockName_errors.section[0].status = true;
        this.blockName_errors.isNumberOfYearsStatic[0].status = true;
      }
      else{
        this.blockName_errors.section[0].status = false;
        this.blockName_errors.isNumberOfYearsStatic[0].status = false;
      }
    }

    if( type === 'component' && !this.isValueRanged ){
      this.component = $event.value.name;
      if((!$event.value.name || !this.section )&& !this.isNumberStatic ){

        this.blockName_errors.section[0].status = true;
        this.blockName_errors.isNumberOfYearsStatic[0].status = true;
      }
      else{
        this.blockName_errors.section[0].status = false;
        this.blockName_errors.isNumberOfYearsStatic[0].status = false;
      }
    }

    if(type ==='isNumberOfYearsStatic' ){
      if( !$event.checked  && (!this.section  || !this.component)){
        this.blockName_errors.section[0].status = true;
        this.blockName_errors.isNumberOfYearsStatic[0].status = true;
      }
      else{
        this.blockName_errors.section[0].status = false;
        this.blockName_errors.isNumberOfYearsStatic[0].status = false;

      }
      console.log(this.blockName_errors);
    }
    this.setBtnDisable();
  }
// =======================================================
  isEmptyInDocumentType(block) {
    console.log(block);
    if (block.staticDocumentType === -1) {
      // this.hasError.staticDynamicSelect = true;
      // this.blockName_errors.documentTypeForUpload[0].status = true;
    } else {
      // this.blockName_errors.documentTypeForUpload[0].status = false;
    }
    /*if (block.staticDynamicSelect === 1 && !block.staticDocumentType) {
      this.blockName_errors.documentTypeForUpload[1].status = true;
    } else {
      this.blockName_errors.documentTypeForUpload[1].status = false;
    }*/
    this.setBtnDisable();
  }


  setBtnDisable() {
    let disable = false;

    for (const property in this.blockName_errors) {
      for (let i = 0; i < this.blockName_errors[property].length; i++) {
        if (this.blockName_errors[property][i].status) {
          disable = true;
        }
      }
    }

      for (const language of this.offlineJsonForm.languages) {
        if (this.blockName_errors.label[0].message[language.id]) {
        if (this.blockName_errors.label[0].message[language.id].status ||
          this.blockName_errors.yearLabel[0].message[language.id].status ||
          this.blockName_errors.editLabel[0].message[language.id].status ||
          this.blockName_errors.indexColumnLabel[0].message[language.id].status ||
          this.blockName_errors.taskTitle[0].message[language.id].status ||
          this.blockName_errors.startMonth[0].message[language.id].status ||
          this.blockName_errors.endMonth[0].message[language.id].status ||
          this.blockName_errors.categoryLabel[0].message[language.id].status ||
          this.blockName_errors.monthLabel[0].message[language.id].status ||
          this.blockName_errors.toolTip[0].message[language.id].status ||
          this.blockName_errors.showNodata[0].message[language.id].status ||
          this.blockName_errors.tableLabel[0].message[language.id].status ||
          this.blockName_errors.dialog[0].message[language.id].status
          || this.blockName_errors.isNumberOfYearsStatic[0].message[language.id].status
        ) {

          disable = true;
        }
      }
    }


    console.log(this.blockName_errors);

    if (disable) {
      this.errorDisableBtn = true;
    } else {
      this.errorDisableBtn = false;
    }

  }

  // Clear validaitons for URL and List Name according to user's selection
  clearForListNameAndUrl(block, lovs, type) {
    this.blockName_errors.section[0].status = false;
    this.blockName_errors.isNumberOfYearsStatic[0].status= false;
    if (type === 'list') {
      this.blockName_errors.list[1].status = false;
      this.blockName_errors.list[0].status = false;
    }



    else {
      for (const language of this.offlineJsonForm.languages) {
        this.customSetValues(type, false, language.id);

      }
    }
    this.setBtnDisable();
  }

  customSetValues(type, flag, lang) {
    if (type === 'label' || type === 'yearLabel'|| type === 'toolTip' || type === 'dialog' || type === 'showNodata' || type === 'tableLabel' ) {
      for (let i = 0; i < this.blockName_errors[type].length; i++) {
        // if(this.blockName_errors[] === 'label'){
        console.log(this.blockName_errors[type][i]);
        this.blockName_errors[type][i].message[lang].status = flag;
      }
    }
    else {
      //   if(type === 'name'){
      console.log('BLOCK NAME');
      this.blockName_errors.name[0].status = flag;
      this.blockName_errors.name[1].status = flag;
      this.blockName_errors.name[2].status = flag;
      this.blockName_errors.name[3].status = flag;
      // }
      // else if(type === 'upload' ){
      console.log('UPLOAD');
      this.blockName_errors.formatTypeForUpload[0].status = flag;
      /*this.blockName_errors.documentTypeForUpload[0].status = flag;
      this.blockName_errors.documentTypeForUpload[1].status = flag;*/

      console.log('SUBMIT BUTTON URL');
      this.blockName_errors.urlForSubmitButton[0].status = flag;

      console.log('DYNAMIC MATRIX LOVs');
      this.blockName_errors.dynamicMatrix[0].status = flag;
      this.blockName_errors.dynamicMatrix[1].status = flag;






      // }
    }
    // console.log(this.blockName_errors);
  }


  checkForErrorSchedulerTable(block, lovs) {

    for (const language of this.offlineJsonForm.languages) {
      if ((block.type !== 'Table' && block.type !== 'Container'  && block.type !== 'SchedulerTable' ) && (block.label[language.id] === '' || (!(block.label[language.id])))) {
        this.blockName_errors.label[0].message[language.id].status = true;
        console.log('0000000000000001');
      } else {
        this.blockName_errors.label[0].message[language.id].status = false;
      }
      if ((   block.type === 'SchedulerTable' ) && (block.monthLabel[language.id] === '' || (!(block.monthLabel[language.id])))) {
        this.blockName_errors.monthLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.monthLabel[0].message[language.id].status = false;
      }
      if (( block.type === 'SchedulerTable') && (block.editLabel[language.id] === '' || (!(block.editLabel[language.id])))) {
        this.blockName_errors.editLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.editLabel[0].message[language.id].status = false;

      }
      if (( block.type === 'SchedulerTable') && (block.indexColumnLabel[language.id] === '' || (!(block.indexColumnLabel[language.id])))) {
        this.blockName_errors.indexColumnLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.indexColumnLabel[0].message[language.id].status = false;

      }
      if (( block.type === 'SchedulerTable') && (block.taskTitle[language.id] === '' || (!(block.taskTitle[language.id])))) {
        this.blockName_errors.taskTitle[0].message[language.id].status = true;

      } else {
        this.blockName_errors.taskTitle[0].message[language.id].status = false;

      }
      if ((  block.type === 'SchedulerTable'  ) &&
        (!block.isNumberOfYearsStatic && (block.section === '' || (!(block.section))) && (block.component === '' || (!(block.component)) ))) {
        console.log('sssssssssssssssssssss');
        console.log(block.isNumberOfYearsStatic);
        // console.log(block.section.name)
        console.log(block);

        this.blockName_errors.section[0].status = true;
        this.blockName_errors.isNumberOfYearsStatic[0].status = true;
        this.blockName_errors.component[0].status = true;

      } else {
        this.blockName_errors.section[0].status = false;
        this.blockName_errors.isNumberOfYearsStatic[0].status= false;
        this.blockName_errors.component[0].status = false;

      }

    }
    this.setBtnDisable();

    console.log(this.blockName_errors);
  }



  checkForError(block, lovs) {
    console.log(this.blockName_errors);
    console.log(block);
    //  if (isDeveloperOptionEnabled && (block.putValueInFormDataKey === '' || (!(block.putValueInFormDataKey)))) {
    // this.hasError.putValueInFormDataKey = true;
    // this.errorInSectionComponents = true;
    // }
    // block.type !== "MatSlider"&&
    for (const language of this.offlineJsonForm.languages) {
      if ((block.type !== 'Table' && block.type !== 'TeamTable'
        &&  block.type !== 'Container' &&  block.type !== 'BudgetType1'
        && block.type !== 'BudgetExpenditureItems' && block.type !== 'BudgetType2'
        && block.type !== 'MatSlider' && block.type !== 'MultiYearTable'
        && block.type !== 'SchedulerTable' && block.type !== 'FundedTeams'  ) && (block.label[language.id] === '' || (!(block.label[language.id])))) {
        this.blockName_errors.label[0].message[language.id].status = true;
      } else {
        this.blockName_errors.label[0].message[language.id].status = false;
      }
      if (( block.type === 'MultiYearTable' || block.type === 'MultiYearTableT2'  ) && (block.yearLabel[language.id] === '' || (!(block.yearLabel[language.id])))) {
        console.log('mmmmmmmmmmmmmmmmmmmmmmmm');
        // console.log(block.section.name)
        console.log(block.isNumberOfYearsStatic);
        this.blockName_errors.yearLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.yearLabel[0].message[language.id].status = false;

      }
      // ==============================================

      // if (( block.type === "MultiYearTable" ) && ((block.isValueRanged ) && (block.section === "" || (!(block.section))) && (block.component === "" || (!(block.component)) )) &&
      //   ((block.selectSectionTo === "" || (!(block.selectSectionTo))) && (block.componentTo === "" || (!(block.componentTo))) )){
      //   // if ((block.section === "" || (!(block.section))) && (block.component === "" || (!(block.component)) ) &&
      //   //   (block.selectSectionTo === "" || (!(block.selectSectionTo))) && (block.componentTo === "" || (!(block.componentTo)) )) {
      //
      //     this.blockName_errors.sectionTo[0].status = true;
      //   }
      //   else{
      //     this.blockName_errors.sectionTo[0].status = false;
      //   }
      // // }

      if (( block.type === 'MultiYearTable' ) && ((block.isValueRanged ) && ((block.selectSectionTo === '' || (!(block.selectSectionTo))) && (block.componentTo === '' || (!(block.componentTo))) ))){

        this.blockName_errors.sectionTo[0].status = true;
      }
      else{
        this.blockName_errors.sectionTo[0].status = false;
      }


      if (( block.type === 'MultiYearTable' || block.type === 'MultiYearTableT2' || block.type === 'SchedulerTable'  ) &&
        (!block.isNumberOfYearsStatic && (block.section === '' || (!(block.section))) && (block.component === '' || (!(block.component)) ))) {

        this.blockName_errors.section[0].status = true;
        this.blockName_errors.isNumberOfYearsStatic[0].status = true;

      } else {
        this.blockName_errors.section[0].status = false;
        this.blockName_errors.isNumberOfYearsStatic[0].status= false;
        // this.blockName_errors.taskTitle[0].message[language.id].status = false;
        // this.blockName_errors.monthLabel[0].message[language.id].status = false;
        // this.blockName_errors.editLabel[0].message[language.id].status = false;
        // this.blockName_errors.indexColumnLabel[0].message[language.id].status = false;
        // this.blockName_errors.label[0].message[language.id].status = false;

      }
      // ==============sssss=====================================

      if ((   block.type === 'SchedulerTable' ) && (block.monthLabel[language.id] === '' || (!(block.monthLabel[language.id])))) {
        this.blockName_errors.monthLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.monthLabel[0].message[language.id].status = false;
      }
      if (( block.type === 'SchedulerTable') && (block.editLabel[language.id] === '' || (!(block.editLabel[language.id])))) {
        this.blockName_errors.editLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.editLabel[0].message[language.id].status = false;

      }
      if (( block.type === 'SchedulerTable') && (block.indexColumnLabel[language.id] === '' || (!(block.indexColumnLabel[language.id])))) {
        this.blockName_errors.indexColumnLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.indexColumnLabel[0].message[language.id].status = false;

      }
      if (( block.type === 'SchedulerTable') && (block.taskTitle[language.id] === '' || (!(block.taskTitle[language.id])))) {
        this.blockName_errors.taskTitle[0].message[language.id].status = true;

      } else {
        this.blockName_errors.taskTitle[0].message[language.id].status = false;

      }
      // ==================================================
      if (( block.type === 'MatSlider') && (block.startMonth[language.id] === '' || (!(block.startMonth[language.id])))) {
        this.blockName_errors.startMonth[0].message[language.id].status = true;

      } else {
        this.blockName_errors.startMonth[0].message[language.id].status = false;

      }

      if (( block.type === 'MatSlider') && (block.endMonth[language.id] === '' || (!(block.endMonth[language.id])))) {
        this.blockName_errors.endMonth[0].message[language.id].status = true;

      } else {
        this.blockName_errors.endMonth[0].message[language.id].status = false;

      }

      // ============================================
      if (( block.type === 'BudgetType1') && (block.categoryLabel[language.id] === '' || (!(block.categoryLabel[language.id])))) {
        this.blockName_errors.categoryLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.categoryLabel[0].message[language.id].status = false;

      }
      if (( block.type === 'BudgetType2') && (block.categoryLabel[language.id] === '' || (!(block.categoryLabel[language.id])))) {
        this.blockName_errors.categoryLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.categoryLabel[0].message[language.id].status = false;

      }
      if (( block.type === 'BudgetExpenditureItems') && (block.categoryLabel[language.id] === '' || (!(block.categoryLabel[language.id])))) {
        this.blockName_errors.categoryLabel[0].message[language.id].status = true;

      } else {
        this.blockName_errors.categoryLabel[0].message[language.id].status = false;

      }

      // ==============================================
      // if (( block.type === "MultiYearTable" || block.type === "MultiYearTableT2"  ) && (block.isNumberOfYearsStatic && (block.section !== "" ) )) {
      // ==============================================
      // if (( block.type === "SchedulerTable") && (block.editLabel[language.id] === "" || (!(block.editLabel[language.id])))) {
      //   this.blockName_errors.editLabel[0].message[language.id].status = true;
      //
      // } else {
      //   this.blockName_errors.yearLabel[0].message[language.id].status = false;
      //
      // }
      // if (( block.type === "SchedulerTable") && (block.indexColumnLabel[language.id] === "" || (!(block.indexColumnLabel[language.id])))) {
      //   this.blockName_errors.indexColumnLabel[0].message[language.id].status = true;
      //
      // } else {
      //   this.blockName_errors.indexColumnLabel[0].message[language.id].status = false;
      //
      // }
      // if (( block.type === "SchedulerTable") && (block.taskTitle[language.id] === "" || (!(block.taskTitle[language.id])))) {
      //   this.blockName_errors.taskTitle[0].message[language.id].status = true;
      //
      // } else {
      //   this.blockName_errors.taskTitle[0].message[language.id].status = false;
      //
      // }
      if ( (block.showtableLabel) && (block.tableLabel[language.id] === '' || (!(block.tableLabel[language.id])))) {
        this.blockName_errors.tableLabel[0].message[language.id].status = true;
      } else {
        this.blockName_errors.tableLabel[0].message[language.id].status = false;
      }

      if ( (block.hasShowNodata) && (block.showNodatatext[language.id] === '' || (!(block.showNodatatext[language.id])))) {
        this.blockName_errors.showNodata[0].message[language.id].status = true;
      } else {
        this.blockName_errors.showNodata[0].message[language.id].status = false;
      }
      if ( (block.hasTooltip) && (block.tooltipText[language.id] === '' || (!(block.tooltipText[language.id])))) {
        this.blockName_errors.toolTip[0].message[language.id].status = true;
      } else {
        this.blockName_errors.toolTip[0].message[language.id].status = false;
      }
      if ( (block.hasDialog) &&  (block.dialogText[language.id] === '' || (!(block.dialogText[language.id])))) {
        this.blockName_errors.dialog[0].message[language.id].status = true;
      } else {
        this.blockName_errors.dialog[0].message[language.id].status = false;
      }
    }

    if (lovs && block.type !== 'dynamicMatrix') {
      if (!block.isDataFromUrl) {
        if (block.lovSQL){
          this.blockName_errors.list[0].status = false;
        } else if(block.listName === '' || (!(block.listName))) {
          this.blockName_errors.list[0].status = true;
          for (const l of lovs) {
            if (block.listNameToShow === l.name[this.selectedLanguage]) {
              this.blockName_errors.list[0].status = false;
              break;
            }
            else {
              this.blockName_errors.list[0].status = true;
            }
          }
        }
      } else {
        if (block.url === '' || (!(block.url))) {
          this.blockName_errors.list[1].status = true;
        }
      }
    } else {
      this.blockName_errors.list[0].status = false;
      this.blockName_errors.list[1].status = false;
    }

    // Dynamic Matrix lovs
    if (block.type === 'dynamicMatrix') {
      let columnValid = false;
      let rowValid = false;

      for (const l of lovs) {
        if (block.listNameToShowForRowLov === l.name[this.selectedLanguage]) {
          // this.hasError.blockList = false;
          this.blockName_errors.dynamicMatrix[0].status = false;
          columnValid = true;
        } else if (block.listNameToShowForColumnLov === l.name[this.selectedLanguage]) {
          // this.hasError.blockList = false;
          this.blockName_errors.dynamicMatrix[1].status = false;
          rowValid = true;
        }
        if (!rowValid && !columnValid) {
          this.blockName_errors.dynamicMatrix[1].status = true;
          this.blockName_errors.dynamicMatrix[0].status = true;

        }
      }
    }
    else {
      this.blockName_errors.dynamicMatrix[1].status = false;
      this.blockName_errors.dynamicMatrix[0].status = false;
    }


    // For the submit button URL
    if (block.type === 'SubmitButton' && (block.url === '' || (!(block.url)))) {
      this.blockName_errors.urlForSubmitButton[0].status = true;
    }
    else {
      this.blockName_errors.urlForSubmitButton[0].status = false;
    }

    if (block.type === 'upload') {
      this.isEmptyInDocumentType(block);

      if (block.typesAdmin === '' || (!(block.typesAdmin))) {
        this.blockName_errors.formatTypeForUpload[0].status = true;
      } else {
        this.blockName_errors.formatTypeForUpload[0].status = false;

      }
    }
    else {
      this.blockName_errors.formatTypeForUpload[0].status = false;
      /*  this.blockName_errors.documentTypeForUpload[0].status = false;
        this.blockName_errors.documentTypeForUpload[1].status = false;*/

    }

    this.setBtnDisable();

    console.log(this.blockName_errors);
    if(block.type === 'MultiYearTable' || block.type === 'SchedulerTable' ){
      if (block.component) {
        delete block.component;
      }
      if (block.section) {
        delete block.section;
      }

    }
  }


  // ALL API CALLS

  getAdminComponents() {
    return this.eCaseHttpService.get('/api/allBlocksNg4').pipe(map(res => res));

  }


  getFormSectionAndComponents() {
    return this.eCaseHttpService.get('/api/getConfig').pipe(map(res => res));
  }

  getFormsForNewProject() {
    return this.eCaseHttpService.get('/api/getFormsForNewProject').pipe(map(res => res));
  }


  getAvailableForms() {
    return this.eCaseHttpService.get('/api/getAllForms');
  }

  getSelectedFormJSON(form) {
    return this.httpClient.post('/api/getFormJSON', { 'selectedForm': parseInt(form, 10) });
  }

  getFormJSONAndActions(form, isFormForConsent?) {
    return this.eCaseHttpService.post('/api/getFormJSONAndActions', { 'selectedForm': parseInt(form, 10) , 'isFormForConsent' : isFormForConsent });
  }

  getActionsObjectFromJson(form) {
    return this.eCaseHttpService.post('/api/getActionsObjectFromJson', form);
  }

  getUpdatedLovs(form) {
    return this.eCaseHttpService.post('/api/getUpdatedLovsFromFormJson', form);
  }

  getFormJSONByName(formName) {
    return this.eCaseHttpService.get<Form>('/api/getForm/' + formName);
  }

  publishForm(formId) {
    return this.eCaseHttpService.post('/api/publishForm', { 'formId': parseInt(formId, 10) }).pipe(timeout(600000));
  }
  deleteForm(formId) {
    return this.eCaseHttpService.post('/api/deleteForm', {'formId': formId});
  }

  getFormVersions(formId) {
    return this.eCaseHttpService.post('/api/getFormVersions', {'formId': formId});
  }

  createDiffBetweenSpecificFormVersions(obj) {
    return this.eCaseHttpService.post('/api/createDiffBetweenSpecificFormVersions',
      obj);
  }

  revertToSpecificFormVersion(obj) {
    return this.eCaseHttpService.post('/api/revertToSpecificFormVersion',
      obj);
  }

}






