import {AfterContentInit, Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ECaseUtils} from '../eCaseUtils';
import {ParametersService} from '../services/parameters.service';

/**
 * Created by sahilb@evision.ca on 2020-03-02.
 */
@Directive({
  selector: '[eCaseNgSticky]'
})
export class ECaseNgStickyDirective implements OnInit, OnDestroy, AfterContentInit {

  originalWidth: number;
  originalTop: number;
  isProd: boolean;
  sticked: boolean;
  lastScroll: number;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private parameterService: ParametersService) {
  }

  scroll = (event): void => {
    if (this.isScrolledIntoView(event)) {
      this.sticked = false;
      this.unstickElement();
    } else {
      this.sticked = true;
      this.stickElement();
    }
  };

  resize = (event): void => {
    this.ngAfterContentInit();
  };

  unstickElement(): void {
    this.renderer.removeStyle(this.elementRef.nativeElement, 'top');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'position');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'width');
    this.renderer.removeStyle(this.elementRef.nativeElement, 'z-index');
    // this.originalTop = ECaseUtils.eCaseCloneDeep(this.elementRef.nativeElement.getBoundingClientRect().top);
  }

  stickElement(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'top', (this.isProd ? '0px' : '24px'));
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'fixed');
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', this.originalWidth + 'px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', '9000');
  }

  isScrolledIntoView(scrollEvent: any): boolean {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    const _window: any = window;
    this.lastScroll = scrollEvent.target.scrollTop;
    console.log(scrollEvent.target.scrollTop < this.originalTop);
    return (elemTop >= (this.isProd ? 0 : 24)) && (scrollEvent.target.scrollTop < this.originalTop) && (elemBottom <= _window.innerHeight);
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
    window.addEventListener('resize', this.resize, true);
  }

  ngAfterContentInit(): void {
    this.isProd = this.parameterService.parameter['/core/environment'] === 'PROD';
    if (this.sticked) {
      this.unstickElement();
      this.originalWidth = ECaseUtils.eCaseCloneDeep(this.elementRef.nativeElement.getBoundingClientRect().width);
      this.originalTop = ECaseUtils.eCaseCloneDeep(this.elementRef.nativeElement.getBoundingClientRect().top) + this.lastScroll;
      this.stickElement();
    } else {
      this.originalWidth = ECaseUtils.eCaseCloneDeep(this.elementRef.nativeElement.getBoundingClientRect().width);
      this.originalTop = ECaseUtils.eCaseCloneDeep(this.elementRef.nativeElement.getBoundingClientRect().top);
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
    window.removeEventListener('resize', this.resize, true);
  }


}
