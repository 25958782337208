import {MatChipInputEvent} from '@angular/material/chips';
import {ECaseUtils} from '../eCaseUtils';

export class Project {
  projectNumber: string;
  id: string;
  email: string;
  password: string;
  projectName: string;
  projectTeamLead: string;
  status: string;
  lastUpdated: string;
  handle: string;
  description: string;
  categories: string[];
  tags: string[];
  images: {
    default: boolean;
    id: string;
    url: string;
    type: string;
  }[];
  priceTaxExcl: number;
  priceTaxIncl: number;
  taxRate: number;
  comparedPrice: number;
  quantity: number;
  sku: string;
  width: string;
  height: string;
  depth: string;
  weight: string;
  extraShippingFee: number;
  active: boolean;

  constructor(project?) {
    project = project || {};
    this.projectNumber = project.projectNumber || ECaseUtils.generateGUID();
    this.projectName = project.projectName || '';
    this.email = project.email || '';
    this.password = project.password || '';
    this.id = project.id || '';
    this.status = project.status || '';
    this.lastUpdated = project.lastUpdated || '';
    this.projectTeamLead = project.projectTeamLead || '';
    this.handle = project.handle || ECaseUtils.handleize(this.projectName);
    this.description = project.description || '';
    this.categories = project.categories || [];
    this.tags = project.tags || [];
    this.images = project.images || [];
    this.priceTaxExcl = project.priceTaxExcl || 0;
    this.priceTaxIncl = project.priceTaxIncl || 0;
    this.taxRate = project.taxRate || 0;
    this.comparedPrice = project.comparedPrice || 0;
    this.quantity = project.quantity || 0;
    this.sku = project.sku || 0;
    this.width = project.width || 0;
    this.height = project.height || 0;
    this.depth = project.depth || 0;
    this.weight = project.weight || 0;
    this.extraShippingFee = project.extraShippingFee || 0;
    this.active = project.active || true;
  }

  addCategory(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add name
    if (value) {
      this.categories.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCategory(category) {
    const index = this.categories.indexOf(category);

    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add tag
    if (value) {
      this.tags.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag) {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
}
