import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {ECaseUtils} from '../eCaseUtils';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarComponent} from '../components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class ECaseSnackBarService {
  config;

  constructor(public snackBar: MatSnackBar, private translate: TranslateService) {
  }


  // Service used to call the snackbar with status dependent styling
  // See the usage example in SaveJsonService class
  // message and duration(time in milliseconds for which snack bar is shown) are optional
  show(status: string, message?: string, duration?: number): void {
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'center';
    this.config.duration = duration ? duration : 7000;
    const otherConfigs: any = this.setConfigs(status, message);

    this.snackBar.open(otherConfigs.key, otherConfigs.closeString, this.config);
  }

  showComponent(status: string, message?: string, duration?: number): void {
    this.config = new MatSnackBarConfig();
    const otherConfigs: any = this.setConfigs(status, message);
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'center';
    this.config.duration = duration ? duration : 7000;
    this.config.data = otherConfigs.key;
    this.config.closeString = otherConfigs.closeString;
    this.snackBar.openFromComponent(SnackBarComponent, this.config);
  }

  setConfigs(status: string, message?: string): any {
    const objReturn: any = {};
    objReturn.closeString = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.close');

    if (status.toLowerCase() === 'success') {
      this.config.panelClass = 'ecase-snack-bar-success';
      objReturn.key = message ? ECaseUtils.getTranslatedValueFromKey(this.translate, message) : ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.saved');
    } else if (status.toLowerCase() === 'failure' || status.toLowerCase() === 'bad request') {
      this.config.panelClass = 'ecase-snack-bar-failure';
      objReturn.key = message ? ECaseUtils.getTranslatedValueFromKey(this.translate, message) : ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.error');
    } else {
      this.config.panelClass = 'ecase-snack-bar-warning';
      objReturn.key = message ? ECaseUtils.getTranslatedValueFromKey(this.translate, message) : ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.warning');
    }
    if (!(objReturn.closeString === 'Close' || objReturn.closeString === 'Fermer')) {
      objReturn.closeString = 'Close';
    }
    return objReturn;
  }

}
