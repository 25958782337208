import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CrmPersonSearchService {

  selectedUsers = [];
  dataSourceCrmPerson = [];
  isUserSearch = false;
  private updateShowUserSearch = new Subject<any>();

  // These variables are for perserving search data
  preserveDataSource: any = false;
  preserveFormData: any = false;
  preserveFilterObject: any = false;

  constructor(private eCaseHttpService: EcaseHttpService) {

  }

  get onShowUserSearch(): Observable<any> {
    return this.updateShowUserSearch.asObservable();
  }
  changeShowUserSearchFlag(event): void{
    this.isUserSearch = event;
    this.updateShowUserSearch.next(event);
  }


  searchCrmPerson(form): Observable<any> {
    return this.eCaseHttpService.post('/api/searchForCrmPersonComm', form);
  }

  searchCrmPersonWithOrganizationPermissionRestricted(form): Observable<any> {
    return this.eCaseHttpService.post('/api/searchForCrmPersonRestricted', form);
  }
}
