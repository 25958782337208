import {Component, OnDestroy, OnInit} from '@angular/core';
import {DefaultLangChangeEvent, TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Download, EcaseHttpService} from "../../services/ecase-http.service";
import {ECaseNumberFormatterPipe} from "../../pipes/eCaseNumberFormatter.pipe";

/**
 * Created by sahilb@evision.ca on 2022-12-04.
 */
@Component({
  selector: 'ecase-downloader',
  templateUrl: './ecase-downloader.component.html',
  styleUrls: ['./ecase-downloader.component.scss']
})
export class EcaseDownloaderComponent implements OnInit, OnDestroy {

  selectedLanguage = '';
  noOfFilesDownloading: any[] = [];
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private translateService: TranslateService, private eCaseHttpService: EcaseHttpService,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.getDefaultLang();
    this.noOfFilesDownloading = [];
    this.translateService.onDefaultLangChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params: DefaultLangChangeEvent) => {
        this.selectedLanguage = params.lang;
      });
    this.eCaseHttpService.onDownloadEvent
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((download: Download) => {
        let index = this.noOfFilesDownloading.findIndex(e => e.fileId === download.fileId);
        if (download.state === 'IN_PROGRESS' || download.state === 'PENDING') {
          if (this.noOfFilesDownloading.some(e => e.fileId === download.fileId)) {
            this.noOfFilesDownloading[index].progress = download.progress;
            this.noOfFilesDownloading[index].showDownloader = true;
            this.noOfFilesDownloading[index].fileName = download.fileName;
            this.noOfFilesDownloading[index].downloadSpeed = download.downloadSpeed;
            this.noOfFilesDownloading[index].remainingTimeInSeconds = download.remainingTimeInSeconds;
            this.noOfFilesDownloading[index].state = download.state;
          } else {
            this.noOfFilesDownloading.push({
              fileId: download.fileId,
              progress: download.progress,
              fileName: download.fileName,
              showDownloader: true,
              isDownloading: true,
            });
          }
        } else if (download.state === 'ERROR') {
          index = this.noOfFilesDownloading.findIndex(e => e.fileId === download.fileId);
          if (index >= 0) {
            this.noOfFilesDownloading[index].state = download.state;
          } else {
            this.noOfFilesDownloading = this.noOfFilesDownloading.map(downloadingFile => {
              downloadingFile.state = download.state;
              return downloadingFile;
            });
          }
          setTimeout(() => {
            index = this.noOfFilesDownloading.findIndex(e => e.fileId === download.fileId);
            if (index >= 0) {
              this.noOfFilesDownloading[index].showDownloader = false;
              this.noOfFilesDownloading.splice(index, 1);
            } else {
              this.noOfFilesDownloading = []
            }
          }, 2000);
        } else if (download.state === 'DONE') {
          this.noOfFilesDownloading[index].progress = download.progress;
          this.noOfFilesDownloading[index].fileName = download.fileName;
          this.noOfFilesDownloading[index].downloadSpeed = download.downloadSpeed;
          this.noOfFilesDownloading[index].remainingTimeInSeconds = download.remainingTimeInSeconds;
          this.noOfFilesDownloading[index].state = download.state;
          setTimeout(() => {
            index = this.noOfFilesDownloading.findIndex(e => e.fileId === download.fileId);
            this.noOfFilesDownloading[index].showDownloader = false;
            this.noOfFilesDownloading.splice(index, 1);
          }, 2000);
        }
        if (this.noOfFilesDownloading[index]) {
          this.noOfFilesDownloading[index].loadedFileSize = this.eCaseNumberFormatterPipe.fileSizeTransform(download.loadedFileSize, this.selectedLanguage);
          this.noOfFilesDownloading[index].totalFileSize = this.eCaseNumberFormatterPipe.fileSizeTransform(download.total, this.selectedLanguage);
        }
      });
  }

  getMatIconFromState(state: string): string {
    switch (state) {
      case 'PENDING' :
        return 'downloading';
      case 'IN_PROGRESS' :
        return 'downloading';
      case 'DONE' :
        return 'file_download_done';
      case 'ERROR' :
        return 'error';
      default:
        return 'downloading'
    }
  }

  cancelDownload(fileId: number): void {
    const subjectIndex = this.eCaseHttpService.cancelDownloadSubjects.findIndex(e => e.fileId === fileId);
    const subject = this.eCaseHttpService.cancelDownloadSubjects[subjectIndex].subject;
    subject.next('');
    subject.complete();
    this.eCaseHttpService.cancelDownloadSubjects.splice(subjectIndex, 1);
    const index = this.noOfFilesDownloading.findIndex(e => e.fileId === fileId);
    this.noOfFilesDownloading[index].state = 'CANCELLED';
    setTimeout(() => {
      this.noOfFilesDownloading[index].showDownloader = false;
      this.noOfFilesDownloading.splice(index, 1);
    }, 1000);
  }

  formatDownloadSpeed(speed: number): string {
    return this.eCaseNumberFormatterPipe.fileSizeTransform(speed, this.selectedLanguage) + '/sec';
  }

  formatDownloadTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - (hours * 3600)) / 60);
    let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
    seconds = seconds > 60 ? 60 : seconds;
    let result = ((hours < 10) ? "0" : "") + hours;
    result = result + ':' + ((minutes < 10) ? "0" : "") + minutes;
    result = result + ':' + ((seconds < 10) ? "0" : "") + seconds;
    return result;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
    this.noOfFilesDownloading = [];
  }

}
