import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {MatDialogRef} from '@angular/material/dialog';
import {ECaseTranslationLoaderService} from 'synto-common';

@Component({
  selector: 'app-cookies-policy-dialog',
  templateUrl: './cookies-policy-dialog.component.html',
  styleUrls: ['./cookies-policy-dialog.component.css']
})
export class CookiesPolicyDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CookiesPolicyDialogComponent>, public translate: TranslateService,
              private translationLoader: ECaseTranslationLoaderService,private cookieService: CookieService) { }

  ngOnInit(): void {
  }

  onAcceptClick(): void {
    this.cookieService.set('SYNTO_COOKIE_SUPPORT_ACCEPTED', 'true', 365, undefined, undefined, true, 'Lax');
    this.cookieService.set('SYNTO_COOKIE_SUPPORT_ACCEPTED_TIMESTAMP', new Date().getTime().toString(10), 365, undefined, undefined, true, 'Lax');
    this.dialogRef.close();
  }

}
