export  class GraphModel {


  id=0 ;
  propreiteName;

  constructor() {
    this.id=this.id+1;
    this.propreiteName = [
      { description: 'Doughnut'},
      { description: 'showXAxis'},
      {description: 'showYAxis'},
      { description: 'showLegend'},
      { description: 'showXAxisLabel'},
      {description: 'showYAxisLabel'},
      { description: 'timeline'},
    ];

  }


}
