import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VersionCheckService {
  // this will be replaced by the actual hashCode in post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(private http: HttpClient) {}

 // this will check the version of the frontend every 30 minutes
  public initVersionCheck(url, frequency = 1000 * 60 ) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

 // Check the version of the js
  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {
          const hash = response.version;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // if it is changed , it will hard reload
          if (hashChanged) {
            console.log('OUSSSSS');
             location.reload();
          }

          // We update the hash here so we don't have to make a version change another time

          this.currentHash = hash;
        },
        (err) => {
          console.error(err, 'ERROOR, I Couldnt get the hash Code');
        }
      );
  }

  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
