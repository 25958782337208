import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root'
})
export class TypeOfGraphService {
  private messageSource = new Subject<any>();

  constructor() { }

  setCustomeGraphs(type: string) {
    this.messageSource.next(type);
  }
  getCustomeGraphs(): Observable<any>{
    return this.messageSource.asObservable();
  }

}
