<div *ngFor="let downloadingFile of noOfFilesDownloading;let i = index">
  <div *ngIf="downloadingFile.showDownloader" class="float" [style.bottom.px]="(i === 0) ? 40 : ((170 * i) + 50)">
    <mat-card>
      <mat-card-content>
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between" style="align-items: center !important;">
            <div>
              <span
                *ngIf="downloadingFile.state === 'PENDING' || downloadingFile.state === 'IN_PROGRESS'">{{'ecase.common.downloading' | translate}}</span>
              <span *ngIf="downloadingFile.state === 'DONE'">{{'ecase.common.downloadcomplete' | translate}}</span>
              <span *ngIf="downloadingFile.state === 'ERROR'">{{'ecase.common.downloadfailed' | translate}}</span>
              <span
                *ngIf="downloadingFile.state === 'CANCELLED'">{{'ecase.common.downloadcancelled' | translate}}</span>
            </div>
            <div *ngIf="downloadingFile.fileId">
              <button (click)="cancelDownload(downloadingFile.fileId)" aria-label="Cancel file download"
                      class="mat-icon-button" type="button"
                      mat-icon-button>
                <mat-icon class="icon">cancel</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row" style="align-items: center">
            <mat-icon class="material-icons-outlined"
                      style="margin-right: 10px">{{getMatIconFromState(downloadingFile.state)}}</mat-icon>
            <mat-progress-bar *ngIf="downloadingFile.totalFileSize" color="accent" mode="determinate"
                              [value]="downloadingFile.progress"></mat-progress-bar>
            <mat-progress-bar *ngIf="!downloadingFile.totalFileSize" color="accent"
                              mode="indeterminate"></mat-progress-bar>
            <span *ngIf="downloadingFile.totalFileSize" style="margin-left: 10px">{{downloadingFile.progress}}%</span>
          </div>
          <div *ngIf="downloadingFile.fileName">
            <span>{{'ecase.common.downloadingFileName' | translate}}{{downloadingFile.fileName}}</span>
            <br>
          </div>
          <div *ngIf="downloadingFile.totalFileSize">
            <span>{{'ecase.common.downloadSpeed' | translate}}{{formatDownloadSpeed(downloadingFile.downloadSpeed)}}</span>
            <br>
            <span>{{'ecase.common.downloadTime' | translate}}{{formatDownloadTime(downloadingFile.remainingTimeInSeconds)}}</span>
            <br>
          </div>
          <span>{{'ecase.common.filesizedownloaded' | translate}}{{downloadingFile.loadedFileSize}}</span>
          <span *ngIf="downloadingFile.totalFileSize"> / {{downloadingFile.totalFileSize}}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

