import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {MAT_INPUT_VALUE_ACCESSOR} from '@angular/material/input';
import {ECaseNumberFormatterPipe} from '../pipes/eCaseNumberFormatter.pipe';
import {TranslateService} from '@ngx-translate/core';
import {ECaseUtils} from '../eCaseUtils';

/**
 * Created by sahilb@evision.ca on 2021-06-18.
 */
@Directive({
  selector: 'input[eCaseNumberField]',
  providers: [{provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: ECaseInputNumberDirective}]
})
export class ECaseInputNumberDirective implements AfterViewInit {

  private _value: string | null;
  private _isCurrency: boolean | null;
  private _isNegativeAllowed: boolean | null;
  private _decimalPlacesAllowed: number | null;
  private _lang: string;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', '.', '-',','];
  private isValueFormatted: boolean;
  private isElementFocused: boolean;

  constructor(private elementRef: ElementRef<HTMLInputElement>, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private translateService: TranslateService) {
  }

  @Input()
  set decimalPlacesAllowed(value: number | null) {
    if ((!value) && value !== 0) {
      value = 2;
    }
    this._decimalPlacesAllowed = value;
  }

  get decimalPlacesAllowed(): number | null {
    return this._decimalPlacesAllowed;
  }

  get value(): string | null {
    return this._value;
  }

  @Input()
  set value(value: string | null) {
    this._value = ECaseUtils.eCaseCloneDeep(value);
    if (!this.isElementFocused) {
      this.formatValue(value);
    }
  }

  @Input()
  set isNegativeAllowed(isNegativeAllowed: boolean | null) {
    this._isNegativeAllowed = (isNegativeAllowed === null || isNegativeAllowed === undefined) ? true : !!isNegativeAllowed;
    if (this._isNegativeAllowed && !this.specialKeys.includes('-')) {
      this.specialKeys.push('-');
    }
    if (!this._isNegativeAllowed && this.specialKeys.includes('-')) {
      this.specialKeys = this.specialKeys.filter(item => item !== '-');
    }
  }

  get isNegativeAllowed(): boolean {
    return this._isNegativeAllowed;
  }

  @Input()
  set isCurrency(isCurrency: boolean | null) {
    this._isCurrency = !!isCurrency;
  }

  get isCurrency(): boolean {
    return this._isCurrency;
  }

  get lang(): string {
    return this._lang;
  }

  @Input()
  set lang(lang: string | null) {
    this._lang = lang ? lang : this.translateService.getDefaultLang();
    if (this._value) {
      this.formatValue(this._value);
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value): void {
    this._value = this.eCaseNumberFormatterPipe.reverseFormatToNumber(value, this._isCurrency, this.lang).toString();
  }

  @HostListener('blur')
  _onBlur(): void {
    this.isElementFocused = false;
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus(): void {
    this.isElementFocused = true;
    this.unFormatValue();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this._value.includes('.') && event.key === '.') {
      event.preventDefault();
    }
    if (this._value.includes('-') && event.key === '-') {
      event.preventDefault();
    }
    if (this._value.includes(',') && event.key === ',') {
      event.preventDefault();
    }
    if (this.lang === 'fr' && this._value.includes(' ') && event.key === ' ') {
      event.preventDefault();
    }
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (isNaN(Number(event.key)) || (this.lang !== 'fr' && event.key === ' ')) {
      event.preventDefault();
    }
  }

  ngAfterViewInit(): void {
    this._lang = this.lang ? this.lang : this.translateService.getDefaultLang();
    if (!this.isNegativeAllowed) {
      this.specialKeys = this.specialKeys.filter(item => item !== '-');
    }
    if (this._value) {
      if (!this.isNegativeAllowed && Number(this._value) < 0) {
        this.value = '0';
      }
      this.formatValue(this._value);
    } else {
      this.formatValue('');
    }
  }

  private formatValue(value: string | null): void {
    if (value !== null) {
      const decimal = new Intl.NumberFormat(this._lang).format(1.1).replace(/1/g, '');
      value = (typeof value === 'string') ? value : value + '';
      value = value.replace(new RegExp('\\'+decimal, 'g'), '.').replace(" ","");
      this.elementRef.nativeElement.value = this.eCaseNumberFormatterPipe.transform(value, this._isCurrency, this.lang, false, undefined, this.decimalPlacesAllowed);



      this.isValueFormatted = true;
    } else {
      this.elementRef.nativeElement.value = '';
    }

  }

  private unFormatValue(): void {
    this._lang = this.lang ? this.lang : this.translateService.getDefaultLang();
    const value = this.elementRef.nativeElement.value;
    const result = this.eCaseNumberFormatterPipe.reverseFormatToNumber(value, this._isCurrency, this.lang, undefined, this.decimalPlacesAllowed);
    if(isNaN(result)){
      // if NaN just return an empty value, otherwise users are blocked and can no more edit the number.
      this._value = "";
    } else{
      this._value = result.toString();
    }



    if (value) {
      this.elementRef.nativeElement.value = this._value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
    const decimal = new Intl.NumberFormat(this._lang).format(1.1).replace(/1/g, '');
    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(new RegExp('\\.', 'g'), decimal);
    this.isValueFormatted = false;
  }


}
