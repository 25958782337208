import {NgModule} from '@angular/core';
import {ComponentModule} from "./core/components/component.module";
import {SharedModule} from "./core/modules/shared.module";
import {MaterialModule} from "./core/modules/material.module";
import {IconsModule} from "./core/icons/icons.module";


@NgModule({
  declarations: [],
  imports: [
    ComponentModule,
    SharedModule,
    MaterialModule,
    IconsModule
  ],
  exports: [ComponentModule,
    SharedModule,
    MaterialModule,
    IconsModule]
})
export class SyntoCommonModule { }
