import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, map} from 'rxjs/operators';
import {EcaseHttpService} from './ecase-http.service';
import {throwError} from 'rxjs/internal/observable/throwError';
import {ECaseSnackBarService} from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class PanelService implements CanActivate {
  static SELF_ASSESSMENT_STAGE_TYPE_ID: any = 1;
  static REVIEW_STAGE_TYPE_ID: any = 2;
  static REVIEW_MEETING_STAGE_TYPE_ID: any = 3;
  static DIRECTOR_REVIEW_STAGE_TYPE_ID: any = 8;
  static COMMITTEE_ROLE_EXTERNAL: any = 6;
  static REVIEW_ROLE_INTERNAL: any = 1;
  static REVIEW_ROLE_EXTERNAL: any = 5;

  public languages;
  selectedPanel: any;
  selectedRatingScale: any;

  private _panelTabSelectedSubject = new Subject<any>();

  constructor(private eCaseHttpService: EcaseHttpService, private snackBarService: ECaseSnackBarService) {
    this.fetchLanguages();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.selectedPanel) {
      return true;
    } else {
      return this.eCaseHttpService.get(`api/review/getPanel/${route.params['panelId']}`).pipe(map((panel: any) => {
        if (panel.status) {
          this.selectedPanel = panel.response[0];
        }
        return panel.status;
      }));
    }
  }

  get onPanelTabSelected() {
    return this._panelTabSelectedSubject.asObservable();
  }

  selectPanelTab(event) {
    this._panelTabSelectedSubject.next(event);
  }

  getPanels() {
    return this.eCaseHttpService.get('/api/getAllReviewCommittees');
  }

  addPanel(newPanel) {
    return this.eCaseHttpService.post('/api/addCommittee', newPanel);
  }

  getPanelRounds(panelId: number) {
    return this.eCaseHttpService.get(`/api/review/panel/getPanelRounds/${panelId}`);
  }

  getPanelMemberRoles() {
    return this.eCaseHttpService.get('/api/getActiveReviewCommitteeUserRoles');
  }

  savePanelMembers(users, committee) {
    return this.eCaseHttpService.post('/api/saveCommitteeUsersWithRoles', {
      'committeeId': committee,
      'users': users
    }).pipe(
      catchError(this.handleError)
    );
  }

  getPanelMembers(id: number) {
    return this.eCaseHttpService.post('/api/getCommitteeUsers', {'committeeId': id});
  }

  getAvailableUsers() {
    return this.eCaseHttpService.get('/api/getAvailableUsers');
  }

  getPanelApplications(committeeId) {
    return this.eCaseHttpService.post('/api/getPanelApplications', {
      'committeeId': committeeId
    });
  }

  deletePanelRound(panelRoundId) {
    return this.eCaseHttpService.post('/api/deletePanelRound', {'panelRoundId': panelRoundId});
  }

  updateCommittee(committee) {
    return this.eCaseHttpService.post('/api/updateCommittee', committee);
  }

  updateCommitteeStage(committeeStage) {
    return this.eCaseHttpService.post('/api/updateCommitteeStage', committeeStage);
  }

  deleteCommittee(committeeId) {
    return this.eCaseHttpService.post('/api/deleteCommittee', {'committeeId': committeeId});
  }

  getCommittees() {
    return this.eCaseHttpService.get('/api/getAllReviewCommittees');
  }

  getCommittee(committeeId) {
    return this.eCaseHttpService.post('/api/getReviewCommittee', {'committeeId': committeeId});
  }


  getPermissionCategories() {
    return this.eCaseHttpService.get('/api/getReviewCommitteePermissionsAndCategories');
  }

  getCommitteePermissionRoleMappings(committeeId: number) {
    return this.eCaseHttpService.post('/api/getCommitteePermissionRoleMappings', {id: committeeId});
  }

  getCommitteeMetadatas(committeeId: number) {
    return this.eCaseHttpService.post('/api/getCommitteeMetadatas', {id: committeeId});
  }

  updateCommitteeStageMetadatas(committeeStage) {
    return this.eCaseHttpService.post('/api/updateCommitteeStageMetadatas', committeeStage);
  }

  saveCommitteePermissionRoles(committeeId: number, mappings) {
    return this.eCaseHttpService.post(`/api/saveCommitteePermissionRoles/${committeeId}`, mappings);
  }

  getCommitteeApplications(committeeId) {
    return this.eCaseHttpService.post('/api/getCommitteeApplications', {
      'committeeId': committeeId
    });
  }

  deleteGridInCommittee(committeeId, rcgId) {
    return this.eCaseHttpService.post('/api/deleteGridInCommittee ', {
      'committeeId': committeeId,
      'rcgid': rcgId

    });
  }

  insertCriteriaIntoCommittee(committeeId, grids) {
    return this.eCaseHttpService.post('/api/insertCriteriaIntoCommittee', {
      'committeeId': committeeId,
      'grids': grids
    });
  }

  getAllGridsInCommittee(committeeId) {
    return this.eCaseHttpService.post('/api/getAllGridsInCommittee', {
      'committeeId': committeeId
    });
  }

  addApplicationsToCommittee(committeeId, applications) {
    return this.eCaseHttpService.post('/api/addRemoveApplicationsToCommittee', {
      'committeeId': committeeId,
      'applications': applications
    });
  }

  getInfoToInsertMember(committeeId, caseApplicationId, ssoUserId) {
    return this.eCaseHttpService.post('/api/getInfoToInsertMember', {
      'committeeId': committeeId,
      'caseApplicationId': caseApplicationId,
      'ssoUserId': ssoUserId
    });
  }

  insertMemberInApplication(rcsca, rcsu, rrrId, status?) {
    return this.eCaseHttpService.post('/api/insertMemberInApplication', {
      'rcsca': rcsca,
      'rcsu': rcsu,
      'rrrId': rrrId,
      'status': status
    });
  }

  deleteMemberFromApplication(rcsca, rcsu) {
    return this.eCaseHttpService.post('/api/deleteMemberFromApplication', {
      'rcsca': rcsca,
      'rcsu': rcsu
    });
  }

  getAllReviewRoles() {
    return this.eCaseHttpService.get('/api/getAllReviewRoles');
  }


  fetchLanguages() {
    this.eCaseHttpService.get('/api/getLanguages').subscribe((data) => {
      this.languages = data;
    });
  }

  getEligibilityApplications(applications) {
    return this.eCaseHttpService.post('/api/searchEligibleApplicationsForCommittee ', applications);

  }

  deleteReviewRatingScales(id) {
    return this.eCaseHttpService.post('/api/deleteReviewRatingScales', {id});
  }

  getReviewRatingScales() {
    return this.eCaseHttpService.get('/api/getReviewRatingScales');
  }

  getAllCommentTypes() {
    return this.eCaseHttpService.get('/api/getAllCommentTypes');
  }

  getAllCommentTypesForCommittee(committeeStageId) {
    return this.eCaseHttpService.post('/api/getAllCommentTypesForCommittee', {
      'committeeStageId': committeeStageId
    });
  }

  updateAndInsertCommentTypeOverride(data) {
    return this.eCaseHttpService.post('/api/updateAndInsertCommentTypeOverride', data);
  }


  getAllCommentTypesCategory() {
    return this.eCaseHttpService.get('/api/getAllCommentTypesCategory');
  }

  deleteCommentType(id) {
    return this.eCaseHttpService.post('/api/deleteCommentType', {id});
  }

  updateCommentType(data) {
    return this.eCaseHttpService.post('/api/updateCommentType', data);
  }

  addCommentType(data) {
    return this.eCaseHttpService.post('/api/addCommentType', data);
  }


  deleteReviewRatingScalesDetail(id) {
    return this.eCaseHttpService.post('/api/deleteReviewRatingScalesDetail', {id});
  }

  addRatingScaleDetails(data) {
    return this.eCaseHttpService.post('/api/addRatingScaleDetails', data);
  }

  updateReviewRatingScalesDetail(data) {
    return this.eCaseHttpService.post('/api/updateReviewRatingScalesDetail', data);
  }


  updateReviewRatingScales(data) {
    return this.eCaseHttpService.post('/api/updateReviewRatingScales', data);
  }

  addRatingScale(data) {
    return this.eCaseHttpService.post('/api/addRatingScale', data);
  }


  getReviewRatingScalesDetails(rsId) {
    return this.eCaseHttpService.post('/api/getReviewRatingScalesDetails', {'rsId': rsId});
  }

  updateReviewRatingScalesDetailsWeight(obj) {
    return this.eCaseHttpService.post('/api/updateRatingScaleDetailWeight', obj);
  }

  updateCommentTypeWeight(obj) {
    return this.eCaseHttpService.post('/api/updateCommentTypeWeight', obj);
  }

  getReviewGrids() {
    return this.eCaseHttpService.get('/api/getReviewGrids');
  }

  addReviewCriteriaGrid(data) {
    console.log(data);
    return this.eCaseHttpService.post('/api/addReviewCriteriaGrid', data);
  }

  updateReviewCriteriaGrid(data) {
    return this.eCaseHttpService.post('/api/updateReviewCriteriaGrid', data);
  }

  deleteReviewCriteriaGrid(data) {
    return this.eCaseHttpService.post('/api/deleteReviewCriteriaGrid', {id: data});
  }

  addReviewCriteria(data) {
    return this.eCaseHttpService.post('/api/addReviewCriteria', data);
  }

  updateReviewCriteria(data) {
    return this.eCaseHttpService.post('/api/updateReviewCriteria', data);
  }

  deleteReviewCriteria(data) {
    return this.eCaseHttpService.post('/api/deleteReviewCriteria', {id: data});
  }


  getAllNominationStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewNominationStrategies');
  }

  getAllStatisticMethods() {
    return this.eCaseHttpService.get('/api/getAllReviewNominationStatistics');
  }

  getAllRatingScalesCalculationMethods() {
    return this.eCaseHttpService.get('/api/getAllReviewRatingScaleCalculationMethods');
  }

  getReviewerPortalPreview(entityType: string, entityId: number, langCode: string) {
    return this.eCaseHttpService.get(`/api/getReviewerPortalPreview/${entityType}/${entityId}/${langCode}`, {responseType: 'text'});
  }

  getReviewerPortalPDF(entityType: string, entityId: number, langCode: string) {
    return this.eCaseHttpService.get(`/api/getReviewerPortalPDF/${entityType}/${entityId}/${langCode}`, {responseType: 'text'});
  }

  handleError(error) {
    let errorMessage = '';
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(errorMessage);
  }

  getCommitteeDashboard(committeeId: number) {
    return this.eCaseHttpService.get('/api/getReviewCommitteeDashboard/' + committeeId);
  }

  getCommitteeMonitoringScreen(monitoringScreen: string, committeeId: number) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/${monitoringScreen}/${committeeId}`);
  }

  getCommitteeMonitoringApplicationDetails(committeeId: number, applicationId: number) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/perApplicationStatus/applicationDetails/${committeeId}/${applicationId}`);
  }

  getCommitteeMonitoringMeetingDetails(committeeId: number, applicationId: number) {
    return this.eCaseHttpService.get(`/api/getReviewCommitteeMonitoringScreen/reviewMeetingStatus/meetingDetails/${committeeId}/${applicationId}`);
  }

  saveCommitteeStageUserRoleMetadatas(committeeStageId: number, metadataKey: string, userRolesCustomizations: any) {
    return this.eCaseHttpService.post(`/api/saveCommitteeStageUserRoleMetadatas/${committeeStageId}/${metadataKey}`, userRolesCustomizations);
  }

  saveCommitteeStageUserMetadatas(ssoUserId: string, memberCustomizations: any) {
    return this.eCaseHttpService.post(`/api/saveCommitteeStageUserMetadatas/${ssoUserId}`, memberCustomizations);
  }
}

