import {Directive, HostBinding, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MediaObserver} from '@angular/flex-layout';
import {ECaseMatchMedia} from '../../services/match-media.service';
import {ECaseMatSidenavHelperService} from './eCase-mat-sidenav-helper.service';
import {Subject} from "rxjs/internal/Subject";
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[eCaseMatSidenavHelper]'
})
export class ECaseMatSidenavHelperDirective implements OnInit, OnDestroy {
  @HostBinding('class.mat-is-locked-open')
  isLockedOpen: boolean;

  @Input()
  eCaseMatSidenavHelper: string;

  @Input()
  matIsLockedOpen: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _eCaseMatchMediaService
   * @param _eCaseMatSidenavHelperService
   * @param _matSidenav
   * @param _mediaObserver
   */
  constructor(
    private _eCaseMatchMediaService: ECaseMatchMedia,
    private _eCaseMatSidenavHelperService: ECaseMatSidenavHelperService,
    private _matSidenav: MatSidenav,
    private _mediaObserver: MediaObserver
  ) {
    // Set the defaults
    this.isLockedOpen = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // ---------------------------------------------- ---------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Register the sidenav to the service
    this._eCaseMatSidenavHelperService.setSidenav(this.eCaseMatSidenavHelper, this._matSidenav);

    if (this._mediaObserver.isActive(this.matIsLockedOpen)) {
      this.isLockedOpen = true;
      this._matSidenav.mode = 'side';
      this._matSidenav.toggle(true);
    }
    else {
      this.isLockedOpen = false;
      this._matSidenav.mode = 'over';
      this._matSidenav.toggle(false);
    }

    this._eCaseMatchMediaService.onMediaChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this._mediaObserver.isActive(this.matIsLockedOpen)) {
          this.isLockedOpen = true;
          this._matSidenav.mode = 'side';
          this._matSidenav.toggle(true);
        }
        else {
          this.isLockedOpen = false;
          this._matSidenav.mode = 'over';
          this._matSidenav.toggle(false);
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }
}

@Directive({
  selector: '[eCaseMatSidenavToggler]'
})
export class ECaseMatSidenavTogglerDirective {
  @Input()
  eCaseMatSidenavToggler: string;

  /**
   * Constructor
   *
   * @param _eCaseMatSidenavHelperService
   */
  constructor(
    private _eCaseMatSidenavHelperService: ECaseMatSidenavHelperService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On click
   */
  @HostListener('click')
  onClick(): void {
    this._eCaseMatSidenavHelperService.getSidenav(this.eCaseMatSidenavToggler).toggle();
  }
}
