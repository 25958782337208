<mat-dialog-content>
  <div *ngIf="originalDocuments.length > 0" class="responsive-table-style">
    <h1>{{'ecase.common.documentation'| translate }}</h1>
    <h3>{{'ecase.common.listofdocuments'| translate }}</h3>
    <table (matSortChange)="sortColumn($event)" [@animateStagger]="{value:'50'}"
           [dataSource]="dataSource"
           class="ecase-mat-table resutTextStyle"
           mat-table
           matSort
           matSortActive="{{sortActive}}"
           matSortDirection="{{sortDirection}}">

      <ng-container matColumnDef="documentType">
        <th *matHeaderCellDef mat-header-cell mat-sort-header><u style="padding-left: 30px;">{{
          'ecase.common.documentType' | eCaseTranslate }}</u></th>
        <td *matCellDef="let row" [title]="'ecase.common.documentType' | eCaseTranslate" mat-cell>
          <span>{{row.documentType[selectedLang]}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header><u>{{
          'ecase.common.documentname' | eCaseTranslate }}</u></th>
        <td *matCellDef="let row" [title]="'ecase.common.documentname' | eCaseTranslate" class="tdStyle"
            mat-cell>
          <a (click)="downloadDocument(row)">
            <span>{{row.name}}</span>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell mat-sort-header><u>{{
          'ecase.common.description' | eCaseTranslate }}</u></th>
        <td *matCellDef="let row" [title]="'ecase.common.description' | eCaseTranslate" class="tdStyle"
            mat-cell>
          {{row.description[selectedLang]}}
        </td>
      </ng-container>

      <!-- Header row first group -->
      <ng-container class="filter_row_first_cell mat-elevation-z4" matColumnDef="header-row-first-group" matRipple>
        <th *matHeaderCellDef class="mat-table_filtered_header_cell"
            mat-header-cell><span class='nodisplay'>.</span>
          <input *ngIf="showImage" alt="clear" class="row_filter_icon"
                 disabled matRipple
                 src="../../../../assets/images/etc/icons8-conversion.svg" type="image">
          <input (click)="resetFilters(true)" *ngIf="!showImage" alt="clear"
                 class="row_filter_icon" src="../../../../assets/images/etc/icons8-clear-filters.svg" type="image">
          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.documentType"
                 [attr.aria-label]="'ecase.common.documentType' | eCaseTranslate"
                 [placeholder]="'ecase.common.search' | eCaseTranslate"
                 class="searchFields"
                 type="text">
        </th>
      </ng-container>

      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-second-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
          <span class='nodisplay'>.</span>
          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.name"
                 [attr.aria-label]="'ecase.common.documentname' | eCaseTranslate"
                 [placeholder]="'ecase.common.search' | eCaseTranslate"
                 class="searchFields"
                 type="text">
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-third-group">
        <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell><span class='nodisplay'>.</span>
          <input (keyup)="applyFilter()" [(ngModel)]="filterObject.description"
                 [attr.aria-label]="'ecase.common.description' | eCaseTranslate"
                 [placeholder]="'ecase.common.search' | eCaseTranslate"
                 class="searchFields"
                 type="text">
        </th>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"
          mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
    <!--  it was in the mat-paginator    -->
    <mat-paginator *ngIf="originalArray.length > 0"
                   [length]=originalArray.length
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="pageSize"
                   showFirstLastButtons></mat-paginator>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button (click)="onCloseDialog()" class="eCase-dialog-negative-action-button"
          mat-raised-button>{{ 'close' | translate }}</button>
</mat-dialog-actions>



