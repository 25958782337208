import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class PeerReviewService {

  constructor(private eCaseHttpService: EcaseHttpService) { }


  getAllScoringStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewScoringStrategies');
  }

  getAllCommentingStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewCommentingStrategies');
  }

  getAllReportAccessStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewReportAccessStrategies');
  }
}
