<div style="margin-top: 50px; margin-bottom: 30px; display: flex; justify-content: center">
  <img width="128px" style="height: 128px !important;" [src]="'../../../../assets/images/logos/eVisionOLogo-light.png'"
       class="logo-spinner"
       alt="Product logo">

  <div style="overflow: hidden!important; position: absolute; z-index: 5; margin-top: 12px;" >
    <mat-spinner [color]="'accent'" mode="indeterminate" [strokeWidth]="5"></mat-spinner>
  </div>
</div>




