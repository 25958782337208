import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartsSettingsService implements OnInit {
  public user: any;
  id;
  public typeOfGraph;
  propreites;
  slectedData;
  isDoughnut: boolean;
  data = {
    prop : {},
    myPropData : {},
    type : {}
  };
  private userLoggedIn: boolean;
  private showData: any[];

  ngOnInit(): void {
    this.data = {
      prop: this.propreites,
      myPropData: this.slectedData,
      type: this.typeOfGraph
    };
  }


}
