import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ECaseConfigService, EcaseHttpService, ECaseNavigationModel, ECaseNavigationService, ECaseSnackBarService,
  ECaseSplashScreenService, ECaseTranslationLoaderService, LanguageService,
  LoginAuthenticationService,
  RefreshContentTabsService
} from 'synto-common';

@Component({
  selector: 'app-shibboleth-login',
  templateUrl: './shibboleth-login.component.html',
  styleUrls: ['./shibboleth-login.component.css']
})
export class ShibbolethLoginComponent implements OnInit {

  constructor(private eCaseConfig: ECaseConfigService, private formBuilder: FormBuilder, public translate: TranslateService,
              private translationLoader: ECaseTranslationLoaderService, private refreshContentTabsService: RefreshContentTabsService,
              private route: ActivatedRoute, private eCaseSplashScreenService: ECaseSplashScreenService, private eCaseHttpService: EcaseHttpService,
              private languageService: LanguageService, private loginAuthenticationService: LoginAuthenticationService, private eCaseSnackBarService: ECaseSnackBarService,
              public router: Router, private eCaseNavigationService: ECaseNavigationService) {
  }

  ngOnInit() {
    this.authenticateUsingShibboleth();
  }

  authenticateUsingShibboleth() {
    this.eCaseHttpService.get('/api/getUserInfoForShibboleth').subscribe((response: any) => {
      if (response.status) {
        this.loginAuthenticationService.isUserLoggedIn = true;
        this.loginAuthenticationService.userProfile = response.userProfile;
        this.loginAuthenticationService.primeRole = response.primeRole;
        this.loginAuthenticationService.dummyRole = response.primeRole;
        this.loginAuthenticationService.roles = response.roles;
        this.loginAuthenticationService.featureIdList = response.featureIdList;
        this.loginAuthenticationService.sysActionIdList = response.sysActionIdList;
        this.refreshContentTabsService.getContentTabs(this.loginAuthenticationService.roles, response.primeRole).subscribe((tabData) => {
          this.loginAuthenticationService.tabs = tabData.tabs;
          this.eCaseNavigationService.sideNavTabs = tabData.tabs;
          this.eCaseNavigationService.setNavigationModel(new ECaseNavigationModel(this.loginAuthenticationService.addDashBoards(this.loginAuthenticationService.tabs.filter(tab => tab.roleId === this.loginAuthenticationService.primeRole)[0])));
          this.translate.use('en');
          this.translate.setDefaultLang('en');
          this.translationLoader.loadTranslations(27);
          this.router.navigate([this.loginAuthenticationService.tabs.filter(tab => tab.roleId === this.loginAuthenticationService.primeRole)[0].url]);
        });
      } else {
        this.router.navigate(['/']);
      }
    });
  }

}
