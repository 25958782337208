import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DeleteChartService {
  private messageSource = new Subject<number>();
  constructor() { }

  setDeletePriority(deleteChart: number) {

  //
    this.messageSource.next(deleteChart);
  }
  getDeletePriority(): Observable<any>{
    return this.messageSource.asObservable();
  }

}
