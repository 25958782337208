import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'ecase-general-dialog',
  templateUrl: 'ecase-general-dialog.component.html'
})
export class GeneralDialogComponent implements OnInit {
  @Input() errors;
  @Input() lang;

  constructor(public dialogRef: MatDialogRef<GeneralDialogComponent>) {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
  }
}
