import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreateNewFormService {

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  createNewForm(form: any): any {
    return this.eCaseHttpService.post('/api/createNewForm', form);
  }

  updateExistingFormDetails(form: any): any {
    return this.eCaseHttpService.post('/api/updateExistingFormDetails', form);
  }
}
