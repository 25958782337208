<div id="main-navigation" class="nav" role="navigation" fxLayout="{{layout === 'vertical' ? 'column' : 'row'}}" fxLayoutAlign="space-between"
     [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">
  <div id="navigation-content">
    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">
      <ng-container *ngFor="let item of navigationModel">
        <eCase-nav-vertical-group [id]="'ecase.navigation.vertical.group.' + item.id" *ngIf="item?.itemType==='group'" [item]="item"></eCase-nav-vertical-group>
        <eCase-nav-vertical-collapse [id]="'ecase.navigation.vertical.collapse.' + item.id" *ngIf="item?.itemType==='collapse'" [item]="item"></eCase-nav-vertical-collapse>
        <eCase-nav-vertical-item [id]="'ecase.navigation.vertical.item.' + item.id" *ngIf="item?.itemType==='item'" [item]="item"></eCase-nav-vertical-item>
      </ng-container>
    </ng-container>
    <!-- / Vertical Navigation Layout -->
    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
      <ng-container *ngFor="let item of navigationModel">
        <eCase-nav-horizontal-collapse [id]="'ecase.navigation.horizontal.group.' + item.id" *ngIf="item?.itemType==='collapse'" [item]="item"></eCase-nav-horizontal-collapse>
        <eCase-nav-horizontal-item [id]="'ecase.navigation.horizontal.group.' + item.id" *ngIf="item?.itemType==='item'" [item]="item"></eCase-nav-horizontal-item>
      </ng-container>
    </ng-container>
    <!-- / Horizontal Navigation Layout -->
  </div>
  <div class="footer">
    <span class="footerText">{{ 'ecase.common.poweredbysynto' | translate }}</span>
    <span class="footerLogo">
      <img style="height:100%;filter: drop-shadow(0px 0px 1px rgb(0, 0, 0));" [src]="'../../../../assets/images/logos/syntoLogo.svg'" alt="eVision Synto Logo">
    </span>
    <ng-container >
      <button (click)= "openContactUsDialog()" aria-label="Toggle quick panel" class="mat-icon-button quick-panel-toggle-button"
              matTooltip="{{'ecase.common.contactUs'| translate}}"
              mat-icon-button>
        <mat-icon class="icon">live_help</mat-icon>
      </button>
    </ng-container>
  </div>
</div>
