<ng-container *ngIf="!(item.id === 66 || item.id === 67)">
<a [id]="'ecase.navigation.vertical.item.link.' + item.id" class="nav-link" *ngIf="item.url" [routerLink]="[item.url]" routerLinkActive="active" (keypress)="onKeyPress($event, item ,false)"
   [routerLinkActiveOptions]="{exact: item.exactMatch || false}"  matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title">{{item.title | translate}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</a>
</ng-container>
<ng-container *ngIf="(item.id === 66 || item.id === 67)">
<a [id]="'ecase.navigation.vertical.item.link.' + item.id" class="nav-link" (click)="loseFocus(item)" (focus)="loseFocus(item)" (mouseenter)="onMouseEnter(item)"
   *ngIf="item.url" [href]="parameters[item.url.split(' ')[0]] ? (parameters[item.url.split(' ')[0]] + getURL(item.url.split(' ')[1])) : getURL(item.url)"
   target="_blank" (keypress)="onKeyPress($event, item , true)" matRipple>
  <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
  <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
  <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
        [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</a>
</ng-container>
<span class="nav-link" *ngIf="item.function" (click)="item.function()" matRipple>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
</span>
