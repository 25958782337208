import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class LovDataService {
  isNavigatingToChildLovComponent: boolean;
  isNavigatingToChildTermComponent: boolean;
  lovArray = [];
  newLov = [];
  newterm = [];
  termsArray = [];
  array = [];
  lovScreenScrollTopSubject = new Subject();
  taxonomyDetailsScreenScrollTopSubject = new Subject();
  selectedTaxonomyId;
  selectedTaxonomyParentId;
  triggerSubject: Subject<any> = new Subject<any>();

  lovFullList = [];

  constructor(private eCaseHttpService: EcaseHttpService) {

  }

  getFullLovLoaded() {
    return new Observable((observer) => {
      if (this.lovFullList.length ==0) {
        this.getAllTaxonomies().subscribe(response => observer.next(response));
      } else {
        const obj: any = {};
          obj.status = true;
          obj.data = this.lovFullList;
        observer.next(obj);
      }
    });
  }

  getLovData() {
    // put lov data here
    return this.eCaseHttpService.get('/api/getAllLovs');
  }

  triggerLovsAreLoaded(lovs) {
    this.triggerSubject.next(lovs);
  }


  getAllTermsWithSequence(seqTaxonomy) {
    // put lov data here
    return this.eCaseHttpService.post('/api/allTermsLov', {'taxonomyIds': seqTaxonomy});
  }


  getLovWithTaxonomyId(taxonomyId) {
    return this.eCaseHttpService.post('/api/getLovWithTaxonomyId', {'taxonomyId': taxonomyId});
  }

  updateLov(offlineFormConfigurationJSON) {
    return this.eCaseHttpService.post('/api/addNewLov', offlineFormConfigurationJSON);
  }

  getAllTaxonomies() {
    return this.eCaseHttpService.get('/api/getLovs');
  }

  getAllTermsWithTermParentId(seqTermParentIds, showInActiveTerms?){
    return this.eCaseHttpService.post('/api/getAllTermsWithTermParentId', {
      'termParentIds': seqTermParentIds,
      'showInActiveTerms': showInActiveTerms ? showInActiveTerms : false
    });
  }

  addNewTaxonomy(taxonomyObject) {
    return this.eCaseHttpService.post('/api/addOrUpdateTaxonomy', taxonomyObject);
  }


  editTaxonomy(taxonomyObject) {
    return this.eCaseHttpService.post('/api/addOrUpdateTaxonomy', taxonomyObject);
  }

  addOrEditTerm(termObject) {
    return this.eCaseHttpService.post('/api/addOrUpdateTerm', termObject);
  }


  deleteTaxonomyById(taxonomyId) {
    return this.eCaseHttpService.post('/api/deleteTaxonomyById', {'taxonomyId': taxonomyId});
  }

  deleteTermById(termId) {
    return this.eCaseHttpService.post('/api/deleteTermById', {'termId': termId});
  }

  getAllTermsForSelectedTaxonomy(taxonomyId: string, taxonomyParentId?: string, showInActiveTerms?: boolean) {
    const obj = {
      taxonomyId: taxonomyId.toString(),
      taxonomyParentId: (taxonomyParentId ? taxonomyParentId.toString() : undefined),
      showInActiveTerms: showInActiveTerms
    };
    return this.eCaseHttpService.post('/api/getAllTermsWithTaxonomyId', obj);
  }

  getAllTermsByTax(idTax) {
    return this.eCaseHttpService.get('/api/getAllTermsByTaxId/' + idTax);
  }
  isOtherExistInTaxonomy(idTax) {
    return this.eCaseHttpService.get('/api/isOtherExistInTaxonomy/' + idTax);
  }
  getTermFullInfo(idTerm) {
    return this.eCaseHttpService.get('/api/getTermFullInfo/' + idTerm);
  }

  getAllWorkflowTaskNameList() {
    return this.eCaseHttpService.get('/api/getAllWorkflowTaskNameList');
  }

  getTermsChildren(idTerm) {
    return this.eCaseHttpService.get('/api/getTermsChildren/' + idTerm);
  }


  sortLovArrayAlphabetically(sortingArray, selectedLang) {
    sortingArray = sortingArray.sort((a, b) => {
      (a.name[selectedLang] === null || a.name[selectedLang] === undefined) ? a.name[selectedLang] = '' : a.name[selectedLang];
      (b.name[selectedLang] === null || b.name[selectedLang] === undefined) ? b.name[selectedLang] = '' : b.name[selectedLang];
      return (a.name[selectedLang]).toLowerCase().localeCompare((b.name[selectedLang]).toLowerCase());
    });
    return sortingArray;
  }




  sortTermArrayAlphabetically(sortingArray, selectedLang) {
    sortingArray = sortingArray.sort((a, b) => {
      (a.label[selectedLang] === null || a.label[selectedLang] === undefined) ? a.label[selectedLang] = '' : a.label[selectedLang];
      (b.label[selectedLang] === null || b.label[selectedLang] === undefined) ? b.label[selectedLang] = '' : b.label[selectedLang];
      return (a.label[selectedLang]).toLowerCase().localeCompare((b.label[selectedLang]).toLowerCase());
    });
    return sortingArray;
  }

  sortTermListAlphabetically(sortingArray, selectedLang) {
    sortingArray = sortingArray.sort((a, b) => {
      (a.value[selectedLang] === null || a.value[selectedLang] === undefined) ? a.value[selectedLang] = '' : a.value[selectedLang];
      (b.value[selectedLang] === null || b.value[selectedLang] === undefined) ? b.value[selectedLang] = '' : b.value[selectedLang];
      return a.value[selectedLang].localeCompare(b.value[selectedLang]);
    });
    return sortingArray;
  }

  sortTermListByKeyAndAlphabetically(sortingArray, selectedLang){
    sortingArray = sortingArray.sort((a, b) => {
      (a.value[selectedLang] === null || a.value[selectedLang] === undefined) ? a.value[selectedLang] = '' : a.value[selectedLang];
      (b.value[selectedLang] === null || b.value[selectedLang] === undefined) ? b.value[selectedLang] = '' : b.value[selectedLang];
      if(a.sortingKey !== null && b.sortingKey !== null && a.sortingKey !== b.sortingKey) {
        return a.sortingKey < b.sortingKey ? -1 : 1;
      } else {
        return a.value[selectedLang].localeCompare(b.value[selectedLang]);
      }
    });
    return sortingArray;
  }

}
