import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionsLabelsService {
  questions: any = [
    {
      'id': 1,
      'question': {
        'en': 'Is this project intended for funding?',
        'fr': 'Cette application est-elle destinée à un financement?'

      },
      'yesMappings': [2],
      'noMappings': [5]
    },
    {
      'id': 2,
      'question': {
        'en': 'Are you the Principal Investigator or a Student in the project?',
        'fr': 'Êtes-vous le chercheur principal ou un étudiant dans le projet?'
      },
      'yesMappings': [3],
      'noMappings': [5]
    },
    {
      'id': 3,
      'question': {
        'en': 'Has the project Received ethics approval at another Canadian Institution ?',
        'fr': 'Le projet a-t-il reçu l\'approbation éthique d\'un autre établissement canadien?'
      },
      'yesMappings': [4],
      'noMappings': [6]
    },
    {
      'id': 4,
      'question': {
        'en': 'Are you a Hôpital Montfort researcher or is any part of your study being conducted under the auspices of the Hôpital Montfort (e.g., recruitment, intervention, access to medical records, data/biological material collection or storage)?',
        'fr': 'Êtes-vous un chercheur de l\'Hôpital Montfort ou une partie de votre étude est-elle menée sous les auspices de l\'Hôpital Montfort (p. Ex. Recrutement, intervention, accès aux dossiers médicaux, collecte de données / matériel biologique ou entreposage)?',
      },
      'yesMappings': [5],
      'noMappings': []
    },
    {
      'id': 5,
      'question': {
        'en': 'Does this project only involve secondary use of data (i.e. the use in a research project of information originally collected for purposes other than the current research purpose)?',
        'fr': 'Ce projet implique-t-il uniquement l\'utilisation secondaire de données (c.-à-d. L\'utilisation dans un projet de recherche d\'informations initialement recueillies à des fins autres que le but de recherche actuel)?',
      },
      'yesMappings': [6],
      'noMappings': []
    },
    {
      'id': 6,
      'question': {
        'en': 'Is this application for a course you are teaching in which students will be collecting data involving human participants as part of the course requirements (e.g., in a research methods course)?',
        'fr': 'Cette application est-elle destinée à un cours que vous enseignez dans lequel les étudiants collectent des données impliquant des participants humains dans le cadre des exigences du cours (par exemple, dans un cours sur les méthodes de recherche)?',
      },
      'yesMappings': [],
      'noMappings': []
    },
    {
      'id': 7,
      'question': {
        'en': 'Is this application for a course you are teaching in which students will be collecting data involving human participants as part of the course requirements (e.g., in a research methods course)?',
        'fr': 'Cette application est-elle destinée à un cours que vous enseignez dans lequel les étudiants collectent des données impliquant des participants humains dans le cadre des exigences du cours (par exemple, dans un cours sur les méthodes de recherche)?',
      },
      'yesMappings': [],
      'noMappings': []
    }
    ,
    {
      'id': 8,
      'question': {
        'en': 'Are any of the research team members affiliated with the following institutions? (List all BCEHI institutions) Yes/No?\n',
        'fr': 'Est-ce que l\'un des membres de l\'équipe de recherche est affilié aux institutions suivantes? (Liste de toutes les institutions de BCEHI) Oui / Non?',
      },
      'yesMappings': [11],
      'noMappings': []
    },

    {
      'id': 9,
      'question': {
        'en': 'Will any recruitment of participants or conduct of research occur at any of the following institutions (List all BCEHI institutions) Yes/No?\n',
        'fr': 'Est-ce que le recrutement des participants ou la conduite de la recherche se fera dans l\'un des établissements suivants (Énumérez tous les établissements de BCEHI) Oui / Non?',
      },
      'yesMappings': [11],
      'noMappings': []
    }
    ,
    {
      'id': 10,
      'question': {
        'en': 'Is this application for a course you are teaching in which students will be collecting data involving human participants as part of the course requirements (e.g., a research methods course exercise, or other exercises designed to give students training in conducting and/or presenting research)? The activity should not be an undergraduate or graduate thesis/dissertation.\n?',
        'fr': 'Cette application pour un cours que vous enseignez dans lequel les étudiants vont recueillir des données impliquant des participants humains dans le cadre des exigences du cours (par exemple, un cours de méthodes de recherche, ou d\'autres exercices conçus pour donner aux étudiants ? L\'activité ne devrait pas être une thèse ou une dissertation de premier cycle ou de deuxième cycle.?',
      },
      'yesMappings': [12],
      'noMappings': []
    }

    ,
    {
      'id': 11,
      'question': {
        'en': 'Your study must be submitted on the BCEHI PREP platform for review. Please visit "link" to sumit your study". \n',
        'fr': 'Votre étude doit être soumise sur la plate-forme PREP de BCEHI pour examen. Veuillez cliquer sur« lien »pour résumer votre étude».',
      },
      'yesMappings': [],
      'noMappings': []
    }
    ,
    {
      'id': 12,
      'question': {
        'en': 'Go to Course Application?',
        'fr': 'Aller à l\'application de cours?',
      },
      'yesMappings': [],
      'noMappings': []
    }


  ];

  constructor() {

  }


}
