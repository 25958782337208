import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {
  eCaseAnimations,
  ECaseConfigService,
  EcaseHttpService, ECaseSnackBarService, ECaseStateMatcher, ECaseUtils,
  LanguageService,
  LoginAuthenticationService, ParametersService, TitleService
} from "synto-common";

@Component({
  selector: 'ECase-forgot-password-2',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: eCaseAnimations
})
export class ECaseForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  forgotPasswordFormErrors: any;
  logoSrc = '';
  baseUrl = '';
  selectedLanguage: any;
  languages: any[];
  matcher = new ECaseStateMatcher();
  showLanguageSwitcher: boolean;
  isDropShadowEnabled = false;


  constructor(private eCaseConfig: ECaseConfigService, private formBuilder: FormBuilder, public translate: TranslateService,
              private eCaseHttpService: EcaseHttpService, private loginAuthenticationService: LoginAuthenticationService,
              private eCaseSnackBarService: ECaseSnackBarService, private titleService: TitleService,
              public router: Router, private languageService: LanguageService, private parametersService: ParametersService,
              @Inject(DOCUMENT) private document: Document, public platform: Platform) {
    this.forgotPasswordFormErrors = {
      email: {}
    };
  }

  setLanguage(lang): void {
    this.selectedLanguage = lang;
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
    this.document.documentElement.lang = lang.id;
    this.titleService.setTitleWithSeparator(['RECOVERPASSWORD', 'ecase.common.defaultBrowserTabTitle'], ' | ');
    this.updateLogoSrc(lang.id);
  }

  updateLogoSrc(lang): void {
    if (this.parametersService.parameter['/core/ui/organisation_logo/multilingual_logo/enabled'] === 'true') {
      const splitSrc = this.logoSrc.split('_');
      this.logoSrc = splitSrc[0] + '_' + lang + '.' + splitSrc[1].split('.')[1];
    }
  }

  ngOnInit(): void {
    this.titleService.setTitleWithSeparator(['RECOVERPASSWORD', 'ecase.common.defaultBrowserTabTitle'], ' | ');
    this.logoSrc = this.parametersService.parameter['/core/ui/organisation_logo/url'];
    this.showLanguageSwitcher = this.parametersService.parameter['/core/show_language_switcher/enabled'] === 'true';
    this.isDropShadowEnabled = this.parametersService.parameter['/core/ui/hosting_organisation_logo_drop_shadow/enabled'] === 'true';
    this.eCaseHttpService.get('/api/getOrganisationLogoDetailsAndBaseUrl').subscribe((response: any) => {
      if (response.status) {

        this.baseUrl = response.baseUrl;
        this.loginAuthenticationService.baseUrl = this.baseUrl.includes('localhost') ? 'http://' + this.baseUrl : 'https://' + this.baseUrl;
      } else {
        this.eCaseSnackBarService.show('failure');
      }
    });
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, this.emailDomainValidator]]
    });
    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this.onForgotPasswordFormValuesChanged();
    });
    this.languages = this.languageService.languageList;
    if (this.languages && this.languages.length > 0) {
      this.languageService.triggerWhenLanguagesAreLoaded();
      this.languages = this.languageService.languageList;
      this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
      this.setLanguage(this.selectedLanguage);
    } else {
      this.languageService.getAllLanguages().subscribe((data) => {
        this.languages = this.languageService.createLanguage(data);
        this.languageService.languageList = this.languages;
        this.languageService.triggerWhenLanguagesAreLoaded();
        this.languages = this.languageService.languageList;
        console.log(this.languages);
        this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
        this.setLanguage(this.selectedLanguage);
      });
    }
  }

  getTextLength(l): number {
    try {
      return this.forgotPasswordForm.controls[l].value ? this.forgotPasswordForm.controls[l].value.length : 0;
    } catch (e) {
      return 0;
    }
  }


  onForgotPasswordFormValuesChanged(): void {
    for (const field in this.forgotPasswordFormErrors) {
      if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }
      this.forgotPasswordFormErrors[field] = {};
      const control = this.forgotPasswordForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.forgotPasswordFormErrors[field] = control.errors;
      }
    }
  }


  emailDomainValidator(control: FormControl): any {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (domain.toLowerCase() === 'sfu.ca') {
        return {
          emailDomain: {
            parsedDomain: domain
          }
        };
      }
    }
    return null;
  }


  getWidthFromMaxlength(): string {
    if ((this.platform.IOS && navigator.userAgent.match(/(iPad)/))) {
      return 'auto';
    }
   if(this.platform.ANDROID || (this.platform.IOS && !navigator.userAgent.match(/(iPad)/))){
      return '100%';

    }
    else {
      return '25%';
    }
  }


  sendactivatelink(): void {
    const email = this.forgotPasswordForm.controls['email'].value;
    this.eCaseHttpService.post('/api/resetPassword', {
      'username': email,
      'selectedLanguage': this.selectedLanguage.code
    }).subscribe({
      next: (_response: any) => {
        if (_response.status) {
          this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.resetpasswordlinksent'));
        } else {
          this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, _response.errorMessage));
        }
      }, error: (err1) => {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.invalidUsername'));
      }
    });
  }
}
