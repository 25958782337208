import {NgModule} from '@angular/core';

import {KeysPipe} from './keys.pipe';
import {GetByIdPipe} from './getById.pipe';
import {HtmlToPlaintextPipe} from './htmlToPlaintext.pipe';
import {FilterPipe} from './filter.pipe';
import {CamelCaseToDashPipe} from './camelCaseToDash.pipe';
import {ECaseDateFormatterPipe} from './eCaseDateFormatter.pipe';
import {ArraySortPipe} from './arraySortPipe.pipe';
import {SizeFormatPipe} from './sizeFormat.pipe';
import {LovFilterPipe} from './lov-filter.pipe';
import {NgForWithNumberPipe} from './ngForWithNumber.pipe';
import {ECaseTranslate} from './eCaseTranslate';
import {HtmlSanitizer} from './htmlSanitizer.pipe';
import {ECaseNumberFormatterPipe} from './eCaseNumberFormatter.pipe';


@NgModule({
  declarations: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    HtmlSanitizer,
    FilterPipe,
    ECaseDateFormatterPipe,
    CamelCaseToDashPipe,
    ArraySortPipe,
    SizeFormatPipe,
    ECaseTranslate,
    LovFilterPipe,
    NgForWithNumberPipe,
    ECaseNumberFormatterPipe
  ],
  imports: [],
  providers:[ECaseNumberFormatterPipe],
  exports: [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    HtmlSanitizer,
    ECaseDateFormatterPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    ArraySortPipe,
    SizeFormatPipe,
    LovFilterPipe,
    ECaseTranslate,
    NgForWithNumberPipe,
    ECaseNumberFormatterPipe
  ]
})

export class ECasePipesModule {

}
