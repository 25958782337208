import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sizeFormat'})
export class SizeFormatPipe implements PipeTransform
{
    transform(size: number): string {
      if (size > 99999) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
      else {
        return (size/1024).toFixed(2) + ' KB';
      }

    }
}
