<div>
  <table>
    <tr>
      <td>
        <i style="font-size: 50pt" class="material-icons">warning</i>
      </td>
      <td style="padding-left: 50px">
        <h1 style="margin: 0" mat-dialog-title> {{ 'ecase.researcherdasboard.projects.validationfailed' |
          translate}}</h1>
        <h4 style="margin: 0"> {{ 'ecase.researcherdasboard.projects.reviewtocontinue' | translate}}</h4>
      </td>
    </tr>
  </table>
</div>
<mat-dialog-content>
  <div *ngIf="languages">
    <table class="full-width" mat-table [dataSource]="dataSource">
      <!-- Section Name Column -->
      <ng-container matColumnDef="{{(displayedColumns[selectedLanguage])[0]}}">
        <th mat-header-cell *matHeaderCellDef> {{(displayedColumns[selectedLanguage])[0]}}</th>
        <ng-container *matCellDef="let element">
          <td class="text-align" [attr.rowspan]="element.rowSpan" *ngIf="!element.isDuplicateSection"
              mat-cell><a  style="cursor: pointer;" (click)="goToFormWithSpecificSection(element.sectionIndex)">{{element.section[selectedLanguage]}}</a>
          </td>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="{{(displayedColumns[selectedLanguage])[1]}}">
        <th class="text-align" mat-header-cell *matHeaderCellDef> {{(displayedColumns[selectedLanguage])[1]}}</th>
        <td class="text-align left-border" mat-cell *matCellDef="let element"> {{element.errorMessage[selectedLanguage]}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns[selectedLanguage]"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns[selectedLanguage];"></tr>
    </table>
    <!--    <mat-paginator #paginator
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10]"
                       [showFirstLastButtons]="true">
        </mat-paginator>-->
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="eCase-dialog-action-button-align">
    <button style="margin-left: 0 !important;" mat-raised-button class="eCase-dialog-negative-action-button"
            (click)="onClose()">{{'close' | translate}}
    </button>
  </div>
</mat-dialog-actions>
