import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';


@Injectable({
  providedIn: 'root'
})
export class RefreshContentTabsService {
  constructor(private eCaseHttpService: EcaseHttpService) {
  }


  getContentTabs(featureIdList, primeRole) {
    return this.eCaseHttpService.post('/api/getContentTabs', {
      'roleIdList': featureIdList,
      'primeRole': primeRole
    });
  }

}
