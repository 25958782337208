import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService implements Resolve<any> {
  forms: any[];
  onFormsChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: EcaseHttpService
  ) {
  }

  /**
   * Resolve
   *
   * @param route
   * @param state
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getForms()
      ]).then(
        () => {
          resolve(undefined);
        },
        reject
      );
    });
  }

  getForms(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('api/e-commerce-forms')
        .subscribe((response: any) => {
          this.forms = response;
          this.onFormsChanged.next(this.forms);
          resolve(response);
        }, reject);
    });
  }
}
