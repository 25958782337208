import {Component, HostBinding, HostListener, Input, OnDestroy} from '@angular/core';
import {eCaseAnimations, ECaseConfigService} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
    selector   : 'eCase-nav-horizontal-collapse',
    templateUrl: './nav-horizontal-collapse.component.html',
    styleUrls  : ['./nav-horizontal-collapse.component.scss'],
    animations : eCaseAnimations
})
export class ECaseNavHorizontalCollapseComponent implements OnDestroy
{
    onSettingsChanged: Subscription;
    eCaseSettings: any;
    isOpen = false;

    @HostBinding('class') classes = 'nav-item nav-collapse';
    @Input() item: any;

    @HostListener('mouseenter')
    open()
    {
        this.isOpen = true;
    }

    @HostListener('mouseleave')
    close()
    {
        this.isOpen = false;
    }

    constructor(
        private eCaseConfig: ECaseConfigService
    )
    {
        this.onSettingsChanged =
            this.eCaseConfig.onSettingsChanged
                .subscribe(
                    (newSettings) => {
                        this.eCaseSettings = newSettings;
                    }
                );
    }

    ngOnDestroy()
    {
        this.onSettingsChanged.unsubscribe();
    }
}
