import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Platform} from '@angular/cdk/platform';
import {
  eCaseAnimations,
  ECaseConfigService,
  ECaseDateFormatterPipe, EcaseHttpService, ECaseSnackBarService, ECaseTranslationLoaderService, ECaseUtils,
  LanguageService,
  LoginAuthenticationService, ParametersService
} from "synto-common";


@Component({
  selector: 'eCase-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: eCaseAnimations
})
export class ECaseResetPasswordComponent implements OnInit {
  isLinkActive: boolean = true;
  logoSrc = '';
  baseUrl = '';
  languages: any[];
  resetPasswordForm: FormGroup;
  resetPasswordFormErrors: any;
  selectedLanguage: any;
  showLanguageSwitcher: boolean;
  isActive = true;
  isActivePC = true;
  isStrongPasswordValidatorEnabled = 'false';
  strongPasswordHelpText = '{\'en\' : \'\', \'fr\' : \'\'}';
  isPasswordHelpTextVisible = false;
  isDropShadowEnabled = false;
  dateFormat;


  constructor(
    private eCaseConfig: ECaseConfigService, private eCaseSnackBarService: ECaseSnackBarService, private translationLoader: ECaseTranslationLoaderService,
    public router: Router, private route: ActivatedRoute, private loginAuthenticationService: LoginAuthenticationService, private formBuilder: FormBuilder,
    public translate: TranslateService,
    private parameterService: ParametersService,
    private eCaseDateFormatterPipe: ECaseDateFormatterPipe,
    private eCaseHttpService: EcaseHttpService, private languageService: LanguageService,
    private parametersService: ParametersService, public platform: Platform
  ) {
    this.dateFormat = this.parameterService.parameter['/core/ui/default_date_time_with_timezone/format'];
    this.resetPasswordFormErrors = {
      password: {},
      passwordConfirm: {}
    };
  }

  splitText(text: string, splitString: string): string[] {
    return text.split(splitString);
  }

  getTextLength(l): number {
    try {
      return this.resetPasswordForm.controls[l].value ? this.resetPasswordForm.controls[l].value.length : 0;
    } catch (e) {
      return 0;
    }
  }

  resetpassword(): void {
    this.eCaseHttpService.post('/api/updatePasswordforReset', {
      'password': this.resetPasswordForm.controls['password'].value,
      'activationCode': this.route.snapshot.paramMap.get('activationCode')
    }).subscribe((_response: any) => {
      if (_response.status) {
        this.router.navigate(['/']);
        this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.passwordresetcomplete'));
      } else {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, _response.errorMessage));
      }
    });
  }

  setLanguage(lang): void {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    // Use the selected language for translations
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
  }

  showPasswordHelpText(): void {
    const control = this.resetPasswordForm.get('password');
    if (control.invalid) {
      this.isPasswordHelpTextVisible = true;
    }
  }

  ngOnInit(): void {
    this.showLanguageSwitcher = this.parametersService.parameter['/core/show_language_switcher/enabled'] === 'true';
    this.isStrongPasswordValidatorEnabled = this.parametersService.parameter['/core/ui/strong_password_validation/enabled'];
    const passwordValidationPattern = this.parametersService.parameter['/core/strong_password_validator_regex_pattern'];
    const activationCode = this.route.snapshot.paramMap.get('activationCode');
    this.setLanguage(this.route.snapshot.paramMap.get('lang'));
    this.isDropShadowEnabled = this.parametersService.parameter['/core/ui/hosting_organisation_logo_drop_shadow/enabled'] === 'true';
    this.strongPasswordHelpText = JSON.parse(this.parametersService.parameter['/core/ui/strong_password_help_text']);
    // this.resetPasswordForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email, this.emailDomainValidator]],
    //   password: ['', Validators.required],
    //   passwordConfirm: ['', [Validators.required, confirmPassword]]
    // });

    this.resetPasswordForm = this.isStrongPasswordValidatorEnabled === 'true' ? this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(passwordValidationPattern)]],
      passwordConfirm: ['', [Validators.required, confirmPassword]]

    }) : this.formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPassword]]
    });


    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.onResetPasswordFormValuesChanged();
    });
    this.eCaseHttpService.post('/api/checkResetPasswordLinkExpiration', {'activationCode': activationCode}).subscribe((response: any) => {
      if (response.status) {
        if (response.isActivationCodeValid) {
          this.isLinkActive = response.isLinkActive;
          if (!this.isLinkActive && response.dateExpiredOn) {
            this.translate.get('ecase.common.resetpasswordlinkexpiredmessage').subscribe((data: any) => {
              const date = this.eCaseDateFormatterPipe.transform(response.dateExpiredOn, this.dateFormat);
              const text = data.replace('${dateExpiredOn}', date);
              this.eCaseSnackBarService.show('failure', text);
              this.router.navigate(['/forgotPassword']);
            });
          }
        } else {
          this.eCaseSnackBarService.show('failure', this.translate.getDefaultLang() === 'en' ? 'The link is expired. Please request a new one'
            : 'Le lien est expiré. S\'\'il vous plaît demander un nouveau');
          this.router.navigate(['/']);
        }
      } else {
        this.isLinkActive = true;
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, response.message));
      }
    });
    this.logoSrc = this.parametersService.parameter['/core/ui/organisation_logo/url'];
    this.eCaseHttpService.get('/api/getOrganisationLogoDetailsAndBaseUrl').subscribe((response: any) => {
      if (response.status) {
        this.baseUrl = response.baseUrl;
        this.loginAuthenticationService.baseUrl = this.baseUrl.includes('localhost') ? 'http://' + this.baseUrl : 'https://' + this.baseUrl;
      } else {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, response.errorMessage));
      }
    });
    this.languageService.getAllLanguages().subscribe((data) => {
      this.languages = this.languageService.createLanguage(data);
      this.languageService.languageList = this.languages;
      this.selectedLanguage = this.languages.filter((item) => {
        const selectedLang = this.route.snapshot.paramMap.get('lang');
        if (selectedLang) {
          return item.id === selectedLang;
        } else {
          return item.id === this.translate.getDefaultLang();
        }
      })[0];
      this.setLanguage(this.selectedLanguage);
    });
  }

  getWidthFromMaxlength(): boolean {
    return this.platform.ANDROID || (this.platform.IOS && !navigator.userAgent.match(/(iPad)/));
  }

  onResetPasswordFormValuesChanged(): void {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};
      // Get the control
      const control = this.resetPasswordForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  togglePassword(): void {
    this.isActive = !this.isActive;
  }

  togglePasswordConfirm(): void {
    this.isActivePC = !this.isActivePC;
  }
}

const confirmPassword = (control: AbstractControl): any => {
  if (!control.parent || !control) {
    return;
  }
  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');
  if (!password || !passwordConfirm) {
    return;
  }
  if (passwordConfirm.value === '') {
    return;
  }
  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
};
