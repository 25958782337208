import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ECaseSnackBarService, ECaseTranslationLoaderService, ECaseUtils} from 'synto-common';

@Component({
  selector: 'ecase-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private router: Router,
              private eCaseTranslationLoaderService: ECaseTranslationLoaderService,
              private eCaseSnackBarService: ECaseSnackBarService) {
  }

  ngOnInit(): void {
    this.eCaseTranslationLoaderService.loadTranslationsWithObservable(27).subscribe(() => {
      const i18nCode = this.activatedRoute.snapshot.paramMap.get('code');
      if (i18nCode && i18nCode !== '' && i18nCode.startsWith('ecase.')) {
        this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, i18nCode));
      }
      this.router.navigate(['/']).then(() => {});
    });
  }

}
