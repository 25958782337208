import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'eCase-generaldialog',
  templateUrl: './generaldialog.component.html',
  styleUrls: ['./generaldialog.component.css']
})
export class GeneraldialogComponent implements OnInit {

  @Input() data: string;
  @Input() sessiondialog? = false;
  @Input() sessionExpired? = false;
  @Input() isDisabled: boolean;

  constructor(
    public dialogRef: MatDialogRef<GeneraldialogComponent>
  ) {
    this.dialogRef.disableClose = true;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }


  // Session Logic

  sessionClose(sessionResult): void{
    this.dialogRef.close(sessionResult);
  }

  ngOnInit() {


  }

}
