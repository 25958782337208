import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class SectionComponetsValidationOnSaveService {
  private messageSource = new Subject<any>();
  sematicArray=[];
  nestedsematicId;
  nestedLevel: number;
  orginsematicArray=[];
  sematicArray2=[];
  semantic_equivalences= [];
  blockNameArray = [];
  allBlockNameArray = [];
  sematic;
  constructor() { }
  setSectionCompValidation(isFieldInvalid: any) {

    this.messageSource.next(isFieldInvalid);
  }
  getSectionCompValidation(): Observable<any>{
    return this.messageSource.asObservable();
  }
  sematicArrayMethod(){
    console.log(this.sematicArray);
    console.log(this.sematicArray2);

  }
}
