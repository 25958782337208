import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DynamicCompValidationService {
  private messageSource = new Subject<any>();
  sematicArray=[];
  constructor() {

  }

  setCompValidityStatus(isFieldInvalid: any) {

    this.messageSource.next(isFieldInvalid);
  }
  getCompValidityStatus(): Observable<any>{
    return this.messageSource.asObservable();
  }

  sematicArrayMethod(){
    console.log(this.sematicArray);

  }

}
