import {MatChipInputEvent} from '@angular/material/chips';
import {ECaseUtils} from '../eCaseUtils';

export class Form {
  formNumber: string;
  id: string;
  formName: string;
  formTeamLead: string;
  status: string;
  lastUpdated: string;
  handle: string;
  description: string;
  categories: string[];
  tags: string[];
  images: {
    default: boolean;
    id: string;
    url: string;
    type: string;
  }[];
  priceTaxExcl: number;
  priceTaxIncl: number;
  taxRate: number;
  comparedPrice: number;
  quantity: number;
  sku: string;
  width: string;
  height: string;
  depth: string;
  weight: string;
  extraShippingFee: number;
  active: boolean;

  constructor(form?) {
    form = form || {};
    this.formNumber = form.formNumber || ECaseUtils.generateGUID();
    this.formName = form.formName || '';
    this.id = form.id || '';
    this.status = form.status || '';
    this.lastUpdated = form.lastUpdated || '';
    this.formTeamLead = form.formTeamLead || '';
    this.handle = form.handle || ECaseUtils.handleize(this.formName);
    this.description = form.description || '';
    this.categories = form.categories || [];
    this.tags = form.tags || [];
    this.images = form.images || [];
    this.priceTaxExcl = form.priceTaxExcl || 0;
    this.priceTaxIncl = form.priceTaxIncl || 0;
    this.taxRate = form.taxRate || 0;
    this.comparedPrice = form.comparedPrice || 0;
    this.quantity = form.quantity || 0;
    this.sku = form.sku || 0;
    this.width = form.width || 0;
    this.height = form.height || 0;
    this.depth = form.depth || 0;
    this.weight = form.weight || 0;
    this.extraShippingFee = form.extraShippingFee || 0;
    this.active = form.active || true;
  }

  addCategory(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add name
    if (value) {
      this.categories.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCategory(category) {
    const index = this.categories.indexOf(category);

    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add tag
    if (value) {
      this.tags.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag) {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
}
