import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {LoginAuthenticationService} from "../../services/login-authentication.service";
import {EcaseHttpService} from "../../services/ecase-http.service";
import {ParametersService} from "../../services/parameters.service";
import {ECaseSnackBarService} from "../../services/snack-bar.service";
import {ECaseUtils} from "../../eCaseUtils";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
stringContactUs: string ;
  constructor(public dialogRef: MatDialogRef<ContactUsComponent>,
              private eCaseHttpService: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,
              private loginAuthenticationService: LoginAuthenticationService,
              private parametersService: ParametersService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.stringContactUs = ECaseUtils.getTranslatedValueFromKey(this.translate, this.parametersService.parameter['/core/login_page/contact_us']);
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
