import {DefaultLangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import {ECaseUtils} from '../../eCaseUtils';
import {ECaseTranslationLoaderService} from '../../services/translation-loader.service';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

  selectedLanguageID: string;

  constructor(private translate: TranslateService, private eCaseTranslationLoaderService: ECaseTranslationLoaderService) {
    super();
    this.translate.onDefaultLangChange.subscribe((params: DefaultLangChangeEvent) => {
      this.selectedLanguageID = params.lang;
      this.getAndInitTranslations();
    });
    this.getAndInitTranslations();
  }


  getAndInitTranslations() {
    if (!this.selectedLanguageID) {
      this.selectedLanguageID = this.translate.getDefaultLang() ? this.translate.getDefaultLang() : 'en';
    }
    if (ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorPage', this.selectedLanguageID) === 'paginatorPage') {
      this.eCaseTranslationLoaderService.loadTranslations(27);
    }
    this.loadPaginator();
    this.changes.next(undefined);
  }


  loadPaginator() {
    this.itemsPerPageLabel = ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorItemsPerPage', this.selectedLanguageID);
    this.firstPageLabel = ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorFirstPage', this.selectedLanguageID);
    this.previousPageLabel = ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorPreviousPage', this.selectedLanguageID);
    this.nextPageLabel = ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorNextPage', this.selectedLanguageID);
    this.lastPageLabel = ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorLastPage', this.selectedLanguageID);
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 ' + ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorOf', this.selectedLanguageID) + ` ${length}`;
      }
      length = Math.max(length, 0);
      if (length > 1000) {
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ` + ECaseUtils.getTranslatedValueFromKey(this.translate, "paginatorOf", this.selectedLanguageID) + ` ${length}`;
      } else {
        return ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorPage', this.selectedLanguageID)
          + ` ${page + 1} ` + ECaseUtils.getTranslatedValueFromKey(this.translate, 'paginatorOf', this.selectedLanguageID)
          + ` ${Math.ceil(length / pageSize)}`;
      }
    };
  }
}
