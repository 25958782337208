import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ContactUsComponent, ECaseNavigationService} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';
import {MatDialog} from '@angular/material/dialog';

@Component({
	selector: 'eCase-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ECaseNavigationComponent implements OnDestroy {
	navigationModel: any[];
	navigationModelChangeSubscription: Subscription;

	@Input() layout = 'vertical';

	constructor(private eCaseNavigationService: ECaseNavigationService, public dialog: MatDialog,) {
	  this.navigationModelChangeSubscription =
			this.eCaseNavigationService.onNavigationModelChange
				.subscribe((navigationModel) => {
					this.navigationModel = navigationModel;
				});
  }



  ngOnDestroy(): void {
		this.navigationModelChangeSubscription.unsubscribe();
	}

  openContactUsDialog(): void {
    this.dialog.open(ContactUsComponent, {
      width: '800px'
    });
  }
}
