import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {ECaseUtils} from '../eCaseUtils';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  defaultSyntoLogo: any = 'assets/images/favicons/apple-icon.png';
  i18nTitleCodesLength = 10;

  constructor(private title: Title, private meta: Meta, private translate: TranslateService) {
  }


  resetTitleOnLanguageChange(): void {
    let title = '';
    for (let i = 0; i < this.i18nTitleCodesLength; i++) {
      if (i !== this.i18nTitleCodesLength - 1) {
        title = title
          + ECaseUtils.getTranslatedValueFromKey(this.translate, this.getTag('i18nTabCode' + i), this.translate.getDefaultLang())
          + ECaseUtils.getTranslatedValueFromKey(this.translate, this.getTag('separator'), this.translate.getDefaultLang());
      } else {
        title = title + ECaseUtils.getTranslatedValueFromKey(this.translate, this.getTag('i18nTabCode' + i), this.translate.getDefaultLang());
      }
    }
    this.title.setTitle(title);
  }

  setTitleWithSeparator(i18nTitleCodes: Array<string>, separator?: string): void {
    this.i18nTitleCodesLength = i18nTitleCodes.length;
    if (!separator) {
      separator = ' ';
    }
    let title = '';
    for (let i = 0; i < i18nTitleCodes.length; i++) {
      if (i !== i18nTitleCodes.length - 1) {
        title = title + ECaseUtils.getTranslatedValueFromKey(this.translate, i18nTitleCodes[i], this.translate.getDefaultLang()) + separator;
      } else {
        title = title + ECaseUtils.getTranslatedValueFromKey(this.translate, i18nTitleCodes[i], this.translate.getDefaultLang());
      }
      this.meta.updateTag({name: 'i18nTabCode' + i, content: i18nTitleCodes[i]});
    }
    this.meta.updateTag({name: 'separator', content: separator});
    this.title.setTitle(title);

  }

  setFavicon(faviconUrl: string): void {
    const element: HTMLLinkElement = document.querySelector('link[rel=\'icon\']') as HTMLLinkElement;
    element.href = (faviconUrl && faviconUrl !== '') ? faviconUrl : this.defaultSyntoLogo;
  }


  addTag(key: string, content: string): void {
    this.meta.addTag({name: key, content: content});
  }

  getTag(name: string): string {
    return this.meta.getTag('name=' + name).content;
  }

  initialiseTags(): void {
    for (let i = 0; i < this.i18nTitleCodesLength; i++) {
      this.addTag('i18nTabCode' + i, '');
    }
  }


}
