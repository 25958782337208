import {Directive, ElementRef, HostListener, Input} from '@angular/core';

/**
 * Created by sahilb@evision.ca on 2021-12-01.
 */
@Directive({
  selector: '[eCaseNDecimalNumber]',
})
export class ECaseNDecimalNumberDirective {
  private numberOfDecimalPlacesAllowed = 2;
  private regex: RegExp = new RegExp(/^\d*\.?|,?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @Input()
  set eCaseNDecimalNumber(val) {
    if (ECaseNDecimalNumberDirective.validateInput(val)) {
      this.numberOfDecimalPlacesAllowed = Number(val);
      this.regex = new RegExp('^\\d*\\.?\\d{0,' + this.numberOfDecimalPlacesAllowed + '}$', 'g');
    }
  }

  private static validateInput(val): boolean {
    const isNotValid = isNaN(val);
    if (isNotValid) {
      throw new TypeError('Value passed in directive \'eCaseNDecimalNumber\' is not a number');
    }
    return isNotValid;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if(this.el.nativeElement.value === "-"){
     // this.el.nativeElement.value = "0"; do nothing here
    }
    else{
      if (isNaN(this.el.nativeElement.value.replace(",","."))) {
        event.preventDefault();
      } else {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
          return;
        }
        const current = this.el.nativeElement.value.toString();
        if (this.numberOfDecimalPlacesAllowed === 0 && (event.key === 'Decimal' || event.key === '.' || event.key === ',' || current.includes('.') || current.includes(','))) {
          this.el.nativeElement.value = current.split(',').join('').split('.').join('');
          event.preventDefault();
        } else if (this.numberOfDecimalPlacesAllowed > 0 && (((current.includes('.') || current.includes(',')) &&
          (event.key === 'Decimal' || event.key === '.' || event.key === ',')))) {
          let t = 0;
          this.el.nativeElement.value = current.replace(/\./g, (match) => {
            t++;
            return (t === 2) ? '' : match;
          });
          event.preventDefault();
        } else if (current && !(current + event.key).match(this.regex)) {
          event.preventDefault();
        }
      }
    }
    // changing commas by dots in case the language is french ( or similar )

    console.log("22here "+this.el.nativeElement.value)
  }
}
