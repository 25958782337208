import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {
  goToAddMeeting;
  selectedCommittee;
  arrayOfGrading;
  selectedProgram;
  languages;
  goToStartMeeting;
  addMember;
  addMemberToMeeting;
  selectedCommitteeStageId;
  isEditMode;
  isEditingAllowed;
  selectedApplication;
  memberSearched: string;
  meetingStatus;
  isCommitteeSelected;
  private _agendaCreationSubject = new Subject<any>();
  private _meetingStatusUpdateSubject = new Subject<any>();
  private _meetingListUpdateSubject = new Subject<any>();
  public dummyCommittee = {
    is_new: true,
    reviewCommittee: {
      id: 0,
      name: '',
      description: {},
      notes: ''
    },
    metadatas: [],
    stages: []
  };

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  _updateMeetingList(event) {
    this._meetingListUpdateSubject.next(event);
  }

  get onMeetingListUpdate() {
    return this._meetingListUpdateSubject.asObservable();
  }

  get onAgendaCreation() {
    return this._agendaCreationSubject.asObservable();
  }

  get onMeetingStatusChange() {
    return this._meetingStatusUpdateSubject.asObservable();
  }

  createNewAgenda(event) {
    this._agendaCreationSubject.next(event);
  }

  changeMeetingStatus(event) {
    this.meetingStatus = event;
    this._meetingStatusUpdateSubject.next(event);
  }

  setEditModeTrue() {
    return this.eCaseHttpService.post('/api/setMeetingEditModeTrue', {'reviewCommitteeStageId': this.selectedCommitteeStageId});
  }

  setEditModeFalse() {
    return this.eCaseHttpService.get('/api/setMeetingEditModeFalse');
  }

  getMeetingById(committeeDetails) {
    return this.eCaseHttpService.post('/api/getMeetingById', committeeDetails);
  }

  getAllMeetingCommittee(selectedCommitteeStage) {
    return this.eCaseHttpService.post('/api/getAllMeetings ', selectedCommitteeStage);
  }

  getAllApplicationsForMeeting(obj) {
    return this.eCaseHttpService.post('/api/getAllApplicationsForMeeting', obj);
  }

  getTask10Applications(obj) {
    return this.eCaseHttpService.post('/api/getTask10Applications', obj);
  }

  getTask10ApplicationsForMeeting(obj) {
    return this.eCaseHttpService.post('/api/getTask10ApplicationsForMeeting', obj);
  }

  setTask10ApplicationsForMeeting(obj) {
    return this.eCaseHttpService.post('/api/setTask10ApplicationsForMeeting', obj);
  }

  getAllMemberLinkedToCommittee(obj) {
    return this.eCaseHttpService.post('/api/getAllPermanentsMembers', obj);
  }

  getArrayOfGrading() {
    this.arrayOfGrading = [

      {
        id: 449,
        englishName: 'Review Level',
        frenchName: 'Review Level',
      }
    ];
    return this.arrayOfGrading;
  }

  addOrUpdateMeeting(meetingDetailsObject: any) {
    return this.eCaseHttpService.post('/api/addOrUpdateMeeting', {'meetingDetailsObject': meetingDetailsObject});
  }


  setApplicationToMeeting(committeeDetail) {
    return this.eCaseHttpService.post('/api/setApplicationForMeeting', {committeeDetail});
  }

  deleteAttachmentFromMeeting(attachmentId, csId) {
    return this.eCaseHttpService.post('/api/deleteAttachmentFromMeeting', {'attachmentId': attachmentId, 'csId': csId});
  }

  getAllAttachmentsForMeeting(csId, attachmentTypeIds) {
    return this.eCaseHttpService.post('/api/getAllAttachmentsForMeeting', {
      'attachmentTypeIds': attachmentTypeIds,
      'csId': csId
    });
  }

  uploadAttachmentForMeeting(formData) {
    return this.eCaseHttpService.post('/api/uploadAttachmentForMeeting', formData);
  }

  getAllPermanentMembersByCommittee(stageId: number) {
    return this.eCaseHttpService.post('/api/getAllPermanentsMembers', {'selectedCommitteeStage': stageId});
  }


  addOrUpdateMemberToMeeting(addOrUpdateMemberObject: any) {
    return this.eCaseHttpService.post('/api/addOrUpdateMemberToMeeting', {'addOrUpdateMemberObject': addOrUpdateMemberObject});
  }

  deleteMemberFromMeeting(deleteUserObject: any) {
    return this.eCaseHttpService.post('/api/deleteMemberFromMeeting', {'deleteUserObject': deleteUserObject});
  }

  getAllMembersOfSelectedMeeting(stageId: any) {
    return this.eCaseHttpService.post('/api/getAllMembersOfSelectedMeeting', {'stageId': stageId});
  }

  setMeetingEditModeFalse() {
    return this.eCaseHttpService.get('/api/setMeetingEditModeFalse');
  }

  setMeetingEditModeTrue() {
    return this.eCaseHttpService.post('/api/setMeetingEditModeTrue', {'reviewCommitteeStageId': this.selectedCommitteeStageId});
  }

  createAgendaForMeeting(csId) {
    return this.eCaseHttpService.post('/api/createAgendaForMeeting', {'csId': csId});
  }

  sendAgendaForMeeting(csId, agenda) {
    return this.eCaseHttpService.post('/api/sendAgendaForMeeting', {'csId': csId, 'agenda': agenda});
  }

  saveAgendaForMeeting(csId, agenda) {
    return this.eCaseHttpService.post('/api/saveAgendaForMeeting', {'csId': csId, 'agenda': agenda});
  }

  startMeeting(csId) {
    return this.eCaseHttpService.post('/api/startOrStopMeeting', {'csId': csId, 'action': 1});
  }

  endMeeting(csId) {
    return this.eCaseHttpService.post('/api/startOrStopMeeting', {'csId': csId, 'action': 0});
  }

  addOrUpdateAttendanceAndQuorumDetails(csId, details) {
    return this.eCaseHttpService.post('/api/addOrUpdateAttendanceAndQuorumDetails', {'csId': csId, 'details': details});
  }

  addOrUpdateGeneralComments(csId, comment) {
    return this.eCaseHttpService.post('/api/addOrUpdateGeneralComments', {'csId': csId, 'comment': comment});
  }

  getMeetingGeneralCommentsForUser(csId) {
    return this.eCaseHttpService.post('/api/getMeetingGeneralCommentsForUser', {'csId': csId});
  }

  addOrUpdateCommentsOnAttachment(csId, meetingAttachments) {
    return this.eCaseHttpService.post('/api/addOrUpdateCommentsOnAttachment', {
      'csId': csId,
      'meetingAttachments': meetingAttachments
    });
  }

  getUserRoleForMeeting(csId) {
    return this.eCaseHttpService.post('/api/getUserRoleForMeeting', {'csId': csId});
  }

  getAttendanceAndQuorumDetails(csId) {
    return this.eCaseHttpService.post('/api/getAttendanceAndQuorumDetails', {'csId': csId});
  }

  getNextAvailableMeetings(csId) {
    return this.eCaseHttpService.post('/api/getNextAvailableMeetings', {'csId': csId});
  }

  postponeMeeting(oldCsId, newCsId, meetingCancelationRemark) {
    return this.eCaseHttpService.post('/api/postponeMeeting', {
      'oldCsId': oldCsId,
      'newCsId': newCsId,
      'meetingCancelationRemark': meetingCancelationRemark
    });

  }

  getCommentsOnAttachment(csId) {
    return this.eCaseHttpService.post('/api/getCommentsOnAttachment', {'csId': csId});
  }

  getExistingMeetingMinutesTemplate() {
    return this.eCaseHttpService.get('/api/getExistingMeetingMinutesTemplate');
  }

  updateMeetingMinutesTemplate(template) {
    return this.eCaseHttpService.post('/api/updateMeetingMinutesTemplate', {'template': template});
  }

  getMeetingMinutesForMeeting(csId) {
    return this.eCaseHttpService.get('/api/getMeetingMinutesForMeeting/' + csId);
  }

  uploadMeetingMinutesTemplate(formData) {
    return this.eCaseHttpService.post('/api/uploadMeetingMinutesTemplate', formData);
  }

  publishMeeting(csId) {
    return this.eCaseHttpService.post('/api/publishMeeting', {'csId': csId});
  }

  getTemplateKeyValues() {
    return this.eCaseHttpService.get('/api/getTemplateKeyValues');
  }

  saveTeamMemberApplicationComment(csId, caseApplicationInternalId, comment) {
    return this.eCaseHttpService.post('/api/saveTeamMemberApplicationComment', {
      'csId': csId,
      'caseApplicationInternalId': caseApplicationInternalId,
      'comment': comment
    });
  }

  getTeamMemberApplicationComment(csId, caseApplicationInternalId) {
    return this.eCaseHttpService.post('/api/getTeamMemberApplicationComment', {
      'csId': csId,
      'caseApplicationInternalId': caseApplicationInternalId
    });
  }

  isUserAllowedToTakeDecision(csId) {
    return this.eCaseHttpService.post('/api/isUserAllowedToTakeDecision', {'csId': csId});
  }

  getAdditionalCommentsForApplication(csId, applicationId, isPaReport) {
    return this.eCaseHttpService.post('/api/getAdditionalCommentsForApplication', {
      'csId': csId,
      'applicationId': applicationId,
      'isPaReport': isPaReport
    });
  }

  getAgendaItemsCommentsForApplication(csId) {
    return this.eCaseHttpService.post('/api/getAgendaItemsCommentsForApplication', {
      'csId': csId
    });
  }

  getTaskPrj(csId, task8Id, applicationId, taskNumber, isPaReport) {
    return this.eCaseHttpService.post('/api/getTaskPrj', {
      'csId': csId,
      'task8Id': task8Id,
      'taskNumber': taskNumber,
      'applicationId': applicationId,
      'isPaReport': isPaReport
    });
  }

  getAllReviewers() {
    return this.eCaseHttpService.get('/api/getAllReviewers');
  }

  completeTask10Review(csId, applicationId, isPaReport) {
    return this.eCaseHttpService.post('/api/completeTask10Review', {
      'csId': csId,
      'applicationId': applicationId,
      'isPaReport': isPaReport
    });
  }


  modifyMeetingForApplication(csId, newCsId, applicationId, isPaReport) {
    return this.eCaseHttpService.post('/api/modifyMeetingForApplication', {
      'csId': csId,
      'newCsId': newCsId,
      'applicationId': applicationId,
      'isPaReport': isPaReport
    });
  }

  makeSelectedApplicationsREBAccepted(obj) {
    return this.eCaseHttpService.post('/api/makeSelectedApplicationsREBAccepted', obj);
  }

  removeKeyListFromSession(keys) {
    return this.eCaseHttpService.post('/api/removeKeyListFromSession', keys);
  }

  // ************************* Reviewer side services goes below ************************* //


  getMeetingsForReviewer() {
    return this.eCaseHttpService.get('/api/getAllMeetingsForReviewer');
  }

  getRankingStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewRankingStrategies');
  }

  getAllPlenaryTypes() {
    return this.eCaseHttpService.get('/api/getAllReviewPlenaryStrategies');
  }

  getAllScoringStrategies() {
    return this.eCaseHttpService.get('/api/getAllReviewPlenaryScoringStrategies');
  }

  reopenPlenary(cscaId: number, applicationId: number) {
    return this.eCaseHttpService.post('/api/reopenPlenary', {
      cscaId: cscaId,
      applicationId: applicationId
    });
  }

  completePlenary(cscaId: number, applicationId: number) {
    return this.eCaseHttpService.post('/api/completePlenary', {
      cscaId: cscaId,
      applicationId: applicationId
    });
  }
}
