import {NgModule} from '@angular/core';
import {SharedModule} from 'synto-common';
import {RouterModule} from '@angular/router';
import {ECaseNavigationComponent} from './navigation.component';
import {ECaseNavVerticalItemComponent} from './vertical/nav-item/nav-vertical-item.component';
import {ECaseNavVerticalCollapseComponent} from './vertical/nav-collapse/nav-vertical-collapse.component';
import {ECaseNavVerticalGroupComponent} from './vertical/nav-group/nav-vertical-group.component';
import {ECaseNavHorizontalItemComponent} from './horizontal/nav-item/nav-horizontal-item.component';
import {ECaseNavHorizontalCollapseComponent} from './horizontal/nav-collapse/nav-horizontal-collapse.component';


@NgModule({
    imports     : [
        SharedModule,
        RouterModule,
    ],
    exports     : [
      ECaseNavigationComponent,
      ECaseNavVerticalGroupComponent,
      ECaseNavVerticalItemComponent,
      ECaseNavVerticalCollapseComponent,
      ECaseNavHorizontalItemComponent,
      ECaseNavHorizontalCollapseComponent
    ],
    declarations: [
        ECaseNavigationComponent,
        ECaseNavVerticalGroupComponent,
        ECaseNavVerticalItemComponent,
        ECaseNavVerticalCollapseComponent,
        ECaseNavHorizontalItemComponent,
        ECaseNavHorizontalCollapseComponent
    ]
})
export class ECaseNavigationModule
{
}
