import {Pipe, PipeTransform} from '@angular/core';
import {ParametersService} from '../services/parameters.service';
import 'moment-timezone';
import {TranslateService} from '@ngx-translate/core';

import moment from 'moment';

@Pipe({
  name: 'eCaseDateFormatter'
})
export class ECaseDateFormatterPipe implements PipeTransform {
  syntoDate;

  constructor(private parameterService: ParametersService, private translateService: TranslateService) {
    this.syntoDate = this.parameterService.parameter['/core/ui/default_date/format'];
  }

  transform(value: any, pattern?: string, timezone?: string, locale?: string): string | null {
    pattern = pattern ? pattern : this.syntoDate;
    return this.transformWithMoment(value, pattern, !!timezone, locale);
  }

  transformWithMoment(value: any, format: string, showTimeZone: boolean, locale?: string): string | null {
    if (value) {
      if(Object.prototype.toString.call(value) === '[object Date]'){
        value = value.getTime();
      }
      moment.locale(locale ? locale : this.translateService.getDefaultLang());
      if (!isNaN(value)) {
        // value = value + (moment(Date.now()).utcOffset() * 60 * 1000)
      }

      const formattedDate = format ? moment(value).tz(moment.tz.guess()).format(format) : moment(value).tz(moment.tz.guess()).format();
      const timeZone = moment.tz.zone(moment.tz.guess()).abbr(value);
      return showTimeZone ? formattedDate + ' ' + timeZone : formattedDate;
    } else {
      return '';
    }
  }

}
