<!--<div [id]="'ecase.navigation.vertical.group.title.' + item.id" class="group-title">
  <span class="hint-text" [translate]="item.translate">{{ item.title }}</span>
</div>-->

<div [id]="'ecase.navigation.vertical.group.items.' + item.id" class="group-items">
  <ng-container *ngFor="let item of item.children">
    <eCase-nav-vertical-group [id]="'ecase.navigation.vertical.group.' + item.id" *ngIf="item?.itemType=='group'"
                              [item]="item"></eCase-nav-vertical-group>
    <eCase-nav-vertical-collapse [id]="'ecase.navigation.vertical.collapse.' + item.id"
                                 *ngIf="item?.itemType=='collapse'" [item]="item"></eCase-nav-vertical-collapse>
    <eCase-nav-vertical-item [id]="'ecase.navigation.vertical.item.' + item.id" *ngIf="item?.itemType=='item'"
                             [item]="item"></eCase-nav-vertical-item>
  </ng-container>
</div>
