import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  parameter: any;

  constructor(private http: HttpClient) {
    this.parameter = {};
  }

  getParameterValues(keys) {
    return this.http.post('/api/getParameterValues', keys);
  }

  setParameters(parameters) {

    for (const key of Object.keys(parameters)) {
      if (!this.parameter[key]) {
        this.parameter[key] = parameters[key];
      }
    }


  }

  getParameterValuesWithChildren(keys) {
    return this.http.post('/api/getParameterValuesWithChildren', keys);

  }


}
