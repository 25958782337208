import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  isContractsTableVisible: boolean;
  dataTypeArray;
  dataCategoryArray;
  dataGroupArray;
  contractId;
  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  addOrUpdateContract(contractItem){
    return this.eCaseHttpService.post('/api/addOrUpdateContract',contractItem);
  }
  getAllContracts(applicationInternalId){
    return this.eCaseHttpService.post('/api/getAllContracts',{'applicationInternalId':applicationInternalId});
  }
  getContractDetails(contractId){
    return this.eCaseHttpService.post('/api/getContractDetails',{'contractId':contractId});
  }
  deleteContract(contractId){
    return this.eCaseHttpService.post('/api/markContractAsDeleted ',{'contractId':contractId});
  }
  getProgramCodeForApplication(applicationInternalId){
    return this.eCaseHttpService.post('/api/getProgramCodeForApplication ',{'applicationInternalId':applicationInternalId});
  }
  getAllContractsAttachmentsForApplication(applicationInternalId){
    return this.eCaseHttpService.post('/api/getAllContractsAttachmentsForApplication ',{'applicationInternalId':applicationInternalId});
  }
  uploadAttachmentForContract(formData){
    return this.eCaseHttpService.post('/api/uploadAttachmentForContract ',formData);
  }
  deleteAttachmentFromContract(attachmentId) {
    return this.eCaseHttpService.post('/api/deleteAttachmentFromContract', {'attachmentId': attachmentId});
  }

}
