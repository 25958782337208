import {Component} from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './ecase-loading-spinner.html'
})
export class EcaseLoadingSpinnerComponent {


  constructor() {
  }
}





