import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DynamicDialogValidationService {
  private messageSource = new Subject<any>();

  constructor() { }

  setDynamicDialogValidationMessage(errorInComponents: any) {


    this.messageSource.next(errorInComponents);
  }
  getDynamicDialogValidationMessage(): Observable<any>{
    return this.messageSource.asObservable();
  }
}
