import {Injectable, OnDestroy} from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaderResponse,
  HttpHeaders,
  HttpProgressEvent,
  HttpResponse
} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {throwError} from "rxjs/internal/observable/throwError";
import {MatProgressBarService} from './mat-progress-bar.service';
import {catchError, distinctUntilChanged, finalize, map, scan, takeUntil} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {ErrorCodesService} from '../components/error-codes.service';
import {environment} from "../../../environments/environment";
import {ECaseUtils} from "../eCaseUtils";


@Injectable({
  providedIn: 'root'
})
export class EcaseHttpService implements OnDestroy {

  windowId;
  httpOptions;
  counter;
  // This time is in seconds
  sessionTimeoutLimitBeforeExpiration = (60 * 55);
  // This time is in seconds
  sessionTimeoutLimitForShowingMessage = (60 * 5);
  sessionTimeoutInterval;
  trackKeysPerPageArray: any = {currentPath: '', parentPath: '', keys: [], parentKeys: [], isTab: false};
  private sessionSubject = new Subject<any>();
  private downProgressSubject = new Subject<any>();
  cancelDownloadSubjects = [];

  constructor(private loaderService: MatProgressBarService, private http: HttpClient, private cookieService: CookieService,
              private router: Router, private errorCodesService: ErrorCodesService) {
    this.counter = 0;
    this.sessionTimeoutInterval = setInterval(() => {
      this.counter = this.counter + 1;
      if (this.counter === this.sessionTimeoutLimitBeforeExpiration) {
        this.checkForSessionTimout({status: true, counter: this.sessionTimeoutLimitForShowingMessage});
      }
    }, 1000);
  }

  get onDownloadEvent(): Observable<Download> {
    return this.downProgressSubject.asObservable();
  }

  get triggerSessionTimout(): Observable<any> {
    return this.sessionSubject.asObservable();
  }

  getAllKeysOfCurrentPage(): any {
    return this.trackKeysPerPageArray;
  }

  triggerDownloadEvent(download: Download): void {
    this.downProgressSubject.next(download);
  }

  checkForSessionTimout(response): void {
    this.sessionSubject.next(response);
  }

  delete<T>(url: string, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.delete(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  get<T>(url: string, options?, isIgnoreError?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    if (this.httpOptions['responseType'] === 'blob') {
      return this.http.get(url, {
        headers: this.httpOptions.headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      }).pipe(this.download(), catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
    } else {
      return isIgnoreError ? this.http.get(url, this.httpOptions) : this.http.get(url, this.httpOptions)
        .pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
    }
  }

  head<T>(url: string, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.head(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  jsonp<T>(url: string, callbackParam: string): Observable<any> {
    this.showLoader();
    this.counter = 0;
    return this.http.jsonp(url, callbackParam).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  options<T>(url: string, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.options(url, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  patch<T>(url: string, body: any | null, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.patch(url, body, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }

  post<T>(url: string, body: any | null, options?, isIgnoreError?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    if (this.httpOptions['responseType'] === 'blob') {
      return this.http.post(url, body, {
        headers: this.httpOptions.headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      }).pipe(this.download(), catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
    } else {
      return isIgnoreError ? this.http.post(url, body, this.httpOptions) : this.http.post(url, body, this.httpOptions)
        .pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
    }
  }

  put<T>(url: string, body: any | null, options?): Observable<any> {
    this.showLoader();
    this.counter = 0;
    this.prepareHeaders(options);
    return this.http.put(url, body, this.httpOptions).pipe(catchError(err => this.handleError(err)), finalize(() => this.onEnd()));
  }


  renewSession(): Observable<any> {
    return this.get('/api/renewSession');
  }

  ngOnDestroy(): void {
    clearInterval(this.sessionTimeoutInterval);
  }

  handleError(error): Observable<any> {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      return throwError(() => new Error(errorMessage));
    } else if (error.error instanceof ProgressEvent) {
      // client-side error
      const errorEvent: Download = {
        progress: Math.round((100 * error.error.loaded) / error.error.total),
        total: error.error.total,
        loadedFileSize: error.error.loaded,
        downloadSpeed: 0,
        remainingTimeInSeconds: ((error.error.loaded - error.error.loaded) / 0),
        state: "ERROR",
        fileId: -1,
        content: null,
        fileName: undefined
      };
      this.triggerDownloadEvent(errorEvent);
      errorMessage = `Error: ${error.error.message}`;
      return throwError(() => new Error(errorMessage));
    } else {
      // server-side error
      if (error.status === 403) {
        this.checkForSessionTimout({status: true, counter: 0});
      }
      if (error.status === 504 && environment.production) {
        this.errorCodesService.errorCode = error.status;
        this.router.navigate(['/error']).then(() => {
        });
      }
      if (error.status >= 302 && error.status <= 399) { // Redirects
        errorMessage = JSON.stringify({
          'errorCode': error.status,
          'errorMessage': 'redirect',
        });
      } else {
        errorMessage = JSON.stringify({
          'errorCode': error.status,
          'errorMessage': error.message,
        });
      }
      if (JSON.parse(errorMessage)['errorMessage'] === 'redirect') {
        return of('Just a redirect');
      } else {
        return throwError(JSON.parse(errorMessage));
      }
    }
  }

  private prepareHeaders(options): any {
    this.httpOptions = {headers: new HttpHeaders()};
    if (options) {
      if (this.cookieService.check('XSRF-TOKEN')) {
        this.httpOptions.headers = this.httpOptions.headers.append('X-XSRF-TOKEN', this.cookieService.get('XSRF-TOKEN'));
      }
      this.httpOptions.headers = this.httpOptions.headers.append('window_id', this.windowId.toString());
      for (const key of Object.keys(options)) {
        if (options[key]) {
          this.httpOptions[key] = options[key].toString();
        }
      }
    } else {
      if (this.windowId) {
        this.httpOptions.headers = this.httpOptions.headers.append('window_id', this.windowId.toString());
      }
      if (this.cookieService.check('XSRF-TOKEN')) {
        this.httpOptions.headers = this.httpOptions.headers.append('X-XSRF-TOKEN', this.cookieService.get('XSRF-TOKEN'));
      }
    }
    return this.httpOptions;
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
  }

  private isHttpHeaderResponse<T>(event: HttpEvent<T>): event is HttpHeaderResponse {
    return event.type === HttpEventType.ResponseHeader
  }

  private isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress
      || event.type === HttpEventType.UploadProgress
  }

  private download(): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    const fileId = Math.floor(Math.random() * 100);
    const downloadStartTimeInSeconds = new Date().getTime() / 1000;
    let isDownloadingInProgress = downloadStartTimeInSeconds * 1000;
    const downloadCheckInterval = setInterval(() => {
      if ((new Date().getTime() - isDownloadingInProgress) > 10000) {
        const errorEvent: Download = {
          progress: 0,
          total: 0,
          loadedFileSize: 0,
          downloadSpeed: 0,
          remainingTimeInSeconds: 0,
          state: "ERROR",
          fileId: fileId,
          content: null,
          fileName: undefined
        };
        this.triggerDownloadEvent(errorEvent);
        clearInterval(downloadCheckInterval);
      }
    }, 1000);
    let fileName: string;
    let totalFileSize: number | undefined;
    this.cancelDownloadSubjects.push({
      'fileId': fileId,
      'subject': new Subject()
    });
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        takeUntil(this.cancelDownloadSubjects.find(e => e.fileId === fileId).subject.asObservable().pipe(
          map((response: any) => {
            clearInterval(downloadCheckInterval);
            return response;
          })
        )),
        scan(
          (download: Download, event): Download => {
            isDownloadingInProgress = new Date().getTime();
            if (this.isHttpHeaderResponse(event)) {
              const fileNameHeader = event.headers.get('Content-Disposition');
              fileName = fileNameHeader ? fileNameHeader.match(/filename="(.+)"/)[1] : undefined;
              let fileNameSizeHeader = event.headers.get('Content-Length');
              if (!fileNameSizeHeader) {
                fileNameSizeHeader = event.headers.get('filesize');
              }
              totalFileSize = fileNameSizeHeader ? Number(fileNameSizeHeader) : undefined;
            }
            if (this.isHttpProgressEvent(event)) {
              const speed = (event.loaded / ((new Date().getTime() / 1000) - downloadStartTimeInSeconds));
              const total = (event.total ? event.total : totalFileSize);
              const downloadEvent: Download = {
                progress: total ? Math.round((100 * event.loaded) / total) : download.progress,
                total: total,
                loadedFileSize: event.loaded,
                downloadSpeed: speed,
                remainingTimeInSeconds: (total ? ((total - event.loaded) / speed) : undefined),
                state: "IN_PROGRESS",
                fileId: fileId,
                content: null,
                fileName: fileName
              };
              this.triggerDownloadEvent(downloadEvent);
              return downloadEvent;
            }
            if (this.isHttpResponse(event)) {
              const speed = (event.body.size / ((new Date().getTime() / 1000) - downloadStartTimeInSeconds));
              ECaseUtils.generateHyperLinkForDownload(event, ECaseUtils.getFileNameFromResponseHeader(event));
              const downloadEvent: Download = {
                progress: 100,
                total: event.body.size,
                state: "DONE",
                loadedFileSize: event.body.size,
                downloadSpeed: speed,
                remainingTimeInSeconds: 0,
                content: event.body,
                fileId: fileId,
                fileName: ECaseUtils.getFileNameFromResponseHeader(event)
              };
              this.triggerDownloadEvent(downloadEvent);
              const index = this.cancelDownloadSubjects.findIndex(e => e.fileId === fileId);
              this.cancelDownloadSubjects[index].subject.complete();
              this.cancelDownloadSubjects.splice(index, 1);
              clearInterval(downloadCheckInterval);
              return downloadEvent;
            }
            this.triggerDownloadEvent(download);
            return download;
          },
          {
            state: "PENDING",
            progress: 0,
            content: null,
            total: undefined,
            downloadSpeed: undefined,
            loadedFileSize: undefined,
            remainingTimeInSeconds: undefined,
            fileId: fileId,
            fileName: fileName
          }
        ),
        distinctUntilChanged((a, b) => a.state === b.state
          && a.progress === b.progress
          && a.content === b.content
          && a.fileId === b.fileId
        )
      );
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}


export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE' | 'ERROR'
  progress: number
  total: number | undefined
  loadedFileSize: number | undefined
  content: Blob | null
  downloadSpeed: number | undefined
  remainingTimeInSeconds: number | undefined
  fileId: number
  fileName: string | undefined
}
