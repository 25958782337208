import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationSystemService {

  constructor(private eCaseHttpService: EcaseHttpService) { }

  saveNotification(notificationObject) {
    return this.eCaseHttpService.post('/api/addNotification', notificationObject);
  }

  getAllNotifications(obj) {
    return this.eCaseHttpService.post('/api/getAllNotifications ',obj);
  }

  updateNotificationRow(notificationToUpdate) {
    return this.eCaseHttpService.post('/api/updateNotification', notificationToUpdate);
  }

}
