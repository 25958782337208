import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EcaseHttpService} from './ecase-http.service';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {map, mergeMap} from 'rxjs/operators';
import {ECaseSnackBarService} from './snack-bar.service';
import {combineLatest} from "rxjs/internal/observable/combineLatest";


@Injectable({
  providedIn: 'root'
})
export class ECaseTranslationLoaderService implements Resolve<any[]> {
  loadedFeatures = {};
  loadedTaxonomies = {};
  loadedRoles = {};
  loadedI18nCodes = {};
  onStartLoadedFeatureVersion = {};
  currentFeatureVersion = {};


  constructor(private translate: TranslateService,
              private eCaseHttpService: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,) {
  }

  public loadTranslationsWithObservableWithMultipleFeatures(features: number[]): Observable<any> {
    return combineLatest(features.map(feature => this.loadTranslationsWithObservable(feature)));
  }

  public loadTranslationsWithObservable(feature: number): Observable<any> {
    return this.getFeatureVersion(feature).pipe(mergeMap((_response) => {
      if (_response.status) {
        if (!this.currentFeatureVersion[feature] || this.currentFeatureVersion[feature] !== this.onStartLoadedFeatureVersion[feature]
          || !this.loadedFeatures[feature + '_' + this.translate.defaultLang]) {
          this.currentFeatureVersion[feature] = _response.version;
          this.onStartLoadedFeatureVersion[feature] = _response.version;
          return this.eCaseHttpService.get('/api/getTranslationForFeature/' + this.onStartLoadedFeatureVersion[feature] + '/' + feature).pipe(map((response) => {
            response.forEach((locale) => {
              // use setTranslation() with the third argument set to true
              // to append translations instead of replacing them
              this.loadedFeatures[feature + '_' + locale.lang] = locale;
              this.translate.setTranslation(locale.lang, locale.data, true);
            });
            return {};
          }));
        } else {
          this.translate.setTranslation(this.loadedFeatures[feature + '_' + this.translate.defaultLang].lang,
            this.loadedFeatures[feature + '_' + this.translate.defaultLang].data, true);
          return new Observable(subscriber => subscriber.next({}));
        }
      } else {
        this.eCaseSnackBarService.show('failure', 'ecase.common.errorloadinglanguages');
        return new Observable(subscriber => subscriber.next({}));
      }
    }));
  }

  public loadTranslationsForTaxonomyWithObservable(taxonomyIds: number[]): Observable<any> {
    return combineLatest(taxonomyIds.map(taxonomyId => {
      if (this.loadedTaxonomies[taxonomyId + '_' + this.translate.defaultLang]
        && this.loadedTaxonomies[taxonomyId + '_' + this.translate.defaultLang].lang
        && this.loadedTaxonomies[taxonomyId + '_' + this.translate.defaultLang].data) {
        this.translate.setTranslation(this.loadedTaxonomies[taxonomyId + '_' + this.translate.defaultLang].lang,
          // this.loadedFeatures[taxonomyId + '_' + this.translate.defaultLang].data, true);
          this.loadedTaxonomies[taxonomyId + '_' + this.translate.defaultLang].data, true);
        return of({});
      } else {
        return this.eCaseHttpService.get('/api/getTranslationForTaxonomy/' + taxonomyId).pipe(map((response) => {
          response.forEach((locale) => {
            this.loadedTaxonomies[taxonomyId + '_' + locale.lang] = locale;
            this.translate.setTranslation(locale.lang, locale.data, true);
          });
          return {};
        }));
      }
    }));
  }

  public loadTranslationsForRolesWithObservable(): Observable<any> {
    if (Object.keys(this.loadedRoles).length > 0) {
      Object.keys(this.loadedRoles).forEach(lang => {
        this.translate.setTranslation(lang, this.loadedRoles[lang], true);
      });
      return of({});
    } else {
      return this.eCaseHttpService.get('/api/getTranslationsForRoles').pipe(map((response) => {
        this.loadedRoles = response.roles;
        Object.keys(this.loadedRoles).forEach(lang => {
          this.translate.setTranslation(lang, this.loadedRoles[lang], true);
        });
        return {};
      }));
    }
  }

  public loadTranslationsForI18nCodesWithObservable(i18nCodes: string[]): Observable<any> {
    if (Object.keys(this.loadedI18nCodes).length > 0 && this.loadedI18nCodes[this.translate.defaultLang] &&
      i18nCodes.every(i18nCode => this.loadedI18nCodes[this.translate.defaultLang].includes(i18nCode))) {
      Object.keys(this.loadedI18nCodes).forEach(lang => {
        this.translate.setTranslation(lang, this.loadedI18nCodes[lang], true);
      });
      return of({});
    } else {
      return this.eCaseHttpService.post('/api/getTranslationsForI18nCodes', i18nCodes).pipe(map((response) => {
        this.loadedI18nCodes = response.i18nCodes;
        Object.keys(this.loadedI18nCodes).forEach(lang => {
          this.translate.setTranslation(lang, this.loadedI18nCodes[lang], true);
        });
        return {};
      }));
    }
  }

  public loadTranslations(feature: number): void {
    this.getFeatureVersion(feature).subscribe((_response: any) => {
      if (_response.status) {
        if (!this.currentFeatureVersion[feature] || this.currentFeatureVersion[feature] !== this.onStartLoadedFeatureVersion[feature]
          || !this.loadedFeatures[feature + '_' + this.translate.defaultLang]) {
          this.currentFeatureVersion[feature] = _response.version;
          this.onStartLoadedFeatureVersion[feature] = _response.version;
          this.eCaseHttpService.get('/api/getTranslationForFeature/' + this.onStartLoadedFeatureVersion[feature] + '/' + feature).subscribe((response: Array<any>) => {
            response.forEach((locale) => {
              // use setTranslation() with the third argument set to true
              // to append translations instead of replacing them
              this.loadedFeatures[feature + '_' + locale.lang] = locale;
              this.translate.setTranslation(locale.lang, locale.data, true);
            });
          });
        } else {
          this.translate.setTranslation(this.loadedFeatures[feature + '_' + this.translate.defaultLang].lang,
            this.loadedFeatures[feature + '_' + this.translate.defaultLang].data, true);
        }
      } else {
        this.eCaseSnackBarService.show('failure', 'ecase.common.errorloadinglanguages');
      }
    });
  }

  public loadTranslationsForCompetition(feature: number): void {
    this.eCaseHttpService.get('/api/getTranslationForFeature/' + this.onStartLoadedFeatureVersion[feature] + '/' + feature).subscribe((response: Array<any>) => {
      response.forEach((locale) => {
        // use setTranslation() with the third argument set to true
        // to append translations instead of replacing them
        this.loadedFeatures[feature + '_' + locale.lang] = locale;
        this.translate.setTranslation(locale.lang, locale.data, true);
      });
    });
  }

  public getSysFeatureVersions(): Observable<any> {
    return this.eCaseHttpService.get('/api/getSysFeatureVersions');
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    // if (isNumeric(route.paramMap.get('featureId'))) {
    if (!isNaN(27)) {
      return this.eCaseHttpService.get('/api/getTranslationForFeature/' + this.onStartLoadedFeatureVersion[27] + '/' + 27).pipe(map((response) => {
        response.forEach((locale) => {
          this.translate.setTranslation(locale.lang, locale.data, true);
        });
        return response;
      }));
    } else {
      const result: any[] = [];
      return new Observable(subscriber => subscriber.next(result));
    }
  }

  private getFeatureVersion(feature: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getFeatureVersion', feature);
  }

  //   public loadTranslations(isLogin: boolean): void {
  //    const locales;
  //  if(isLogin)
  //  locales = this.http.get('http://LoginserviceURL').map(res => res.json());
  //  else
  //  locales = this.http.get('http://serviceURL').map(res => res.json());
  //    locales.forEach((locale) => {
  //      // use setTranslation() with the third argument set to true
  //      // to append translations instead of replacing them
  //      this.translate.setTranslation(locale.lang, locale.data, true);
  //    });
  //  }

}
