import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class GraphConfigurationService {
  private messageSource = new Subject<any>();
  index=0;
  constructor() { }

  setGraphConfig(nameofproperties: any) {
   this.index++;

    this.messageSource.next(nameofproperties);
  }
  getGraphConfig(): Observable<any>{

    return this.messageSource.asObservable();
  }
}
