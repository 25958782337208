import {Pipe, PipeTransform} from '@angular/core';
import {ECaseUtils} from '../eCaseUtils';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform
{
    transform(mainArr: any[], searchText: string, property: string): any
    {
        return ECaseUtils.filterArrayByString(mainArr, searchText);
    }
}
