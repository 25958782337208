import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoginAuthenticationService} from '../services/login-authentication.service';

/**
 * Created by sahilb@evision.ca on 2019-10-18.
 * Usage :-
 *  With Array Data
 *  <div *eCaseNgIfWithSysActionIds="[12,45,67]">
 *       <!-- Content to be protected -->
 *  </div>
 *  <div *eCaseNgIfWithSysActionIdsCustom="['(',12,'||',45,')','&&',67]">
 *       <!-- Content to be protected -->
 *  </div>
 *  With Number
 *  <div *eCaseNgIfWithSysActionIds="23">
 *        <!-- Content to be protected -->
 *  </div>
 */
@Directive({
  selector: '[eCaseNgIfWithSysActionIds], [eCaseNgIfWithSysActionIdsOr], [eCaseNgIfWithSysActionIdsCustom]'
})
export class ECaseNgIfWithSysActionIdsDirective {

  private requiredSysActionIds = [];

  constructor(private loginAuthenticationService: LoginAuthenticationService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input()
  set eCaseNgIfWithSysActionIds(val) {
    if (ECaseNgIfWithSysActionIdsDirective.validateInput(val, false)) {
      this.requiredSysActionIds = val;
      this.updateView();
    }
  }

 // Directive to call when you need at least one of the sysactions
  @Input()
  set eCaseNgIfWithSysActionIdsOr(val) {
    if (ECaseNgIfWithSysActionIdsDirective.validateInput(val, false)) {
      this.requiredSysActionIds = val;
      this.updateViewOr();
    }
  }

  @Input()
  set eCaseNgIfWithSysActionIdsCustom(val) {
    if (ECaseNgIfWithSysActionIdsDirective.validateInput(val, true)) {
      this.requiredSysActionIds = val;
      this.updateViewCustom();
    }
  }

  private static validateInput(val, allowNonNumericalArray): boolean {
    const isValid = true;
    if (!Array.isArray(val) && isNaN(val)) {
      throw new TypeError('Value passed in directive \'eCaseNgIfWithSysActionIds\' in neither an array nor a number');
    } else if (Array.isArray(val) && (!allowNonNumericalArray && val.some(isNaN))) {
      throw new TypeError('Value passed in directive \'eCaseNgIfWithSysActionIds\' in not an array of numbers');
    }
    return isValid;
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;
    if (this.loginAuthenticationService && this.loginAuthenticationService.sysActionIdList) {
      hasPermission = Array.isArray(this.requiredSysActionIds) ?
        this.requiredSysActionIds.every(val => this.loginAuthenticationService.sysActionIdList.includes(val)) :
        this.loginAuthenticationService.sysActionIdList.includes(this.requiredSysActionIds);
    }
    return hasPermission;
  }

  private updateViewOr(): void {
    if (this.checkPermissionWithOr()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermissionWithOr(): boolean {
    let hasPermission = false;
    if (this.loginAuthenticationService && this.loginAuthenticationService.sysActionIdList) {
      hasPermission = Array.isArray(this.requiredSysActionIds) ?
        this.requiredSysActionIds.some(val => this.loginAuthenticationService.sysActionIdList.includes(val)) :
        this.loginAuthenticationService.sysActionIdList.includes(this.requiredSysActionIds);
    }
    return hasPermission;
  }

  private updateViewCustom(): void {
    if (this.checkPermissionWithCustom()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermissionWithCustom(): boolean {
    let hasPermission = false;
    if (this.loginAuthenticationService && this.loginAuthenticationService.sysActionIdList) {
      let evalString = 'const hasPermission = () => { ';
      if (Array.isArray(this.requiredSysActionIds)) {
        evalString = evalString + 'return ' + this.requiredSysActionIds
          .map(value => isNaN(value) ? value : this.loginAuthenticationService.sysActionIdList.includes(Number(value)))
          .join(' ') + ';';
      } else if (!isNaN(this.requiredSysActionIds)) {
        evalString = evalString + 'return ' + this.loginAuthenticationService.sysActionIdList.includes(this.requiredSysActionIds) + ';';
      } else {
        evalString = evalString + 'return false;'
      }
      evalString = evalString + ' }; hasPermission();'
      hasPermission = eval(evalString);
    }
    return hasPermission;
  }


}
