import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';
import {TypeDataModel} from '../model/typeData.model';

@Injectable({
  providedIn: 'root'
})
export class ChartsDataService {
  private messageSource = new Subject<any>();

 projectsData = [
   {
     'name': {'en':'In Progress','fr': 'En cours'} ,
     'value': 25,
     'change': +0.6
   },
   {
     'name': {'en':'Submitted','fr': 'Soumis'} ,
     'value': 25,
     'change': +0.6
   },
   {
     'name': {'en':'Funded','fr': 'Funded'},
     'value': 16,
     'change': +0.7
   },
   {
     'name':  {'en':'Archived','fr': 'Archivé'},
     'value': 11,
     'change': -0.1
   },


 ];

 totalValueOfProjectsData = [
   {
     'name':  {'en':'Arts','fr': 'Arts'},
     'value': '10',
     'change': +'60'
   },
   {
     'name':  {'en':'Engineering','fr': 'Ingénierie'},
     'value': '15',
     'change': +'25'
   },
   {
     'name':  {'en':'Management','fr': 'La gestion'},
     'value': '25',
     'change': +'30'
   }, {
     'name':  {'en':'Research','fr': 'Research '},
     'value': '30',
     'change': +'15'
   }
 ];

 submittedApplicationsData = [
   {
     'name': {'en':'Eligible','fr': 'Éligible'},
     'value': 10,
     'change': +0.6
   },
   {
     'name': {'en':'Fundable','fr': 'Financable'},
     'value': 7,
     'change': +0.7
   },
   {
     'name': {'en':'Rejected','fr': 'Rejeté'},
     'value': 3,
     'change': -0.1
   }, {
     'name': {'en':'Funded','fr': 'Funded'},
     'value': 4,
     'change': -0.25
   }
 ];

 applicationData = [
   {
     'name': {'en':'Created','fr': 'Créé'},
     'value': 10,
     'change': +0.2
   },
   {
     'name': {'en':'Submitted','fr': 'Soumis'},
     'value':5,
     'change': +0.5
   }
 ];

 constructor() { }

 setSelectedChartsData(nameOfData: TypeDataModel){
   this.messageSource.next(nameOfData);
 }

  getSelectedChartsData(): Observable<any>{
    return this.messageSource.asObservable();
  }

  getData(value: string){
    switch(value){
      case 'projectsData ':
        return this.projectsData;
      case'totalValueOfProjectsData':
        return this.totalValueOfProjectsData;
      case 'applicationData':
        alert('Iam in the application');
        return this.applicationData;
      case 'submittedApplicationsData':
        return this.submittedApplicationsData;
      default:
        return this.projectsData;


    }
  }
}
