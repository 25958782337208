import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshlovService {

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  getLOV() {
    return this.eCaseHttpService.get('http://localhost:9000/getlovs').subscribe(res => res);

  }

}
