import { Injectable } from '@angular/core';
import { EcaseHttpService } from './ecase-http.service';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class ChangePrincipalInvestigatorService {

  constructor(private eCaseHttpService: EcaseHttpService) { }

  validateApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/validateApplication', {
      caseApplicationId: applicationId,
      task: "changePrincipalInvestigator"
    });
  }

  updatePrincipalInvestigator(data): Observable<any> {
    return this.eCaseHttpService.post('/api/updatePrincipalInvestigator', data);
  }

  validatePiEmailAddress(piEmail): Observable<any> {
    return this.eCaseHttpService.post('/api/validatePiEmailAddress', {
      piEmail: piEmail
    });
  }
}
