import {AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';

import {CookieService} from 'ngx-cookie-service';
import {DOCUMENT} from '@angular/common';
import {CookiesPolicyDialogComponent} from './cookies-policy-dialog/cookies-policy-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PublicDocumentationDialogComponent} from './public-documentation-dialog/public-documentation-dialog.component';
import {Subject} from "rxjs/internal/Subject";
import {takeUntil} from 'rxjs/operators';
import {
  ContactUsComponent, eCaseAnimations,
  ECaseConfigService, EcaseHttpService, ECaseNavigationModel, ECaseNavigationService, ECaseSnackBarService,
  ECaseTranslationLoaderService, ECaseUtils, LanguageService, LoginAuthenticationService,
  MatProgressBarService, ParametersService, ProjectService,
  RefreshContentTabsService, TitleService, ECaseDateFormatterPipe
} from 'synto-common';


@Component({
  selector: 'eCase-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: eCaseAnimations
})

export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  languages: any[];
  selectedLanguage: any;
  errorMessage: any;
  accountLockedMillis = new Date().getTime();
  syntoDateTimeWithHourAndMinute;
  loginForm: FormGroup;
  loginFormErrors: any;
  showLoginComponent;
  logoSrc = '';
  baseUrl = '';
  capsLockOn = false;
  isDevEnvironment: boolean;
  showLanguageSwitcher: boolean;
  redirectUrl = '';
  isActive = true;
  isStrongPasswordValidatorEnabled = 'false';
  isAccountCreationActivated = true;
  forgotPasswordEnabled;
  isDropShadowEnabled = false;
  showInternalLogin = false;
  isHideLoginForm = false;
  cookiePolicyPopUpContent = '{}';
  // This shows popup bar at the bottom of the page won't block users to continue
  isShowCookiePolicyPopUpEnabled = false;
  // this is to show a dialog and force user to accept before moving any further in the system
  isShowCookiePolicyDialogEnabled = false;
  isUserAcceptedCookiePolicy = false;
  isPlatFormMobile = false;
  isShowEnvironment = true;
  isBrowserUnsupported = false;
  publicDocuments = [];
  hasGeneralDirectory = true;
  clientName = '';

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private eCaseConfig: ECaseConfigService, private formBuilder: FormBuilder, public translate: TranslateService, private projectService: ProjectService,
              private translationLoader: ECaseTranslationLoaderService, private refreshContentTabsService: RefreshContentTabsService,
              private matProgressBarService: MatProgressBarService, private route: ActivatedRoute, private eCaseHttpService: EcaseHttpService,
              private languageService: LanguageService, public loginAuthenticationService: LoginAuthenticationService, private eCaseSnackBarService: ECaseSnackBarService,
              public router: Router, private eCaseNavigationService: ECaseNavigationService, private parametersService: ParametersService,
              public platform: Platform, private titleService: TitleService, private cookieService: CookieService, public dialog: MatDialog,
              @Inject(DOCUMENT) private document: Document, private eCaseDateFormatterPipe: ECaseDateFormatterPipe) {
    this.eCaseConfig.setSettings({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
    this.loginFormErrors = {
      email: {},
      password: {}
    };
    router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          matProgressBarService.show();
          this.showLoginComponent = false;
        }
        if (event instanceof NavigationEnd) {
          matProgressBarService.hide();
          this.showLoginComponent = true;
        }
        if (event instanceof NavigationCancel) {
          matProgressBarService.hide();
          this.showLoginComponent = true;
        }
      });
    this.syntoDateTimeWithHourAndMinute = this.parametersService.parameter['/core/ui/default_date_time_with_hour_and_minute/format'];
    this.hasGeneralDirectory = false/*this.parametersService.parameter['/core/ui/show_general_directory_button'] === 'true'*/;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  acceptCookiePolicy(): void {
    this.cookieService.set('SYNTO_COOKIE_SUPPORT_ACCEPTED', 'true', 365, undefined, undefined, true, 'Lax');
    this.cookieService.set('SYNTO_COOKIE_SUPPORT_ACCEPTED_TIMESTAMP', new Date().getTime().toString(10), 365, undefined, undefined, true, 'Lax');
    this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.cookiepolicyaccepted'));
    this.isUserAcceptedCookiePolicy = true;
  }

  openDialog(): void {

  }

  ngOnInit(): void {
    this.isShowCookiePolicyDialogEnabled = this.parametersService.parameter['/core/ui/show_cookie_policy_dialog/enabled'] === 'true';
    this.isUserAcceptedCookiePolicy = this.cookieService.check('SYNTO_COOKIE_SUPPORT_ACCEPTED') && this.cookieService.get('SYNTO_COOKIE_SUPPORT_ACCEPTED') === 'true';
    if (this.isShowCookiePolicyDialogEnabled && !this.isUserAcceptedCookiePolicy) {
      this.dialog.open(CookiesPolicyDialogComponent, {
        disableClose: true,
        width: '700px'
      });
    }


    const currentBrowser = ECaseUtils.getBrowserName();
    this.getPublicDocuments();
    this.isBrowserUnsupported = (currentBrowser !== 'Chrome' && currentBrowser !== 'Safari' && currentBrowser !== 'Firefox' && currentBrowser !== 'Edge');
    this.isPlatFormMobile = this.platform.ANDROID || (this.platform.IOS && !navigator.userAgent.match(/(iPad)/));
    this.titleService.addTag('i18nTabCode', 'ecase.common.defaultBrowserTabTitle');
    this.titleService.initialiseTags();

    this.isShowCookiePolicyPopUpEnabled = this.parametersService.parameter['/core/ui/show_cookie_policy_pop_up/enabled'] === 'true';
    this.forgotPasswordEnabled = this.parametersService.parameter['/core/ui/forgot_password_link/enabled'] === 'true';
    //  this.isShowEnvironment = this.parametersService.parameter['/core/environment'] !== 'PROD';
    this.showLanguageSwitcher = this.parametersService.parameter['/core/show_language_switcher/enabled'] === 'true';
    this.isAccountCreationActivated = this.parametersService.parameter['/core/ui/account_creation/enabled'] === 'true';
    this.redirectUrl = this.parametersService.parameter['/core/base_redirect/url'];
    this.logoSrc = this.parametersService.parameter['/core/ui/organisation_logo/url'];
    this.isDropShadowEnabled = this.parametersService.parameter['/core/ui/hosting_organisation_logo_drop_shadow/enabled'] === 'true';
    this.isStrongPasswordValidatorEnabled = this.parametersService.parameter['/core/ui/strong_password_validation/enabled'];
    this.showInternalLogin = this.parametersService.parameter['/core/login/show_internal_login/enabled'] === 'true';
    this.isHideLoginForm = this.parametersService.parameter['/core/ui/hide_login_form/enabled'] === 'true';
    this.clientName = this.parametersService.parameter['/core/email/client_name'];
    this.showLoginComponent = location.pathname === '/'|| location.pathname.startsWith('/login/');
    this.languageService.getAllLanguages().subscribe((data) => {
      const browserLang = ECaseUtils.getBrowserLanguage();
      this.languages = this.languageService.createLanguage(data);
      this.languageService.languageList = this.languages;
      let langFound = this.languages.find(it => it.id === browserLang);
      if (langFound == null || langFound.isEmpty) {
        langFound = this.languages.find(it => it.id === this.translate.getDefaultLang());
      }
      this.selectedLanguage = this.languages.filter(item => item.id === langFound.id)[0];
      this.translate.use(this.selectedLanguage.id);
      this.translate.setDefaultLang(this.selectedLanguage.id);
      this.cookiePolicyPopUpContent = JSON.parse(this.parametersService.parameter['/core/ui/cookie_policy_pop_up_content'])[this.selectedLanguage.id];
      this.languageService.triggerWhenLanguagesAreLoaded();
      this.titleService.setTitleWithSeparator(['ecase.common.defaultBrowserTabTitle']);
      this.updateLogoSrc(this.selectedLanguage.id);


      if (this.route.snapshot.paramMap.get('lang') && this.showLoginComponent) {
        let selectedLang = (this.route.snapshot.paramMap.get('lang'))? this.route.snapshot.paramMap.get('lang') : this.translate.getDefaultLang() ;
        if(!this.languages.filter(item => item.id === selectedLang)[0]){
          selectedLang = this.translate.getDefaultLang();
        }
        this.setLanguage(this.languages.filter(item => item.id === selectedLang)[0]);
      }
    });

    this.eCaseHttpService.get('/api/getOrganisationLogoDetailsAndBaseUrl').subscribe((response: any) => {
      if (response.status) {
        this.isDevEnvironment = response.environment;
        this.loginAuthenticationService.isDevEnvironment = this.isDevEnvironment;
        this.baseUrl = response.baseUrl;
        this.loginAuthenticationService.baseUrl = this.baseUrl.includes('localhost') ? 'http://' + this.baseUrl : 'https://' + this.baseUrl;
      } else {
        this.eCaseSnackBarService.show('failure');
      }
    });

    this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.showLoginComponent = location.pathname === '/' || location.pathname.startsWith('/w/');
      if (this.showLoginComponent) {
        const selectedLang = (this.route.snapshot.paramMap.get('lang'))? this.route.snapshot.paramMap.get('lang') : this.translate.getDefaultLang() ;
        this.setLanguage(this.languages.filter(item => item.id === selectedLang)[0]);
      }
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.loginForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.onLoginFormValuesChanged();
    });
  }

  /*emailDomainValidator(control: FormControl): any {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (domain.toLowerCase() === 'sfu.ca') {
        return {
          emailDomain: {
            parsedDomain: domain
          }
        };
      }
    }
    return null;
  }*/

  getPublicDocuments(): void {
    this.loginAuthenticationService.getPublicDocuments().subscribe((response) => {
      if (response.status) {
        this.publicDocuments = response.documents;
        this.loginAuthenticationService.isPublicDocumentationDocsAvailable = this.publicDocuments.length > 0;
      } else {
        this.eCaseSnackBarService.show('failure', response.message);
      }
    });
  }

  openPublicDocumentsDialog(): void {
    const dialogRef = this.dialog.open(PublicDocumentationDialogComponent, {
      width: '800px'
    });
    dialogRef.componentInstance.documents = this.publicDocuments;
  }

  openContactUsDialog(): void {
    const dialogRef = this.dialog.open(ContactUsComponent, {
      width: '800px'
    });

  }

  focusInFunction(): void {

  }

  textReplace(originalText: string, textToBeReplaced: string, textTobeReplacedWith: string): string {
    return ECaseUtils.replaceAllWithoutRegex(originalText, textToBeReplaced, textTobeReplacedWith);
  }

  onLoginFormValuesChanged(): void {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  setLanguage(lang): void {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    // Use the selected language for translations
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
    this.titleService.setTitleWithSeparator(['ecase.common.defaultBrowserTabTitle']);
    this.cookiePolicyPopUpContent = JSON.parse(this.parametersService.parameter['/core/ui/cookie_policy_pop_up_content'])[lang.id];
    this.document.documentElement.lang = lang.id;
    this.updateLogoSrc(lang.id);
  }

  updateLogoSrc(lang): void {
    if (this.parametersService.parameter['/core/ui/organisation_logo/multilingual_logo/enabled'] === 'true') {
      const splitSrc = this.logoSrc.split('_');
      this.logoSrc = splitSrc[0] + '_' + lang + '.' + splitSrc[1].split('.')[1];
    }
  }

  getExpirationMessage(): string {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.accountlockedbecauseofmultiplefailures')
      .replace('${unlockDateTime}', this.eCaseDateFormatterPipe.transform(this.accountLockedMillis, this.syntoDateTimeWithHourAndMinute));
  }

  register(): void {
    this.router.navigate(['auth/register']).then(() => {
    });
  }

  forgotPassword(): void {
    this.router.navigate(['forgotPassword']).then(() => {
    });
  }

  authenticateCredentials(): void {
    if (this.loginForm.get('email').valid && this.loginForm.get('password').valid) {
      this.matProgressBarService.show();
      this.loginAuthenticationService.authenticateCredentials(
        this.loginForm.get('email').value,
        this.loginForm.get('password').value,
        this.cookieService.getAll()
      ).subscribe({
        next: (data: any) => {
          if (data.status) {
            const validationResult: any = data;
            this.loginAuthenticationService.resetLoginCounter().subscribe(() => {
            });
            this.projectService.isUnderGradStudent = validationResult.isUnderGradStudent;
            this.errorMessage = null;
            this.loginAuthenticationService.isUserLoggedIn = true;
            this.loginAuthenticationService.userProfile = validationResult.userProfile;
            this.loginAuthenticationService.primeRole = validationResult.primeRole;
            this.loginAuthenticationService.dummyRole = validationResult.primeRole;
            this.loginAuthenticationService.roles = validationResult.roles;
            this.loginAuthenticationService.featureIdList = validationResult.featureIdList;
            this.loginAuthenticationService.sysActionIdList = validationResult.sysActionIdList;
            this.loginAuthenticationService.isFirstTimeLogin = validationResult.isFirstTimeLogin;
            this.loginAuthenticationService.isProfileCompleted = validationResult.isProfileCompleted;
            this.loginAuthenticationService.isSamlProfile = validationResult.isSamlProfile;
            this.selectedLanguage = this.languages.filter(item => item.id === this.loginAuthenticationService.userProfile.principalLanguageCode)[0];
            this.translate.use(this.selectedLanguage.id);
            this.translate.setDefaultLang(this.selectedLanguage.id);
            this.refreshContentTabsService.getContentTabs(this.loginAuthenticationService.roles, validationResult.primeRole).subscribe((tabData) => {
              this.loginAuthenticationService.tabs = tabData.tabs;
              this.loginAuthenticationService.isContainApplicantRole = tabData.isContainApplicantRole;
              this.loginAuthenticationService.isOnlyApplicantRole = tabData.isOnlyApplicantRole;
              this.loginAuthenticationService.firstClickableFeature = tabData.firstClickableFeature;
              this.loginAuthenticationService.isAllPrivacyNoticesConsented = validationResult.isAllPrivacyNoticesConsented;
              this.loginAuthenticationService.privacyNoticeConsentDetails = {
                'ssoRoleId': tabData.ssoRoleId,
                'ssoConsentId': tabData.ssoConsentId
              };
              this.eCaseNavigationService.sideNavTabs = tabData.tabs;
              this.eCaseNavigationService.sideNavTabs = tabData.tabs;
              this.eCaseNavigationService.setNavigationModel(new ECaseNavigationModel(this.loginAuthenticationService.addDashBoards(this.loginAuthenticationService.tabs[0])));
              this.setLanguage(this.selectedLanguage);
              this.matProgressBarService.show();
              if (validationResult.isTwoFactorAuthenticationRequired && this.parametersService.parameter['/core/security/two_factor_authentication/enabled'] === 'true') {
                this.router.navigate(['/auth/2fa']).then(() => {
                });
              } else {
                if (validationResult.isAllPrivacyNoticesConsented) {
                  if (data.isProfileCompleted !== 'Y' && tabData.firstClickableFeature.parentRootId === 3) {
                    this.router.navigate(['/dashboard/profile/updateContact']).then(() => {
                    });
                  } else {
                    if (this.loginAuthenticationService.isUserRedirectedForLogIn
                      && this.loginAuthenticationService.oldRedirectedUrl) {
                      if (ECaseUtils.isUserHasAccessToUrl(this.loginAuthenticationService.oldRedirectedUrl, tabData.tabs)) {
                        this.router.navigate([this.loginAuthenticationService.oldRedirectedUrl]).then(() => {
                        });
                      } else {
                        this.eCaseSnackBarService.show('failure', ECaseUtils
                          .getTranslatedValueFromKey(this.translate, 'ecase.common.redirectpagenoacessmessage')
                          .replace('$redirectUrl', tabData.firstClickableFeature.url));
                        this.router.navigate([tabData.firstClickableFeature.url]).then(() => {
                        });
                      }
                    } else {
                      this.router.navigate([tabData.firstClickableFeature.url]).then(() => {
                      });
                    }
                  }
                } else {
                  this.router.navigate(['/dashboard/profile/consentToPrivacyNotice/' +
                  tabData.ssoRoleId + '/' + tabData.ssoConsentId]).then(() => {
                  });
                }
              }
            });
            this.isActive = true;
          } else {
            this.errorMessage = data.errorMessage;
            if (data.unlockDateTime) {
              this.accountLockedMillis = data.unlockDateTime;
            }
            switch (data.userStatusId) {
              case 721 :
                this.errorMessage = 'ecase.login.waitingforapproval';
                break;
              case 723 :
                this.errorMessage = 'ecase.login.accountnotapproved';
                break;
              case 724 :
                this.errorMessage = 'ecase.login.accountNoActive';
                break;
            }
            this.matProgressBarService.hide();
          }
        },
        error: (error: any) => {
          this.errorMessage = error.error.errorMessage;
        }
      });
    }


  }

  togglePassword(): void {
    this.isActive = !this.isActive;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (document.getElementById('description') !== null) {
        const documentInputField = document.getElementById('description').getElementsByTagName('p');
        const size = documentInputField.length;
        for (let i = 0; i < size; i++) {
          documentInputField.item(i).setAttribute('style', 'margin:0;');
        }
      }
    }, 500);
  }

  onKeyDown2(event): void {
    if (event) {
      this.togglePassword();
    } else {
      this.isActive = true;
    }
  }

  getWidthFromMaxlength(): boolean {
    return this.isPlatFormMobile;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }

  goGeneralDirectory(): void {
    this.router.navigate(['/general-directory']);
  }

}




