import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RefreshAutoCompleteDataService {
  private messageSource = new Subject<any>();
  private messageSourceForSelect = new Subject<any>();

  constructor() {
  }

  setNewValue(bphChange: any, confChange: any) {
    confChange.bphChange = bphChange;
    this.messageSource.next(confChange);
  }

  getNewValue(): Observable<any> {
    return this.messageSource.asObservable();
  }

  setNewValueForSelect(data) {
    this.messageSourceForSelect.next(data);
  }

  getNewValueForSelect(): Observable<any> {
    return this.messageSourceForSelect.asObservable();
  }
}
