import {Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DynamicLanguagesService {
  private messageSource = new Subject<any>();
  private messageSourceForLangSection = new Subject<any>();

  constructor() { }

  setNewDynamicLang(nameofproperties: any) {

    this.messageSource.next(nameofproperties);
  }
  getNewDynamicLang(): Observable<any>{

    return this.messageSource.asObservable();
  }

  setLanguageSectionError(errorInlangInput: any){
    this.messageSourceForLangSection.next(errorInlangInput);
  }

  getLanguageSectionError(): Observable<any>{

    return this.messageSourceForLangSection.asObservable();
  }

}


