import {Subject} from "rxjs/internal/Subject";
import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';
import {GraphModel} from '../model/graph.model';

@Injectable({
  providedIn: 'root'
})
export class MyGraphService {

  messageSource = new Subject<GraphModel>();
  index=0;
  constructor() { }

  setMyConfig(model: GraphModel) {

    this.messageSource.next(model);
  }
  getMyConfig(): Observable<any>{

    return this.messageSource.asObservable();
  }
}
