import { Injectable } from '@angular/core';
import { EcaseHttpService } from './ecase-http.service';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class ChangeOrganizationService {

  constructor(private eCaseHttpService: EcaseHttpService) { }

  validateApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/validateApplication', {
      caseApplicationId: applicationId,
      task: "changeOrganization"
    });
  }

  updateOrganization(data): Observable<any> {
    return this.eCaseHttpService.post('/api/updateOrganization', data);
  }
}
