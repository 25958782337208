<div *ngIf="isLinkActive" eCasePerfectScrollbar fxLayout="column" id="forgot-password" style="height: 100%">
  <div *eCaseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" fxLayout="column"
       fxLayoutAlign="center center" id="forgot-password-form-wrapper">
    <div id="forgot-password-form">
      <div *ngIf="showLanguageSwitcher">
        <mat-form-field appearance="outline" >
          <mat-select [(ngModel)]="selectedLanguage" (ngModelChange)="setLanguage($event)">
            <mat-option *ngFor="let language of languages" [value]="language">{{language.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="logo">
        <img *ngIf="isDropShadowEnabled" [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo" class="logoImg"
             style="filter: drop-shadow(0px 0px 5px #000);">
        <img *ngIf="!isDropShadowEnabled" class="logoImg"
             [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
             alt="Synto product logo">
      </div>
      <div class="title">{{ 'RECOVERPASSWORD' | translate }}</div>
      <form [formGroup]="resetPasswordForm" name="resetPasswordForm" novalidate>

        <mat-label><span style="color: red">* </span>{{ 'password' | translate }}</mat-label>
        <br *ngIf="getWidthFromMaxlength()">
        <mat-form-field appearance="outline" >
          <input (focusin)="showPasswordHelpText()" matInput formControlName="password" [type]="isActive ? 'password' : 'text'"
                 autocomplete="new-password" name="new-password" maxlength="50">
          <button mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="togglePassword()">
            <mat-icon>{{isActive ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
          <mat-error *ngIf="resetPasswordFormErrors.password.required">
            {{ 'passwordIsRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="resetPasswordFormErrors.password.pattern "> {{ 'passwordIsWeak' | translate }}
          </mat-error>
        </mat-form-field>
        <p  class="counter-style">{{getTextLength('password')}} / 50</p>

        <div *ngIf="isStrongPasswordValidatorEnabled === 'true' && isPasswordHelpTextVisible" class="passwordhelptextdiv">
          <span class="listspan">{{ 'passwordHelp' | translate }}</span>
          <ul style="padding-left: 28px;">
            <li *ngFor="let text of splitText(strongPasswordHelpText[selectedLanguage.id], '|')">
              <span class="listspan">{{text}}</span>
            </li>
          </ul>
        </div>

        <mat-label><span style="color: red">* </span>{{ 'passwordConfirm' | translate }}</mat-label>
        <br *ngIf="getWidthFromMaxlength()">
        <mat-form-field appearance="outline" >
          <input matInput  formControlName="passwordConfirm" [type]="isActivePC ? 'password' : 'text'"
                 autocomplete="new-password" name="confirm-new-password" maxlength="50">
          <button mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="togglePasswordConfirm()">
            <mat-icon>{{isActivePC ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
          <mat-error *ngIf="resetPasswordFormErrors.passwordConfirm.required">
            {{ 'passwordConfirmationIsRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="resetPasswordFormErrors.passwordConfirm.passwordsNotMatch">
            {{ 'passwordMustMatch' | translate }}
          </mat-error>
        </mat-form-field>
        <p  class="counter-style">{{getTextLength('passwordConfirm')}} / 50</p>

        <button (click)="resetpassword()" [disabled]="resetPasswordForm.invalid" aria-label="RECOVER PASSWORD"
                class="submit-button"
                color="accent" mat-raised-button>
          {{ 'RECOVERPASSWORD' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
