<mat-dialog-content>
  <span [innerHTML]="stringContactUs"></span>


</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button (click)="onCloseDialog()" class="eCase-dialog-negative-action-button"
          mat-raised-button>{{ 'close' | translate }}</button>
</mat-dialog-actions>



