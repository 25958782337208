import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';
import {ECaseUtils} from '../../eCaseUtils';

@Component({
  selector: 'app-validation-error-dialog',
  templateUrl: './validation-error-dialog.component.html',
  styleUrls: ['./validation-error-dialog.component.css']
})
export class ValidationErrorDialogComponent implements OnInit {

  @Input() validationResult;
  selectedLanguage;
  dataSource;
  languages: any[];
  displayedColumns = {};
  paginator: MatPaginator;


  constructor(public dialogRef: MatDialogRef<ValidationErrorDialogComponent>,
              private translate: TranslateService,
              private languageService: LanguageService) {
  }

  @ViewChild(MatPaginator, {static:true}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource = new MatTableDataSource<ValidationError>(this.validationResult);
    this.dataSource.paginator = this.paginator;
  }


  setLanguage(languageID: string) {
    this.selectedLanguage = languageID;
  }

  ngOnInit() {
    if(!this.selectedLanguage){
      this.setLanguage(this.translate.getDefaultLang());
    }
    this.languageService.getAllLanguages().subscribe((data) => {
      this.languages = this.languageService.createLanguage(data);
      for (const language of this.languages) {
        this.displayedColumns[language.id] = [ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.researcherdasboard.projects.section', language.id),
          ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.researcherdasboard.projects.errormessage', language.id)];
      }
    });
    ECaseUtils.groupBy(this.validationResult, item => item.sectionId).forEach((item) => {
      const replacingObject = this.validationResult.filter(_item => _item.sectionId === item[0].sectionId)[0];
      const index = this.validationResult.indexOf(replacingObject);
      replacingObject.rowSpan = item.length;
      this.validationResult[index] = replacingObject;
    });
    this.validationResult = this.validationResult.sort((a, b) => a.section[this.selectedLanguage].localeCompare(b.section[this.selectedLanguage]));
  }


  ngAfterViewInit() {
    this.dataSource.data = this.validationResult;
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  goToFormWithSpecificSection(sectionIndex) {
    this.dialogRef.close(sectionIndex);
  }


}

export interface ValidationError {
  sectionIndex: number;
  rowSpan: number;
  sectionId: string;
  section: any;
  errorMessage: any;
  isDuplicateSection: boolean;
  componentName: string;
}
