<a [id]="'ecase.navigation.vertical.collapse.link.' + item.id" class="nav-link" matRipple
   (focus)="loseFocus(item)" (mouseleave)="onMouseLeave(item)"
   (click)="toggleOpen($event, item);loseFocus(item)" href="" target="_blank" (keypress)="onKeyPress($event, item)">
  <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
  <span class="nav-link-title" >{{item.title | translate}}</span>
  <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
        [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
        {{item.badge.title}}
    </span>
  <mat-icon class="collapse-arrow">keyboard_arrow_right</mat-icon>
</a>

<div class="children" [@slideInOut]="isOpen">
  <ng-container *ngFor="let item of item.children">
    <eCase-nav-vertical-group [id]="'ecase.navigation.vertical.group.' + item.id" *ngIf="item?.itemType=='group'"
                              [item]="item"></eCase-nav-vertical-group>
    <eCase-nav-vertical-collapse [id]="'ecase.navigation.vertical.collapse.' + item.id"
                                 *ngIf="item?.itemType=='collapse'" [item]="item"></eCase-nav-vertical-collapse>
    <eCase-nav-vertical-item [id]="'ecase.navigation.vertical.item.' + item.id" *ngIf="item?.itemType=='item'"
                             [item]="item"></eCase-nav-vertical-item>
  </ng-container>
</div>
