import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {ECaseUtils} from '../eCaseUtils';

@Injectable({
  providedIn: 'root'
})
export class SaveJsonService {
  loadedFormId: number;
  loadedFormVersion: number;
  updatedSectionFromDropdown;
  defaultFormLanguage: any;
  tableComponentName: string;
  tableComponentType: string;
  clonedCopyOfJson: any;
  allTaxonomies = [];

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  saveJSONObjectToFile(offlineFormConfigurationJSON, formId) {
    console.log('this is the one....');
    console.log(offlineFormConfigurationJSON);
    formId = parseInt(formId, 10);
    offlineFormConfigurationJSON.rules = ECaseUtils.removeDuplicates(offlineFormConfigurationJSON.rules, 'rule');
    return this.eCaseHttpService.post('/api/saveConfig/' + formId, offlineFormConfigurationJSON);
  }

  getAllTermsForSelectedTaxonomy(taxonomyId: string) {
    const obj = {
      taxonomyId: taxonomyId.toString()
    };
    return this.eCaseHttpService.post('/api/getAllTermsWithTaxonomyId', obj);
    }

  getAllTaxonomies() {
    return this.eCaseHttpService.get('/api/getLovs');
  }

  exportForm(formId, loginAuthenticationService) {
    window.open(loginAuthenticationService.baseUrl + '/api/exportForm/' + formId);
  }

  importForm(data) {
    return this.eCaseHttpService.post('/api/importForm', data);
  }


}
