<div fxLayout="row" fxLayoutAlign="start" id="login">
  <div fxLayout="column"
       fxLayoutAlign="start" style="width: 100%">
    <ng-container *ngTemplateOutlet="LoginFieldsDiv;"></ng-container>
  </div>

  <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}"
       id="login-form-wrapper">

    <div>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="40">
        </div>
      </div>
      <div id="login-form">
        <main>
          <div class="title" style="color:black !important">
            Le portail n'est pas disponible à cet instant. Notre équipe travaille actuellement à rétablir sa fonctionnalité.
            Nous sommes désolés de tout inconvénient causé par cette interruption.
            Veuillez ressayer plus tard.

            <br>
            The portal is currently unavailable. Our team is working on restoring its functionality as soon as possible.
            We apologize for any inconvenience caused by this interruption.
            Please try again later.

          </div>
        </main>
      </div>
    </div>

    <div>
      <div class="footer" fxLayout="row" fxLayoutAlign="end center">
        <span class="footerText">Powered by / Propulsé par</span>
        <img [src]="'/assets/images/logos/syntoLogo.svg'" alt="eVision Synto Logo"
             style="height:25px;">
      </div>
    </div>
  </div>
</div>


<ng-template #LoginFieldsDiv>
  <div fxFlex fxHide fxShow.gt-xs id="login-intro">
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{scale:'0.1'}}" class="logo">
      <img [src]="'/assets/images/logos/syntoLogo.svg'"
           alt="Synto product logo"
           class="logoImg"
           style="filter: drop-shadow(0px 0px 5px #000);">
    </div>
   <!-- <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}"
         class="title">
      Welcome to Synto / Bienvenue sur Synto
    </div>
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}"
         class="subtitle">
      Research Grants Management System / Système de gestion du financement et de la conformité
    </div>-->
  </div>
</ng-template>


