import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export  class  UserInfoService {

  userInfo={};
  formValues={};
}
