import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ECaseUtils, LoginAuthenticationService, ParametersService} from 'synto-common';

@Component({
  selector: 'eCase-nav-vertical-item',
  templateUrl: './nav-vertical-item.component.html',
  styleUrls: ['./nav-vertical-item.component.scss']
})
export class ECaseNavVerticalItemComponent implements OnInit {
  @HostBinding('class') classes = 'nav-item';
  @Input() item: any;
  parameters = {};

  constructor(private router: Router, private loginAuthenticationService: LoginAuthenticationService, private parameterService: ParametersService) {
    this.parameters = this.parameterService.parameter;
  }

  onKeyPress(event, item, isOpenInNewTab) {
    const code = event.which;
    // 13 = Return, 32 = Space
    if ((code === 13) || (code === 32)) {
      if (isOpenInNewTab) {
        window.open(item.url);
      } else {
        ECaseUtils.scrollToTop();
        this.router.navigate([item.url]);
      }
    }
  }

  getURL(url: string): string {
    return ECaseUtils.replaceAllWithoutRegex(
      ECaseUtils.replaceAllWithoutRegex(
        ECaseUtils.replaceAllWithoutRegex(url, '{1}', this.loginAuthenticationService.userProfile.id)
        , '{2}', this.loginAuthenticationService.userProfile.hashedUsername),
      '{lang}', this.loginAuthenticationService.userProfile.principalLanguageCode);
  }

  loseFocus(item: any) {
    console.log(item);
    document.getElementById('ecase.navigation.vertical.item.link.' + item.id).blur();
    document.getElementById('ecase.navigation.vertical.item.link.' + item.id).setAttribute('style', 'background-color:transparent;');
    document.getElementById('buttonToFocusOn').focus();
  }

  onMouseEnter(item: any) {
    document.getElementById('ecase.navigation.vertical.item.link.' + item.id).setAttribute('style', '');
  }

  ngOnInit() {
  }
}
