import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';
import {
  CompetitionService,
  eCaseAnimations,
  ECaseDataTable, ECaseDateFormatterPipe, ECaseSnackBarService,
  LanguageService, LoginAuthenticationService, ParametersService,
  TitleService
} from 'synto-common';


@Component({
  selector: 'app-general-directory',
  templateUrl: './general-directory.component.html',
  styleUrls: ['./general-directory.component.scss'],
  animations: eCaseAnimations,
})
export class GeneralDirectoryComponent extends  ECaseDataTable implements OnInit {


  opportunities = [];
  originalOpportunities = [];
  selectedLang;
  displayedColumns = ['programName', 'competitionName', 'openDate', 'deadline', 'prospectus'];
  filterObject = {
    'programName': '',
    'competitionName': '',
    'formattedOpenDate': '',
    'formattedDeadline': ''
  };
  sortActive = 'programName';
  sortDirection = 'asc';

  loading = true;
  languages: any[];

  constructor(private titleService: TitleService, private languageService: LanguageService,
              private competitionService: CompetitionService, translate: TranslateService,
              parameterService: ParametersService, private loginAuthenticationService: LoginAuthenticationService,
              public datePipe: ECaseDateFormatterPipe, private snackBarService: ECaseSnackBarService ) {
    super(translate, parameterService);

  }

  ngOnInit(): void {
    this.loading = true;
    this.languageService.getAllLanguages().subscribe((data) => {
      this.languages = this.languageService.createLanguage(data);
      this.selectedLang = this.translate.getDefaultLang();
    });
    this.loadTable();
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLang = params.lang;
      this.loadTable();
    });
    document.body.style.overflow ='auto';
  }

  loadTable(): void {
    this.competitionService.getAllActiveCompetitionsForGeneralDirectory().subscribe((response) => {
      if (response.status) {
        response.result.forEach((item) => {
          if(item.competitions && item.competitions.length > 0) {
            item.competitions = cloneDeep(item.competitions.filter(it => it.showInGeneralDirectory === true));
          }
        });
        this.adjustRows(response.result);
      }
    });
  }

  adjustRows(objects): void{
    let finalList = [];
    let counter = 0;
    objects.sort((a, b) => {
      let x = a.programName[this.selectedLang];
      let y = b.programName[this.selectedLang];
      if (a.programName[this.selectedLang] === null || !a.programName[this.selectedLang]) {
        x = '';
      }
      if (b.programName[this.selectedLang] === null || !b.programName[this.selectedLang]) {
        y = '';
      }
      return (x.localeCompare(y));
    })
      .forEach((program)=>{
        if(program.competitions && program.competitions.length > 0) {
          program.isVisible = true;
          program.programName = program.programName[this.selectedLang];
          program.isProgramLine = true;
          program.competitionName = '';
          program.showCompetitionRowToggle = true;
          program.showCompetitionRows = false;
          program.order = counter ++;
          finalList.push(program);
          const cpmList = program.competitions.map((cpm: any)=> {
            cpm.programId = program.programId;
            cpm.programName = program.programName;
            cpm.isVisible = false;
            cpm.competitionName = cpm.competitionName[this.selectedLang];
            cpm.showCompetitionRows = true;
            cpm.showCompetitionRowToggle = false;
            cpm.formattedOpenDate = this.datePipe.transform(cpm.openDate, this.syntoDateTimeWithHourAndMinute);
            cpm.formattedDeadline = cpm.deadline && cpm.deadline !== '' ? this.datePipe.transform(cpm.deadline, this.syntoDateTimeWithHourAndMinute) : '';
            cpm.order = counter ++;
            cpm.isProgramLine = false;
            return cpm;
          }).sort((a, b) => {
            if (a.openDate < b.openDate) {
              return -1;
            } else if (a.openDate > b.openDate) {
              return 1;
            }
            return 0;
          });
          finalList = finalList.concat(cpmList);
        }
      });
    this.opportunities = cloneDeep(finalList);
    this.serviceArray = this.opportunities;
    this.originalArray = this.opportunities;
    this.setDataSourceAttributes();
    console.log('opportunities', this.opportunities);
    this.loading = false;
  }


  toggleCompetitionRows(row): void {
    row.showCompetitionRows = !row.showCompetitionRows;
    this.opportunities = this.opportunities.map((item) => {
      if(item.programId === row.programId && !item.isProgramLine) {
        item.isVisible = row.showCompetitionRows;
      }
      return item;
    });

    this.serviceArray = this.opportunities;
    this.originalArray = this.opportunities;
    this.applyFilter();
  }

  downloadProspectus(row): void {

    if(row.prospectus.docs[this.selectedLang].edmDocumentRepositoryId) {
      window.open(this.loginAuthenticationService.baseUrl + '/api/downloadDocumentation/' + row.prospectus.docs[this.selectedLang].edmDocumentRepositoryId);
    } else {
      this.snackBarService.show('error');
    }

  }


  setLanguage(lang): void {
    // Set the selected language for toolbar
    this.selectedLang = lang;
    // Use the selected language for translations
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
  }
}
