import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialogRef} from '@angular/material/dialog';
import {
  eCaseAnimations,
  ECaseDataTable,
  EcaseHttpService, ECaseSnackBarService, ECaseUtils,
  LoginAuthenticationService,
  ParametersService
} from 'synto-common';

@Component({
  selector: 'ecase-public-documentation-dialog',
  templateUrl: './public-documentation-dialog.component.html',
  styleUrls: ['./public-documentation-dialog.component.scss'],
  animations: eCaseAnimations
})
export class PublicDocumentationDialogComponent extends ECaseDataTable implements OnInit {

  @Input() documents: any[] = [];
  // ----------------  Table code starts from here --------------------//
  displayedColumns = ['documentType', 'name', 'description'];
  filterObject = {
    'documentType': '',
    'name': '',
    'description': ''
  };
  sortActive = 'documentType';
  sortDirection = 'asc';
  // ----------------  Table code end here --------------------//
  originalDocuments: any[] = [];
  selectedLang;

  constructor(translate: TranslateService,
              public dialogRef: MatDialogRef<PublicDocumentationDialogComponent>,
              private eCaseHttpService: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,
              private loginAuthenticationService: LoginAuthenticationService,
              parameterService: ParametersService) {
    super(translate, parameterService);
    this.serviceArray = this.documents;
  }

  sortColumn(event: any, childPath?: string): void {
    this.dataSource.data = ECaseUtils.sortColumn(event, this.dataSource.data, this.translate, childPath);
    this.renewDataSource(this.dataSource.data);
  }

  renewDataSource(newDataArray): void {
    this.dataSource = new MatTableDataSource(newDataArray);
    this.sortingDataAccessorWithIgnoreCase();
    this.dataSource.paginator = this.paginator.toArray()[0];
  }

  ngOnInit(): void {
    this.selectedLang = this.translate.getDefaultLang();
    this.originalArray = this.documents;
    this.serviceArray = this.documents;
    this.originalDocuments = ECaseUtils.eCaseCloneDeep(this.documents);
    this.dataSource = new MatTableDataSource(this.documents);
    this.sortColumn({active: this.sortActive, direction: this.sortDirection});
  }

  downloadDocument(row): void {
    this.eCaseHttpService.get(this.loginAuthenticationService.baseUrl + '/api/downloadDocumentation/' + row.docId, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe({
      next: (response: any) => {
        ECaseUtils.generateHyperLinkForDownload(response);
      },
      error: (e) => {
        this.eCaseSnackBarService.show('failure', e);
      }
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
