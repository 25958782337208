import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {ParametersService} from '../services/parameters.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import {Subscription} from "rxjs/internal/Subscription";

/**
 * Created by sahilb@evision.ca on 2020-10-04.
 */
@Pipe({
  name: 'eCaseNumberFormatterPipe'
})
export class ECaseNumberFormatterPipe implements PipeTransform, OnDestroy {
  defaultSystemCurrency = '';
  isFormatNegativeNumberWithParenthesis = false;
  selectedLang = '';
  subscription: Subscription;

  constructor(private parameterService: ParametersService, private translateService: TranslateService, private platform: Platform) {
    this.defaultSystemCurrency = this.parameterService.parameter['/core/ui/default_currency'];
    this.isFormatNegativeNumberWithParenthesis = this.parameterService.parameter['/core/ui/format_negative_number_with_brackets'] === 'true';
    this.selectedLang = this.translateService.getDefaultLang();
    this.subscription = this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLang = params.lang;
    });
  }

  transform(value: any, isCurrency?: boolean, locale?: string, removeFraction?: boolean, currency?: string, decimalPlacesAllowed?: number): string | null {
    if ("-" === value) {
      value = 0;
    }
    locale = locale ? locale : this.selectedLang;
    let cur;
    if (!currency) {
      cur = this.defaultSystemCurrency;
    } else {
      cur = currency;
    }
    const options = isCurrency ? {
      style: 'currency',
      currencyDisplay: (this.platform.SAFARI || this.platform.EDGE) ? 'symbol' : 'narrowSymbol',
      currency: cur
    } : {};
    if (removeFraction) {
      options['maximumFractionDigits'] = 0;
      options['minimumFractionDigits'] = 0;
    }
    if (decimalPlacesAllowed && decimalPlacesAllowed > 0) {
      options['minimumFractionDigits'] = decimalPlacesAllowed;
      options['maximumFractionDigits'] = decimalPlacesAllowed;
    }
    return (isNaN(value) ? value : ((this.isFormatNegativeNumberWithParenthesis && value < 0) ?
      ('(' + new Intl.NumberFormat(locale, options).format((value * -1)) + ')') :
      new Intl.NumberFormat(locale, options).format(value)));
  }


  // Value has to be in bytes
  fileSizeTransform(value: any, locale?: string): string | null {
    locale = locale ? locale : this.selectedLang;
    let unit: string;
    if (!isNaN(value)) {
      if (value <= 1024) { // 1KB
        unit = 'byte';
      } else if (value <= 1048576) { // 1MB
        value = value / 1024;
        unit = 'kilobyte';
      } else if (value <= 1073741824) { // 1GB
        value = value / 1048576;
        unit = 'megabyte';
      } else if (value <= 1099511627776) { // 1TB
        value = value / 1073741824;
        unit = 'gigabyte';
      } else if (value <= 1125899906842624) { // 1PB
        value = value / 1073741824;
        unit = 'terabyte';
      }
      let options;
      if (this.platform.SAFARI || this.platform.EDGE) {
        options = {
          style: 'decimal'
        };
        return new Intl.NumberFormat(locale, options).format(value) + ' ' + unit;
      } else {
        options = {
          style: 'unit',
          unit: unit
        };
        return new Intl.NumberFormat(locale, options).format(value);
      }
    } else {
      return value;
    }
  }

  reverseFormatToNumber(value: string, isCurrency?: boolean, locale?: string, removeFraction?: boolean, decimalPlacesAllowed?: number): number {
    locale = locale ? locale : this.selectedLang;
    const options = isCurrency ? {
      style: 'currency',
      currencyDisplay: (this.platform.SAFARI || this.platform.EDGE) ? 'symbol' : 'narrowSymbol',
      currency: this.defaultSystemCurrency
    } : {};
    if (removeFraction) {
      options['maximumFractionDigits'] = 0;
      options['minimumFractionDigits'] = 0;
    }
    if (decimalPlacesAllowed && decimalPlacesAllowed > 0) {
      options['minimumFractionDigits'] = decimalPlacesAllowed;
      options['maximumFractionDigits'] = decimalPlacesAllowed;
    }
    const currencySymbol = new Intl.NumberFormat(locale, options).formatToParts(1).filter(item => item.type === 'currency')[0];
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    try {
      value = value ? ((value.toString().startsWith('(') && value.toString().endsWith(')')) ? '-' + value.toString().replace('(', '').replace(')', '') : value) : '0';
    } catch (e) {
      console.log(value);
      console.error(e);
      value = '0';
    }
    let reversedVal = value.toString().replace(new RegExp('\\' + group, 'g'), '');
    if (currencySymbol) {
      reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.').replace(currencySymbol.value, '');
    } else {
      reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    }
    return Number.isNaN(reversedVal) ? 0 : Number(reversedVal);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
