import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Observable} from "rxjs/internal/Observable";


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notificationsTemplatesList = [];
  systemActionTemplatesList = [];

  templateSelectedForViewer: any = {};

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  public searchNotifications(notification: any){
    return this.eCaseHttpService.post('/api/searchNotifications', notification);
  }

  public viewNotification(notificationId){
    return this.eCaseHttpService.get('/api/viewNotification/' + notificationId);
  }

  public exportNotificationsToXml(data: any){
    return this.eCaseHttpService.post('/api/exportNotificationsToXml', data, {observe: 'response', responseType: 'blob'});
  }

  public exportNotificationsToExcel(data: any){
    return this.eCaseHttpService.post('/api/exportNotificationsToExcel', data, {observe: 'response', responseType: 'blob'});
  }

  public printAllNotifications(data: any[]){
    return this.eCaseHttpService.post('/api/printAllNotifications', data, {observe: 'response', responseType: 'blob'});
  }

  public getAllNotificationTemplates(){
    return this.eCaseHttpService.get('/api/getNotificationsTemplates');
  }

  public getAllTemplateVariables(){
    return this.eCaseHttpService.get('/api/getAllTemplateVariables');
  }

  public deleteTemplate(templateId) {
    return this.eCaseHttpService.post('/api/deleteNotificationsTemplate', templateId);
  }

  public saveTemplate(template) {
    return this.eCaseHttpService.post('/api/addOrUpdateNotificationsTemplate', template);
  }

  public getAllSystemActionTemplates() {
    return this.eCaseHttpService.get('/api/getSystemActionsNotificationsTemplates');
  }

  public deleteSystemNotification(notification){
    return this.eCaseHttpService.post('/api/deleteSystemActionNotificationsTemplate', notification);
  }

  public saveSystemNotification(template) {
    return this.eCaseHttpService.post('/api/addOrUpdateSystemActionNotifications', template);
  }

  public countTotalNotifications(){
    return this.eCaseHttpService.get('/api/countTotalNotifications');
  }

  public countTotalTemplates(): Observable<any>{
    return this.eCaseHttpService.get('/api/countTotalTemplates');
  }
  public searchTemplates(searchObj): Observable<any>{
    return this.eCaseHttpService.post('/api/searchTemplates', searchObj);
  }

}
