  <mat-toolbar class="p-0 mat-elevation-z1" style="margin-bottom: 10px;">
    <div style="margin-left: 15px;">
      <a href="/"> <h3>{{'ecase.common.generalDirectory.home' | translate}}</h3></a>
    </div>
    <div fxFill fxFlex fxLayout="row" fxLayoutAlign="end center">

      <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
        <ng-container>
          <div class="toolbar-separator"></div>
          <button [matMenuTriggerFor]="languageMenu" class="language-button"
                  fxShow.gt-xs
                  mat-button>
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="iso text-uppercase">{{selectedLang}}</span>
            </div>
          </button>

          <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
            <ng-container *ngFor="let lang of languages">
              <button (click)="setLanguage(lang)" [disabled]="lang.id === selectedLang.id" mat-menu-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="iso">{{lang.nativeName}}</span>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </mat-toolbar>
  <mat-card class="ecase-mat-card">
  <mat-card-title> {{'ecase.opportunity.listofavaialbleopportunities' | eCaseTranslate }}</mat-card-title>
    <app-loading-spinner *ngIf="loading"></app-loading-spinner>
      <mat-card-content *ngIf="opportunities.length > 0 && !loading" class="responsive-table-style">

        <table (matSortChange)="sortColumn($event)" [@animateStagger]="{value:'50'}"
               [dataSource]="dataSource"
               class="ecase-mat-table"
               id="ecase_opportunities_table"
               mat-table
               matSort
               matSortActive="{{sortActive}}"
               matSortDirection="{{sortDirection}}"
               style="margin-top: 0px">

          <ng-container matColumnDef="programName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header><u>{{'ecase.opportunity.programName' | eCaseTranslate}}</u>
            </th>
            <ng-container *matCellDef="let row">
              <td *ngIf="row.isVisible" [ngClass]="row.showCompetitionRowToggle ? 'td-grey-color' : ''"
                  [style.padding-left.px]="!row.showCompetitionRowToggle ? '66' : ''"
                  mat-cell
                  style="text-align: left !important; max-width:250px">
                <button (click)="toggleCompetitionRows(row)" *ngIf="row.showCompetitionRowToggle"
                        class="mat-icon-button" mat-icon-button
                        matTooltip="{{(row.showCompetitionRows ? ('ecase.opportunity.hidecompetitions' | eCaseTranslate) : ('ecase.opportunity.showcompetitions' | eCaseTranslate)) }}">
                  <mat-icon>{{row.showCompetitionRows ? 'remove_circle_outline' : 'add_circle_outline'}}</mat-icon>
                </button>
                {{row.showCompetitionRowToggle ? row.programName : ''}}
              </td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="competitionName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header><u>{{'ecase.common.generalDirectory.competitionName' |
              eCaseTranslate}}</u></th>
            <ng-container *matCellDef="let row">
              <td *ngIf="row.isVisible" [ngClass]="row.showCompetitionRowToggle ? 'td-grey-color' : ''"
                  [title]="'ecase.common.generalDirectory.competitionName' | eCaseTranslate"
                  mat-cell>
                {{ row.competitionName}}
              </td>
            </ng-container>
          </ng-container>
          <!-- Position Column -->

          <ng-container matColumnDef="openDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header><u>{{ 'ecase.opportunity.accessDate' |
              eCaseTranslate }}</u>
            </th>
            <ng-container *matCellDef="let row">
              <td *ngIf="row.isVisible" [ngClass]="row.showCompetitionRowToggle ? 'td-grey-color' : ''" [title]="'ecase.opportunity.accessDate' | eCaseTranslate"
                  class="wrap-table-text"
                  mat-cell>
                {{row.formattedOpenDate}}
              </td>
            </ng-container>
          </ng-container>


          <!-- Weight Column -->

          <ng-container matColumnDef="deadline">
            <th *matHeaderCellDef mat-header-cell mat-sort-header><u>{{'ecase.opportunity.deadlineForSubmission' |
              eCaseTranslate }}</u></th>
            <ng-container *matCellDef="let row">
              <td *ngIf="row.isVisible" [ngClass]="row.showCompetitionRowToggle ? 'td-grey-color' : ''" [title]="'ecase.opportunity.deadlineForSubmission' | eCaseTranslate "
                  class="wrap-table-text"
                  mat-cell>
                {{row.formattedDeadline}}
              </td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="prospectus">
            <th *matHeaderCellDef mat-header-cell>{{'ecase.opportunity.prospectus' | eCaseTranslate
              }}</th>
            <ng-container *matCellDef="let row">
              <td *ngIf="row.isVisible" [ngClass]="row.showCompetitionRowToggle ? 'td-grey-color' : ''" [title]="'ecase.opportunity.prospectus' | eCaseTranslate "
                  class="wrap-table-text"
                  mat-cell>
                <ng-container *ngIf = "row.prospectus && !row.hideActions">
                  <a (click)="downloadProspectus(row)" *ngIf="row.prospectus.isDoc && row.prospectus.docs?.[selectedLang]?.edmDocumentRepositoryId" style="text-decoration: none;white-space: nowrap">
                    {{'ecase.opportunity.prospectus' | eCaseTranslate }}</a>
                  <a target="_blank" [href]="row.prospectus.link[selectedLang]"  *ngIf="!row.prospectus.isDoc && row.prospectus.link[selectedLang]"
                     style="text-decoration: none;white-space: nowrap">
                    {{'ecase.opportunity.prospectus' | eCaseTranslate }}</a>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>

          <!-- Header row first group -->
          <ng-container class="filter_row_first_cell mat-elevation-z4" matColumnDef="header-row-first-group" matRipple>

            <th *matHeaderCellDef class="mat-table_filtered_header_cell"
                mat-header-cell><span class='nodisplay'>.</span>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <input *ngIf="showImage" alt="clear" class="row_filter_icon"
                       disabled matRipple
                       src="../../../assets/images/etc/icons8-conversion.svg" type="image">
                <input (click)="resetFilters(true)" *ngIf="!showImage" alt="clear"
                       class="row_filter_icon" src="../../../assets/images/etc/icons8-clear-filters.svg" type="image">
                <input (keyup)="applyFilter()" [(ngModel)]="filterObject.programName"
                       [attr.aria-label]="'ecase.opportunity.programName' | eCaseTranslate"
                       [placeholder]="'ecase.admindashboard.search' | eCaseTranslate"
                       class="searchFields"
                       id="ecase_opportunity_programName"
                       type="text">
              </div>
            </th>
          </ng-container>


          <!-- Header row second group -->
          <ng-container matColumnDef="header-row-second-group">
            <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
              <span class='nodisplay'>.</span>
              <input (keyup)="applyFilter()" [(ngModel)]="filterObject.competitionName"
                     [attr.aria-label]="'ecase.opportunity.competitionName' | eCaseTranslate"
                     [placeholder]="'ecase.admindashboard.search' | eCaseTranslate"
                     class="searchFields"
                     id="ecase_opportunity_competitionName"
                     type="text">
            </th>
          </ng-container>


          <ng-container matColumnDef="header-row-third-group">
            <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
              <span class='nodisplay'>.</span>
              <input (keyup)="applyFilter()" [(ngModel)]="filterObject.formattedOpenDate"
                     [attr.aria-label]="'ecase.opportunity.accessDate' | eCaseTranslate"
                     [placeholder]="'ecase.admindashboard.search' | eCaseTranslate"
                     class="searchFields"
                     id="ecase_opportunity_accessDate"
                     type="text">
            </th>
          </ng-container>

          <ng-container matColumnDef="header-row-four-group">
            <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
              <span class='nodisplay'>.</span>
              <input (keyup)="applyFilter()" [(ngModel)]="filterObject.formattedDeadline"
                     [attr.aria-label]="'ecase.opportunity.deadlineForSubmission' | eCaseTranslate"
                     [placeholder]="'ecase.admindashboard.search' | eCaseTranslate"
                     class="searchFields"
                     id="ecase_opportunity_deadlineForSubmission"
                     type="text">
            </th>
          </ng-container>

          <ng-container matColumnDef="header-row-five-group">
            <th *matHeaderCellDef class="mat-table_filtered_header_cell" mat-header-cell>
              <span class='nodisplay'>.</span>

            </th>
          </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matHeaderRowDef="['header-row-first-group', 'header-row-second-group',
      'header-row-third-group', 'header-row-four-group', 'header-row-five-group']" mat-header-row></tr>
      <ng-container *matRowDef="let row; columns: displayedColumns;">
        <tr [style.display]="row.isVisible ? '' : 'none'" mat-row></tr>
      </ng-container>
    </table>

      </mat-card-content>
      <mat-card-content
        *ngIf="opportunities.length === 0 && !loading" [innerHTML]="'ecase.opportunity.noopportunitiesfound' | eCaseTranslate">{{'ecase.opportunity.noopportunitiesfound' | eCaseTranslate }} </mat-card-content>
</mat-card>

