import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {TranslateService} from '@ngx-translate/core';
import {ECaseUtils} from './eCaseUtils';

/**
 * Created by sahilb@evision.ca on 2020-04-21.
 */
@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<any> {
  isPristine = true;

  constructor(private translateService: TranslateService) {
  }

  canDeactivate(): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
    if (this.isPristine) {
      return true;
    } else {
      const decision = confirm(ECaseUtils.getTranslatedValueFromKey(this.translateService, 'ecase.common.unsavedchangeswarning'));
      if (decision) {
        this.isPristine = true;
      }
      return decision;
    }
  }
}
