<div *ngIf="!sessiondialog && !sessionExpired">
  <mat-dialog-content style="padding-left: 10px;">
    <pre style="font-size: 12pt; ">{{data}}</pre>
    <label *ngIf="isDisabled" style="color:red">Please remove all the instances of LOV before deleting</label>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button style="color: white" (click)="onConfirm()" [disabled]="isDisabled"  id="ecase_generaldialog_yes" class="eCase-dialog-positive-action-button"
            mat-raised-button>{{ 'yes' | translate }}</button>    <!-- putting $event in the method -->
    &nbsp;
    <button style="color: white" (click)="onClose()" class="eCase-dialog-negative-action-button" id="ecase_generaldialog_no"
            mat-raised-button>{{ 'no' | translate }}</button>
  </mat-dialog-actions>

</div>


<!--Session Logic-->


<div *ngIf="sessiondialog || sessionExpired" style="text-align: center; margin-bottom: 20px;">
  <mat-dialog-content>
    <i *ngIf="sessionExpired" style="font-size: 30pt; font-family: Material Icons;" class="material-icons">warning</i>
    <pre style="font-size: 12pt;">{{data}}</pre>
  </mat-dialog-content>

  <mat-dialog-actions style="display: block !important;">
    <button *ngIf="sessionExpired" style="color: white" (click)="sessionClose('login')" [disabled]="isDisabled" class="eCase-dialog-positive-action-button"
            mat-raised-button>{{ 'ecase.common.pleaseLogin' | translate }}
    </button>

    <button *ngIf="sessiondialog" style="color: white" (click)="sessionClose('renew')" [disabled]="isDisabled" class="eCase-dialog-positive-action-button"
            mat-raised-button>{{ 'ecase.common.renewSessionButton' | translate }}
    </button>

  </mat-dialog-actions>


</div>
