import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class FormService implements Resolve<any>
{
  routeParams: any;
  form: any;
  onFormChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: EcaseHttpService
  ) {
  }

  /**
   * Resolve
   *
   * @param route
   * @param state
   * @returns
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.routeParams = route.params;

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getForm()
      ]).then(
        () => {
          resolve(undefined);
        },
        reject
        );
    });
  }

  getForm(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.routeParams.hasOwnProperty('formNumber')) {
        this.onFormChanged.next(false);
        resolve(false);
      }
      else {
        this.http.get('api/e-commerce-forms/' + this.routeParams.formNumber)
          .subscribe((response: any) => {
            this.form = response;
            this.onFormChanged.next(this.form);
            resolve(response);
          }, reject);
      }
    });
  }

  saveForm(form) {
    return new Promise((resolve, reject) => {
      this.http.post('api/e-commerce-forms/' + form.formNumber, form)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addForm(form) {
    return new Promise((resolve, reject) => {
      this.http.post('api/e-commerce-forms/', form)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
