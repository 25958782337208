import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {MaterialModule} from './material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ColorPickerModule} from 'ngx-color-picker';
import {EditorModule} from '@tinymce/tinymce-angular';


import {
  ECaseMatSidenavHelperDirective,
  ECaseMatSidenavTogglerDirective
} from '../directives/eCase-mat-sidenav-helper/eCase-mat-sidenav-helper.directive';
import {ECaseMatSidenavHelperService} from '../directives/eCase-mat-sidenav-helper/eCase-mat-sidenav-helper.service';
import {ECasePipesModule} from '../pipes/pipes.module';
import {ECaseConfirmDialogComponent} from '../components/confirm-dialog/confirm-dialog.component';
import {ECaseMatchMedia} from '../services/match-media.service';
import {ECasePerfectScrollbarDirective} from '../directives/eCase-perfect-scrollbar/eCase-perfect-scrollbar.directive';
import {ECaseIfOnDomDirective} from '../directives/eCase-if-on-dom/eCase-if-on-dom.directive';
import {CookieService} from 'ngx-cookie-service';
import {TranslateModule} from '@ngx-translate/core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {QuillModule} from 'ngx-quill';
import {HttpClientModule} from '@angular/common/http';
import {QuestionsLabelsService} from '../services/questionslabels.service';
import {ECaseSnackBarService} from '../services/snack-bar.service';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxNativeDateModule
} from '@angular-material-components/datetime-picker';
import {ECaseNgIfWithFeatureIdsDirective} from '../directives/eCase-ng-if-with-feature-ids.directive';
import {ECaseDragAndDropTextareaDirective} from '../directives/eCase-drag-and-drop-textarea.directive';
import {ECaseNgIfWithSysActionIdsDirective} from '../directives/eCase-ng-if-with-sys-action-ids.directive';
import {ECaseNgStickyDirective} from '../directives/eCase-ng-sticky.directive';
import {EcaseLoadingSpinnerComponent} from '../components/ecase-loading-spinner/ecase-loading-spinner.component';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {ECaseVarDirective} from '../directives/eCase-var.directive';
import {ECaseNDecimalNumberDirective} from '../directives/eCase-n-decimal-number.directive';
import {MonacoEditorModule, NgxMonacoEditorConfig} from 'ngx-monaco-editor';
import {ECaseInputNumberDirective} from '../directives/e-case-input-number.directive';
import {ECaseNavbarVerticalService} from "../services/navbar-vertical.service";
import {OverlayModule} from "@angular/cdk/overlay";


const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: () => {
    (window as any).monaco.editor.EditorOptions.automaticLayout.defaultValue = true;
    (window as any).monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false
    });
    (window as any).monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: (window as any).monaco.languages.typescript.ScriptTarget.ES5,
      allowNonTsExtensions: true
    });
    (window as any).monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      allowComments: false
    });
    (window as any).monaco.languages.json.jsonDefaults.setModeConfiguration({
      diagnostics: true
    });
  }
};

@NgModule({
    declarations: [
        ECaseInputNumberDirective,
        ECaseMatSidenavHelperDirective,
        ECaseMatSidenavTogglerDirective,
        ECaseConfirmDialogComponent,
        ECaseIfOnDomDirective,
        ECasePerfectScrollbarDirective,
        ECaseNgIfWithFeatureIdsDirective,
        ECaseDragAndDropTextareaDirective,
        ECaseVarDirective,
        ECaseNDecimalNumberDirective,
        ECaseNgIfWithSysActionIdsDirective,
        ECaseNgStickyDirective,
        ECaseInputNumberDirective,
        EcaseLoadingSpinnerComponent
    ],
    imports: [
        FlexLayoutModule,
        QuillModule.forRoot(),
        MonacoEditorModule.forRoot(monacoConfig),
        HttpClientModule,
        MaterialModule,
        CommonModule,
        OverlayModule,
        FormsModule,
        ECasePipesModule,
        ReactiveFormsModule,
        ColorPickerModule,
        NgxChartsModule,
        TranslateModule.forChild({}),
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        EditorModule
    ],
    exports: [
        // GetGraphTemplatesDirective,
        ECaseInputNumberDirective,
        NgxMatDatetimePickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        EditorModule,
        FlexLayoutModule,
        MaterialModule,
        OverlayModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        QuillModule,
        TranslateModule,
        ECaseMatSidenavHelperDirective,
        ECaseMatSidenavTogglerDirective,
        ECasePipesModule,
        EcaseLoadingSpinnerComponent,
        ECasePerfectScrollbarDirective,
        ECaseNgIfWithFeatureIdsDirective,
        ECaseNgIfWithSysActionIdsDirective,
        ECaseDragAndDropTextareaDirective,
        ECaseVarDirective,
        ECaseNgStickyDirective,
        ReactiveFormsModule,
        ColorPickerModule,
        NgxChartsModule,
        ECaseIfOnDomDirective,
        ECaseInputNumberDirective,
        TranslateModule,
        NgxMatTimepickerModule,
        ECaseNDecimalNumberDirective,
        MonacoEditorModule
    ],
    providers: [
        CookieService,
        QuestionsLabelsService,
        ECaseSnackBarService,
        ECaseMatchMedia,
        ECaseNavbarVerticalService,
        ECaseMatSidenavHelperService
    ]
})

export class SharedModule {

}
