import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {Subscription} from 'rxjs/internal/Subscription';
import {EcaseHttpService} from './ecase-http.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  subscription: Subscription;
  languageList = [];
  defaultSystemLanguage: Language;
  triggerSubject: Subject<any> = new Subject<any>();

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  createLanguage(languages: any): Array<Language> {
    const refactoredLanguages: Array<Language> = [];
    for (const lang of languages) {
      refactoredLanguages.push({
        id: lang.code,
        code: lang.code,
        nativeName: lang.nativeName,
        label: lang.nativeName,
        sortingKey: lang.sortingKey,
        writingMode:lang.writingMode
      });
    }
    return refactoredLanguages;
  }

  getAllLanguages(): Observable<any> {
    return this.eCaseHttpService.get('/api/getLanguages');
  }

  getSystemDefaultLanguage(): Observable<any> {
    return this.eCaseHttpService.get('/api/getSystemDefaultLanguage');
  }

  triggerWhenLanguagesAreLoaded(): void {
    this.languageList = this.languageList.map(item => {
      if (!item.id) {
        item.id = item.code
      }
      if (!item.code) {
        item.code = item.id
      }
      return item;
    })
    this.triggerSubject.next(this.languageList);
  }


}

interface Language {
  id: string;
  code: string;
  nativeName: string;
  label: string;
  sortingKey: number;
  writingMode: string;
}
