import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Observable} from 'rxjs/internal/Observable';
import {LoginAuthenticationService} from './login-authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AdminDmsService {

  documentTypeList = [];
  languageList = [];
  selectedLang;
  searchResult = [];


  constructor(private ecaseHttpService: EcaseHttpService,
              private loginAuthenticationService: LoginAuthenticationService) { }

  searchDocuments(searchForm) {
    return this.ecaseHttpService.post('/api/searchForDocument', searchForm);
  }

  deleteDocument(docId) {
    return this.ecaseHttpService.post('/api/deleteDmsDocument', docId);
  }

  addGeneralDocument(formData: FormData) {
    return this.ecaseHttpService.post('/api/addGeneralDocument', formData);
  }

  previewDocument(docId) {
    window.open(this.loginAuthenticationService.baseUrl + '/api/previewDmsDocument/' + docId.toString());
  }


  testSearch() {
    return new Observable((subscriber) => {
      const obj: any = {
        status: true,
        message: 'Success',
        result: [
          {
            documentId: 3,
            formType: {
              en: 'application',
              fr: 'application-fr'
            },
            documentCategory: {
              en: 'category01',
              fr: 'categorie01'
            },
            documentType: {
              en: 'app',
              fr: 'aplic'
            },
            documentName: 'document01.pdf',
            attachmentDate: '2020-09-27 05:13:57',
            attachedBy: 'Sylvia',
            actorRole: {
              en: 'Administrator',
              fr: 'Administrateur'
            },
            caseApplicationId: 34
          },
          {
            documentId: 32,
            formType: {
              en: 'diploma',
              fr: 'diplome'
            },
            documentCategory: {
              en: 'category03',
              fr: 'categorie03'
            },
            documentType: {
              en: 'dipl',
              fr: 'diplom'
            },
            documentName: 'fake_diploma.pdf',
            attachmentDate: '2020-12-27T05:13:57Z',
            attachedBy: 'Faissal',
            actorRole: {
              en: 'User',
              fr: 'Usuario'
            },
            caseApplicationId: 25
          }
        ]
      };
      subscriber.next(obj);
    });
  }
}
