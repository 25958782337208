import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ECaseConfigService,
  ECaseDateFormatterPipe,
  ECaseSnackBarService,
  ECaseSplashScreenService,
  ECaseTranslationLoaderService, ECaseUtils,
  LanguageService,
  LoginAuthenticationService,
  ParametersService,
  RefreshContentTabsService
} from "synto-common";

@Component({
  selector: 'app-activator',
  templateUrl: './activator.component.html',
  styleUrls: ['./activator.component.css']
})
export class ActivatorComponent implements OnInit {

  dateFormat = '';
  constructor(private eCaseConfig: ECaseConfigService, private formBuilder: FormBuilder, public translate: TranslateService,
              private translationLoader: ECaseTranslationLoaderService, private refreshContentTabsService: RefreshContentTabsService,
              private route: ActivatedRoute, private eCaseSplashScreenService: ECaseSplashScreenService, private eCaseSnackBarService: ECaseSnackBarService,
              private languageService: LanguageService,
              private eCaseDateFormatterPipe: ECaseDateFormatterPipe,
              private parameterService: ParametersService,
              private loginAuthenticationService: LoginAuthenticationService,
              public router: Router) {
    this.dateFormat = this.parameterService.parameter['/core/ui/default_date_time_with_timezone/format'];
  }


  ngOnInit() {
    this.translationLoader.loadTranslationsWithObservable(27).subscribe(() => {
      const selectedLang = this.route.snapshot.paramMap.get('lang');

      if (selectedLang) {
        if(this.translate.getLangs().indexOf(selectedLang) !== -1 ){
          this.setLanguage(selectedLang);
        }
      }
      const activationCode = this.route.snapshot.paramMap.get('activationCode');
      if (activationCode && activationCode !== '') {
        this.eCaseSplashScreenService.show();
        this.loginAuthenticationService.activateAccount(activationCode).subscribe((validationResult: any) => {
          if (validationResult.status) {
            this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.accountactivated'));
            this.eCaseSplashScreenService.hide();
            this.router.navigate(['/']);
          } else if (!validationResult.status && validationResult.dateExpiredOn) {
              this.translate.get('ecase.common.acctivationAccountlinkexpiredmessage').subscribe((data: any) => {
              const date = this.eCaseDateFormatterPipe.transform(validationResult.dateExpiredOn, this.dateFormat);
              const text = data.replace('${dateExpiredOn}', date);
              this.eCaseSnackBarService.showComponent('failure', text);
              this.eCaseSplashScreenService.hide();
              this.router.navigate(['/']);
            });
          }else {
            this.eCaseSnackBarService.showComponent('failure', ECaseUtils.getTranslatedValueFromKey(this.translate,'ecase.common.accountAlreadyActivated'));
            this.eCaseSplashScreenService.hide();
            this.router.navigate(['/']);
          }

        }, (error) => {
          if (!error.error.status) {
            this.eCaseSnackBarService.showComponent('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.accountAlreadyActivated'));
            this.eCaseSplashScreenService.hide();
          }

        });
      }
      else {
        this.eCaseSnackBarService.showComponent('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.activationfailed'));
      }
    });

  }


  setLanguage(lang) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
  }

}
