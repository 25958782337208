import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {
  ECaseSnackBarService,
  ECaseUtils,
  ECaseUtilsGlobal,
  LoginAuthenticationService,
  MatProgressBarService, TitleService
} from "synto-common";
@Component({
  selector: 'app-adfs-saml2-login',
  templateUrl: './adfs-saml2-login.component.html',
  styleUrls: ['./adfs-saml2-login.component.scss']
})
export class AdfsSaml2LoginComponent implements OnInit {

  constructor(private matProgressBarService: MatProgressBarService, private loginAuthenticationService: LoginAuthenticationService,
              private eCaseSnackBarService: ECaseSnackBarService, public translate: TranslateService, public router: Router,
              private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitleWithSeparator(['Redirecting....']);
    this.loginAuthenticationService.getSaml2UserProfile().subscribe((response: any) => {
      if (response.status) {
        this.loginAuthenticationService.isUserLoggedIn = true;
        this.loginAuthenticationService.primeRole = response.primeRole;
        this.loginAuthenticationService.tabs = response.tabs;
        this.loginAuthenticationService.isSamlProfile = response.isSamlProfile;
        this.loginAuthenticationService.isOnlyApplicantRole = response.isOnlyApplicantRole;
        this.loginAuthenticationService.firstClickableFeature = response.firstClickableFeature;
        this.loginAuthenticationService.featureIdList = response.featureIdList;
        this.loginAuthenticationService.isProfileCompleted = response.isProfileCompleted;
        this.loginAuthenticationService.sysActionIdList = response.sysActionIdList;
        /*if (this.loginAuthenticationService.primeRole === 3) {
          this.router.navigate(['/dashboard/flows/processes']).then(() => {});
        } else {
          this.router.navigate([response.firstClickableFeature.url]).then(() => {});
        }*/
        this.router.navigate(['/dashboard/flows/processes']).then(() => {});
      } else {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, response.errorMessage));
        this.titleService.setTitleWithSeparator(['ecase.common.defaultBrowserTabTitle']);
        this.router.navigate(['/']).then(() => {});
      }
    });
  }

}
