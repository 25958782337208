import {Type} from '../enumeration/type.enum';
import {HtmlComponent} from './htmlComponent.model';

import {ActionService} from '../services/action.service';

export class ActionModel {


  _rule: string;
  messageHolder: any = {'en': '', 'fr': ''};
  selectedSectionId: string;
  selectedComponentName: string;
  isRequired = false;
  minLength?: number;
  minDate?: Date;
  maxDate?: Date;
  nestedBlockName?: string;
  nestedName?;
  string;
  selectedOperatorMin?: string;
  isVisible? = true;
  value?: number;
  myMinDate: Date;
  myMaxDate: Date;
  email: string;
  nestedLevelText: boolean;
  yearList: Array<number> = [];
  myListOfRules: Array<String> = [];
  block: string;
  isCheckboxTrue: boolean;
  dateValidator = false;
  selectValue: any;
  hasError: boolean;
  myData: TermActionInterface[];
  termActionModel: TermActionModel = new TermActionModel();
  arrayTermAction: TermActionModel[] = [];
  taxonomyIdForYesOrNoList: number;


  constructor(private actionService: ActionService) {
    this.getYearList();


    this.myMinDate = new Date(this.yearList[0], new Date().getMonth(), new Date().getDate());
    this.myMaxDate = new Date(this.yearList[this.yearList.length - 1], new Date().getMonth(), new Date().getDate());
    this.messageHolder.en = this.selectedSectionId + ' ,' + this.selectedComponentName + ' is mandatory';
    this.messageHolder.fr = this.selectedSectionId + ' ,' + this.selectedComponentName + ' est obligatoire';
    this.nestedLevelText = this.nestedName === 'textarea' || this.nestedBlockName === 'text' || this.nestedBlockName === 'currency';
    this.block = '';
    this.hasError = false;
    this._rule = '';
    this.dateValidator = false;

  }


  convertDateToNumber(date: Date = new Date()): number {

    const myYear = date.getFullYear();
    const myMouth = date.getMonth() + 1;
    const myDay = date.getDate();
    let myAlLDAY = myYear + '' + myMouth + '' + myDay;
    if (myMouth < 10) {
      myAlLDAY = myYear + '0' + myMouth + myDay;
    }
    if (myDay < 10) {
      myAlLDAY = myYear + myMouth + '0' + myDay;
    }
    if (myMouth < 10 && myDay < 10) {
      myAlLDAY = myYear + '0' + myMouth + '0' + myDay;
    }


    return Number(myAlLDAY);


  }

  getYearList() {

    const currrentYear = new Date().getFullYear();
    for (let i = (currrentYear - 50); i < currrentYear + 50; i++) {
      this.yearList.push(i);
    }
    return this.yearList;
  }


  checkError(blockName: string, nestedTable?: HtmlComponent): boolean {
    switch (blockName) {
      case Type.Checkbox :
      case Type.Upload:
        this.hasError = this.isCheckboxTrue === undefined ? true : false;

        break;
      case Type.Text:
      case Type.TextArea:
      case Type.Currency:
      case Type.AutoComplete:
        this.hasError = !(this.selectedOperatorMin && this.minLength) ? true : false;
        break;
      case Type.DatePicker:
        this.hasError = !(this.minDate && this.maxDate) ? true : false;
        this.hasError = (this.convertDateToNumber(this.minDate)) >= (this.convertDateToNumber(this.maxDate)) ? true : false;
        if (this.minDate !== undefined && this.maxDate !== undefined) {
          this.dateValidator = (this.convertDateToNumber(this.minDate)) >= (this.convertDateToNumber(this.maxDate)) ? true : false;
        }

        break;

      case Type.Select:
      case Type.RadioButton:
        this.hasError = !this.selectValue ? true : false;
        break;
      case Type.Table:

        this.hasError = !nestedTable ? true : false;
        if (nestedTable) {
          this.hasError = this.checkErrorForTable(nestedTable.blockName);
        }


        break;


    }
    return this.hasError;
  }

  checkErrorForTable(blockName: string) {
    this.hasError = !blockName ? true : false;
    switch (blockName) {
      case Type.Checkbox :
      case Type.RadioButton:
        this.hasError = !this.isCheckboxTrue ? true : false;
        break;
      case Type.Text:
      case Type.TextArea:
      case Type.Currency:

        this.hasError = !(this.selectedOperatorMin && this.minLength) ? true : false;
        break;
      case Type.DatePicker:
        this.hasError = !(this.minDate && this.maxDate) ? true : false;
        this.hasError = (this.convertDateToNumber(this.minDate)) >= (this.convertDateToNumber(this.maxDate)) ? true : false;
        break;

      case Type.Select:
      case Type.AutoComplete:
      case Type.RadioButton:
        this.hasError = !this.selectValue ? true : false;
        break;

    }
    return this.hasError;
  }


  getRules(value: string, nestedBlockName?: string, nestedName?: string) {
    //

    switch (value) {

      case Type.Checkbox:
        if (!this.checkError(Type.Checkbox)) {
          this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value[z].value} # ' + this.isCheckboxTrue;
          this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value[z].value';
          this.myListOfRules.push(this._rule);

        }
        break;

      case Type.RadioButton:

        if (!this.checkError(Type.Select)) {
          if (isNaN(this.selectValue)) {
            this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value.value} ## ' + this.selectValue;
          } else {
            this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value.value} # ' + this.selectValue;
          }

          this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value.value';
          this.myListOfRules.push(this._rule);

        }
        break;

      case Type.Text:
      case Type.TextArea:
      case Type.Currency:
      case Type.AutoComplete:
        if (!this.checkError(Type.Text)) {
          this._rule = 'length($${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value})' + this.selectedOperatorMin + this.minLength;
          this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value';
          this.myListOfRules.push(this._rule);

        }
        break;


      case Type.Table:

        if (!(this.checkErrorForTable(nestedBlockName)) && nestedBlockName && nestedName) {
          this.verifyRulesTable(nestedBlockName, nestedName);

        }
        break;


      case Type.DatePicker:
        if (!this.checkError(Type.DatePicker)) {
          this.addRuleForDate();

        }
        break;


      case  Type.Select:
        if (!this.checkError(Type.Select)) {
          if (isNaN(this.selectValue)) {
            this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value.value} ## ' + this.selectValue;

          } else {
            this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value.value} # ' + this.selectValue;
          }
          this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value.value';
          this.myListOfRules.push(this._rule);

        }
        break;

      case Type.Upload:
        if (!this.checkError(Type.Upload)) {
          this._rule = '$${' + this.selectedSectionId + '.' + this.selectedComponentName + '.value.fileUploaded} #' + this.isCheckboxTrue;
          this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value[0].value';
          this.myListOfRules.push(this._rule);


        }

        break;


    }

    return this.myListOfRules;


  }


  addRuleForDate(nestBlockName?) {

    if (this.convertDateToNumber(this.minDate) >= this.convertDateToNumber(this.maxDate)) {
      this.dateValidator = true;

      this.hasError = true;

    }


    if (this.minDate) {

      this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.sort_date} >' + this.convertDateToNumber(this.minDate);
      this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value[0].value';
      this.myListOfRules.push(this._rule);
      this.dateValidator = false;

    }
    if (this.maxDate) {

      this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.sort_date} < ' + this.convertDateToNumber(this.maxDate);
      this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value[0].value';
      this.myListOfRules.push(this._rule);
      this.dateValidator = false;


    } else if (nestBlockName && this.minDate) {

      this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.rows[i].' + nestBlockName + '.sort_date} >' + this.convertDateToNumber(this.minDate);
      this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value[0].value';
      this.myListOfRules.push(this._rule);
      this.dateValidator = false;


    }
    if (nestBlockName && this.maxDate) {

      this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.rows[i].' + nestBlockName + '.sort_date} <' + this.convertDateToNumber(this.maxDate);
      this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.value[0].value';
      this.myListOfRules.push(this._rule);
      this.dateValidator = false;


    }


  }


  verifyRulesTable(nestedTableBlockName: string, nestedTableName: string) {

    switch (nestedTableBlockName) {
      case Type.Checkbox:
        this._rule = '$${' + this.selectedSectionId + '.' + this.selectedComponentName + '.rows[i].' + nestedTableName + '.value[z].value} # ' + this.isCheckboxTrue;
        this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value[z].value';
        this.myListOfRules.push(this._rule);
        break;
      case Type.RadioButton:
        this._rule = '$${' + this.selectedSectionId + '.' + this.selectedComponentName + '.rows[i].' + nestedTableName + '.value[0].value} # ' + this.isCheckboxTrue;
        this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value[0].value';
        this.myListOfRules.push(this._rule);
        break;
      case Type.Select:
        if (isNaN(this.selectValue)) {
          this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value.value} ## ' + this.selectValue;
        } else {
          this._rule = '${' + this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value.value} # ' + this.selectValue;
        }

        this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value.value';
        this.myListOfRules.push(this._rule);
        break;
      case Type.DatePicker:
        this.addRuleForDate(nestedTableName);
        break;


      case Type.Text:
      case Type.TextArea:
      case Type.Currency:
      case Type.AutoComplete:

        this._rule = 'length($${' + this.selectedSectionId + '.' + this.selectedComponentName + '.rows[i].' + nestedTableName + '.value})' + this.selectedOperatorMin + this.minLength;
        this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value.value';
        this.myListOfRules.push(this._rule);
        break;
      default:

        this._rule = 'length($${' + this.selectedSectionId + '.' + this.selectedComponentName + '.rows[i].' + nestedTableName + '.value}) > 0';
        this.block = this.selectedSectionId + '.' + this.selectedComponentName + '.selectedRow.' + nestedTableName + '.value.value';
        this.myListOfRules.push(this._rule);


    }
    return this.myListOfRules;
  }


}

export interface TermActionInterface {
  idTaxonomy: number;

  termRow: TermActionModel [];

}

export class TermActionModel {
  idTerm: number;
  valueTerm: String;
  valueLocal: String;

}
