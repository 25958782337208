import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ECaseNavigationService} from 'synto-common';

@Component({
  selector: 'eCase-nav-vertical-group',
  templateUrl: './nav-vertical-group.component.html',
  styleUrls: ['./nav-vertical-group.component.scss']
})
export class ECaseNavVerticalGroupComponent implements OnInit {
  @HostBinding('class') classes = 'nav-group nav-item';
  @Input() item: any;

  constructor(private eCaseNavigationService: ECaseNavigationService) {
  }

  ngOnInit() {
    this.item.children = this.eCaseNavigationService.sideNavTabs;
  }

}
