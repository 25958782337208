import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportSectionService {

  constructor(private eCaseHttpService: EcaseHttpService) {
  }


  getSectionsBasedOnSearch(searchCriteria: any): any {
    return this.eCaseHttpService.post('/api/getSectionsBasedOnSearch', searchCriteria).pipe(map(res => res));
  }

  getAllDistinctSections() {
    return this.eCaseHttpService.get('/api/getAllDistinctSections');
  }
}
