import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'eCase-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ECaseConfirmDialogComponent implements OnInit {
  public confirmMessage: string;

  constructor(public dialogRef: MatDialogRef<ECaseConfirmDialogComponent>) {
  }

  ngOnInit() {
  }

}
