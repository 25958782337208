import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoginAuthenticationService} from '../services/login-authentication.service';

/**
 * Created by sahilb@evision.ca on 2019-09-24.
 * Usage :-
 *   With Array Data
 *  <div *eCaseNgIfWithFeatureIds="[12,45,67]">
 <!-- Content to be protected -->
 </div>
 *  With Number
 <div *eCaseNgIfWithFeatureIds="23">
 <!-- Content to be protected -->
 </div>
 */
@Directive({
  selector: '[eCaseNgIfWithFeatureIds]'
})
export class ECaseNgIfWithFeatureIdsDirective {

  private requiredFeatureIds = [];

  constructor(private loginAuthenticationService: LoginAuthenticationService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input()
  set eCaseNgIfWithFeatureIds(val) {
    if (ECaseNgIfWithFeatureIdsDirective.validateInput(val)) {
      this.requiredFeatureIds = val;
      this.updateView();
    }
  }

  private static validateInput(val): boolean {
    const isValid = true;
    if (!Array.isArray(val) && isNaN(val)) {
      throw new TypeError('Value passed in directive \'eCaseNgIfWithFeatureIds\' in neither an array nor a number');
    } else if (Array.isArray(val) && val.some(isNaN)) {
      throw new TypeError('Value passed in directive \'eCaseNgIfWithFeatureIds\' in not an array of numbers');
    }
    return isValid;
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;
    if (this.loginAuthenticationService && this.loginAuthenticationService.featureIdList) {
      hasPermission = Array.isArray(this.requiredFeatureIds) ?
        this.requiredFeatureIds.every(val => this.loginAuthenticationService.featureIdList.includes(val)) :
        this.loginAuthenticationService.featureIdList.includes(this.requiredFeatureIds);
    }
    return hasPermission;
  }


}
