import { Injectable } from '@angular/core';
import { EcaseHttpService } from './ecase-http.service';
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class ChangeProgramCompetitionService {

  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  validateApplication(applicationId): Observable<any> {
    return this.eCaseHttpService.post('/api/validateApplication', {
      caseApplicationId: applicationId,
      task: "changeProgramCompetition"
    });
  }

  updateProgramCompetition(data): Observable<any> {
    return this.eCaseHttpService.post('/api/updateProgramCompetition', data);
  }

}
