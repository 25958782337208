export class  TypeDataModel {


  id=0;
  typesOfData ;
  constructor(){
   this.id=this.id+1;

    this.typesOfData = [
      {description: 'Projects'},
      { description: 'Total Value of Projects'},
      {description: 'Submitted Applications'},
      { description: 'Applications'},
    ];
  }
}
