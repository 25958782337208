import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CvService {
  goToCvForm: boolean;
  formInformation = {
    'formId': -1,
    'caseJsonRepositoryId': -1,
    'crmPersonCvId': -1
  };

  constructor(private eCaseHttpService: EcaseHttpService) {
  }


  getCvFormDataForFields(formInformation): Observable<any> {
    return this.eCaseHttpService.post('/api/getCvFormDataForFields', {
      'formId': formInformation.formId,
      'caseJsonDataRepositoryId': formInformation.caseJsonRepositoryId,
      'isFormForConsent': true
    });
  }

  getAllCVDocuments(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllCVDocuments');
  }

  getAllCvForms(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllCvForms');
  }

  addNewCVForm(formId: number, description: string, name: string): Observable<any> {
    return this.eCaseHttpService.post('/api/addNewCVForm', {
      'formId': formId,
      'description' : description,
      'name' : name
    });
  }

  cloneCVForm(crmPersonCvId: number, description: string, name: string): Observable<any> {
    return this.eCaseHttpService.post('/api/cloneCVForm', {
      'crmPersonCvId': crmPersonCvId,
      'description' : description,
      'name' : name
    });
  }

  addNewCVDocument(document: FormData): Observable<any> {
    return this.eCaseHttpService.post('/api/addNewCVDocument', document);
  }

  downloadCvDocument(documentId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/downloadCvDocument/' + documentId, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  updateCvDocument(documentId: number, description: string, name: string, cvPersonId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/updateCvDocument', {
      'documentId': documentId,
      'description': description,
      'name': name,
      'cvPersonId': cvPersonId,
    });
  }

  downloadCvForm(crmPersonCvId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/downloadCvForm/' + crmPersonCvId, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  saveCVForm(prj: any): Observable<any> {
    return this.eCaseHttpService.post('/api/saveCVForm', {
      'data': prj
    });
  }

  deleteCVForm(crmPersonCvId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/deleteCVForm/' + crmPersonCvId);
  }

  deleteCVDocument(edmDocumentRepositoryId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/deleteCVDocument/' + edmDocumentRepositoryId);
  }

  cloneCVDocument(edmDocumentRepositoryId: number): Observable<any> {
    return this.eCaseHttpService.get('/api/cloneCVDocument/' + edmDocumentRepositoryId);
  }

}
