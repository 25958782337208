<div class="page-layout carded fullwidth" eCasePerfectScrollbar id="forms">
  <!-- TOP BACKGROUND -->
  <div class="top-bg mat-accent-bg" style="height: 100px !important;"></div>
  <!-- / TOP BACKGROUND -->
  <!-- CENTER -->
  <div class="center">
    <!-- HEADER -->
    <div class="header white-fg" style="height: 70px !important;
      min-height: 70px !important;
      max-height: 70px !important;">
      <br/>
      <!-- APP TITLE -->
      <div [@animate]="{value:'*',params:{x:'50px'}}" class="breadcrumb " fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="logo-icon mr-16 h1">
            web
          </mat-icon>
          <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                class="logo-text h1">{{'ecase.common.browsersupport' | translate}}</span>
        </div>

        <mat-form-field appearance="outline" class="languageSwitcher" style="float: right;width:10%">
          <mat-select (ngModelChange)="setLanguage($event)" [(ngModel)]="selectedLanguage"
                      [attr.aria-label]="'language selector'" id="languageSelect">
            <mat-option *ngFor="let language of languages" [attr.aria-labelledby]="'languageSelect'" [value]="language"
                        role="option">{{language.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <!-- / APP TITLE -->
    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card mat-white-bg" style="margin-bottom: 10px;margin-top: 10px">
      <mat-card-content (window:resize)="onResize($event)" class="content" id="content">
        <div style="width: 100%">
          <p>{{'ecase.common.syntobrowsersupport' | translate}}</p>
          <table class="angular-table table">
            <thead class="angular-table-thead">
            <tr class="angular-table-tr">
              <th class="angular-table-th">{{'ecase.common.browser' | translate}}</th>
              <th class="angular-table-th">{{'ecase.common.supportedversions' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let browser of browsers" class="angular-table-tr">
              <td class="angular-table-td">{{browser.name}}</td>
              <td class="angular-table-td">{{browser.supportedVersions | translate}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>



