import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EcaseHttpService, LanguageService, ParametersService, TitleService} from 'synto-common';

@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.scss']
})
export class BrowserSupportComponent implements OnInit {

  selectedLanguage;
  languages: any[];
  browsers = [{
    'name': 'Chrome',
    'supportedVersions': 'ecase.common.chromesupportedversions'
  }, {
    'name': 'Firefox',
    'supportedVersions': 'ecase.common.firefoxsupportedversions'
  }, {
    'name': 'Edge',
    'supportedVersions': 'ecase.common.edgesupportedversions'
  }, {
    'name': 'Safari',
    'supportedVersions': 'ecase.common.safarisupportedversions'
  }, {
    'name': 'iOS',
    'supportedVersions': 'ecase.common.iossupportedversions'
  }, {
    'name': 'Android',
    'supportedVersions': 'ecase.common.androidsupportedversions'
  }];

  constructor(public translate: TranslateService, private titleService: TitleService,
              private eCaseHttpService: EcaseHttpService, private parametersService: ParametersService,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.titleService.setTitleWithSeparator(['ecase.common.browsersupport']);
    this.languageService.getAllLanguages().subscribe((data) => {
      this.languages = this.languageService.createLanguage(data);
      this.languageService.languageList = this.languages;
      this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
      this.translate.use(this.selectedLanguage.id);
      this.translate.setDefaultLang(this.selectedLanguage.id);
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize(null);
    }, 200);
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    // Use the selected language for translations
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
    this.titleService.setTitleWithSeparator(['ecase.common.browsersupport']);
  }

  onResize(event) {
    const element = document.getElementById('content');
    const newHeight = window.innerHeight - 124;
    element.setAttribute('style', 'padding:15px;max-height:' + newHeight + 'px;');
  }

}
