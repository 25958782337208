import {Injectable} from '@angular/core';
import {EcaseHttpService} from './ecase-http.service';
import {TermActionInterface} from '../model/action.model';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  actionLov;
  actionRuleYesNo;
  listValue;
  constructor(private eCaseHttpService: EcaseHttpService) {
  }

  getTaxonomyId(){
    return this.eCaseHttpService.get<TermActionInterface[]>('api/getTaxonomyYesNoList');
  }
}
